import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AccountUpdate } from '../account.model';
import { AuthService } from '../../authentication/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    currentProfile: any;
    constructor(
        private _httpClient: HttpClient,
        public authService: AuthService
    ) {}

    getProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiEndpoint + 'recruiters/profile')
                .subscribe(
                    (response: any) => {
                        this.currentProfile = response;
                        resolve(response);
                    },
                    (error) => {
                        this.authService.logout();
                        window.open('/');
                    }
                );
        });
    }

    updateProfile(account: AccountUpdate, id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    environment.apiEndpoint + 'companies/accounts/update',
                    account
                )
                .subscribe((response: any) => {
                    resolve(response);
                });
        });
    }

    changePassword(passwordChangeOjb, id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    environment.apiEndpoint + 'accounts/password/' + id,
                    passwordChangeOjb
                )
                .subscribe((response: any) => {
                    resolve(response);
                });
        });
    }
}
