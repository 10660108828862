import { FuseNavigation } from '@fuse/types';

export const superAdminNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'activation-manage',
                title: 'Activation Manage',
                translate: 'NAV.ACTIVATION_MANAGE.TITLE',
                type: 'item',
                icon: 'vpn_key',
                url: '/activation-manage',
            },
            {
                id: 'company-manage',
                title: 'Company Manage',
                translate: 'NAV.COMPANY_MANAGE.TITLE',
                type: 'item',
                icon: 'vpn_key',
                url: '/company-manage',
            },
            {
                id: 'candidate-manage',
                title: 'Company Manage',
                translate: 'NAV.CANDIDATE_MANAGE.TITLE',
                type: 'item',
                icon: 'vpn_key',
                url: '/candidate-manage',
            }
        ],
    },
];
