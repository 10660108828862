import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { PosterService } from '../poster.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'app-poster-view',
    templateUrl: './poster-view.component.html',
    styleUrls: ['./poster-view.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class PosterViewComponent implements OnInit, OnChanges {
    @Input() posterModel: any;
    @Input() customTextSize = 20;

    posterViewBackgroundStyle: any;
    cropperSettings: any;
    posterTextModel: any;
    posterWidth: number;

    constructor(private _posterService: PosterService) {
        this.posterViewBackgroundStyle = {
            'background-color': '#ffffff',
            // width: '452px',
            // height: '302px',
        };

        this.posterModel = {};

        this.cropperSettings = {
            croppedWidth: 300,
            croppedHeight: 300 * 1.5,
        };

        this.posterWidth = 300;
    }

    ngOnInit() {
        this._initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._initialize();
    }

    _initialize() {
        if (this.posterModel) {
            if (this.posterModel.bgType === 'color') {
                this.posterViewBackgroundStyle = {
                    'background-color': this.posterModel.bgValue,
                };
            }

            if (this.posterModel.bgType === 'pattern') {
                const bgImageSrc = 'url(\'{src}\')';
                this.posterViewBackgroundStyle = {
                    'background-image': bgImageSrc.replace(
                        '{src}',
                        this.posterModel.bgValue
                    ),
                    'background-size': 'cover',
                    width: '100%',
                    height: '100%',
                };
            }

            if (this.posterModel.bgType === 'image') {
                this.posterViewBackgroundStyle = {
                    width: 'auto',
                    height: '100%',
                };
            }

            if (this.posterModel.type === 'vertical') {
                this.cropperSettings.croppedHeight = 300 * 1.5;
                this.cropperSettings.croppedWidth = 300;
                this.posterWidth = 300;
            } else {
                this.cropperSettings.croppedHeight = 300;
                this.cropperSettings.croppedWidth = 300 * 1.5;
                this.posterWidth = 450;
            }

            this.posterTextModel = this._posterService.generatePosterTextModel(
                this.posterModel,
                this.customTextSize
            );
        }
    }
}
