import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardsComponent } from './main/dashboards/dashboards.component';
import { LoginComponent } from './main/authentication/login/login.component';
import { RegisterComponent } from './main/authentication/register/register.component';
import { ForgotPasswordComponent } from './main/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './main/authentication/reset-password/reset-password.component';
import { AuthGuard } from './main/authentication/guards/auth.guard';
import { ActivationManageComponent } from './main/activation-manage/activation-manage.component';
import { CandidateDownloadComponent } from './main/candidate-download/candidate-download.component';
import { RedirectGuard } from './core/guards/redirect.guard';
import { LoginGuard } from './main/authentication/guards/login.guard';
import { AppReportComponent } from './main/app-report/app-report.component';
import { AppReportGuard } from './main/authentication/guards/app-report.guard';
import { CompanyManageComponent } from "./main/company-manage/company-manage.component";
import { CandidateManageComponent } from "./main/candidate-manage/candidate-manage.component";

const routes: Routes = [
    {
        path: '',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
            externalUrl: 'https://home.viecnhamay.vn/'
        },
        pathMatch: 'full',
    },
    {
        path: 'dashboards',
        component: DashboardsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'download',
        component: CandidateDownloadComponent,
    },
    {
        path: 'auth/login',
        component: LoginComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'auth/register',
        component: RegisterComponent,
    },
    {
        path: 'auth/forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'auth/reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'account',
        loadChildren: './main/account/account.module#AccountModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'database',
        loadChildren: './main/database/database.module#DatabaseModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'campaigns',
        loadChildren: './main/campaign/campaign.module#CampaignModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'activation-manage',
        component: ActivationManageComponent,
        // loadChildren: './main/activation-manage/activation-manage.module#ActivationManageModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'company-manage',
        component: CompanyManageComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'candidate-manage',
        component: CandidateManageComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'report',
        component: AppReportComponent,
        canActivate: [AppReportGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
