import {
    Component,
    Inject,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { CompanyService } from '../company/company.service';
import { takeUntil } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { get, isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-poster',
    templateUrl: './poster.component.html',
    styleUrls: ['./poster.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class PosterComponent implements OnInit {
    @Input()
    posterList: any;
    isPosterListSmallView = false;
    selectedPosterIndex: -1;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    translate = [];
    private _unsubscribeAll: Subject<any>;

    constructor(
        private translateService: TranslateService,
        public dialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private _fuseProgressBarService: FuseProgressBarService,
        private _companyService: CompanyService
    ) {
        this.posterList = [];

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._companyService.getCompanyPosters().then(() => {});

        this._companyService.onCompanyChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((company) => {
                this.posterList = company.posters;
                this.posterList.sort((p1, p2) => p2.id - p1.id);
                this.posterList.forEach((poster) => {
                    poster.textColor = poster.contentColor;
                });

                this._fuseProgressBarService.hide();
            });

        this.translateService
            .get('POSTER_COMPONENT')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((t) => {
                this.translate = t;
            });

        this.translateService.onLangChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((t) => {
                const translation =
                    get(t, 'translations["POSTER_COMPONENT"]') || {};
                if (!isEmpty(translation)) {
                    this.translate = translation;
                }
            });
    }

    selectPoster(poster) {
        this.selectedPosterIndex = poster.index;
    }

    deletePoster(poster) {
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });

        this.confirmDialogRef.componentInstance.confirmMessage =
            this.translate['CONFIRM_DELETE'];

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.handleDeletePoster(poster, true, () => {
                    this._matSnackBar.open(this.translate['SAVE_SUCCESS'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                });
            }
            this.confirmDialogRef = null;
        });
    }

    editPoster(poster) {
        this.openPosterCreatorDialog({
            action: 'edit',
            poster,
        });
    }

    addPoster() {
        this.openPosterCreatorDialog({
            action: 'new',
        });
    }

    openPosterCreatorDialog(data: any) {
        const dialogRef = this.dialog.open(PosterCreatorDialogComponent, {
            panelClass: 'poster-form-dialog',
            data,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (isEmpty(result)) {
                return;
            }

            result.contentColor = result.textColor;
            this._fuseProgressBarService.show();

            const { bgType = '' } = result;
            if (data.action === 'new') {
                this.saveNewPoster(result);
            } else {
                result.id = data.poster.model.id;
                const that = this;
                this._companyService
                    .editCompanyPoster(result)
                    .then((success) => {
                        this._matSnackBar.open(this.translate['SAVE_SUCCESS'], 'OK', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                    })
                    .catch((error) => {
                        this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                        that._fuseProgressBarService.hide();
                    });
            }
        });
    }

    saveNewPoster(poster) {
        this._companyService
            .saveCompanyPoster(poster)
            .then((success) => {
                this._fuseProgressBarService.hide();
                this._matSnackBar.open(this.translate['SAVE_SUCCESS'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
            })
            .catch((error) => {
                this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                this._fuseProgressBarService.hide();
            });
    }

    handleDeletePoster(poster, withRefresh = true, callback = () => {}) {
        this._companyService
            .deleteCompanyPoster(poster.model, withRefresh)
            .then((res) => {
                callback();
            })
            .catch((err) => {
                this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                console.log('Error while deleting', err);
            });
    }
}

export interface PosterCreatorData {
    action: any;
    poster: any;
}

@Component({
    selector: 'app-poster-creator-dialog',
    templateUrl: './poster-creator-dialog.html',
    styleUrls: ['./poster-creator-dialog.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PosterCreatorDialogComponent implements OnInit {
    poster: any;
    posterSizeModel: any;
    constructor(
        public dialogRef: MatDialogRef<PosterCreatorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PosterCreatorData
    ) {}

    ngOnInit(): void {
        if (this.data.action === 'edit') {
            this.poster = { model: this.data.poster };
            this.posterSizeModel = this.data.poster.type;
        } else {
            this.poster = {};
            this.posterSizeModel = 'horizontal';
        }
    }

    getPosterCreatorApi(api) {
        this.poster.api = api;
    }

    onButtonEmitter(name) {
        switch (name) {
            case 'save':
                this.onClickSavePosterCreator();
                break;
            default:
                this.onNoClick();
                break;
        }
    }

    onClickSavePosterCreator() {
        const poster = this.poster.api.getPosterModel();
        this.dialogRef.close(Object.assign({}, poster));
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
