import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ProfileService } from "../../profile/profile.service";

@Component({
    selector: "fw-set-password-form",
    templateUrl: "./set-password-form.component.html",
    styleUrls: ["./set-password-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SetPasswordFormDialogComponent {
    form: FormGroup;
    dialogTitle: string;
    requestCurrentPassword: boolean;
    hide = true;
    hideP = true;
    hidePC = true;
    wrongPassword = false;

    constructor(
        public matDialogRef: MatDialogRef<SetPasswordFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private _profileService: ProfileService
    ) {
        this.requestCurrentPassword = _data.requestCurrentPassword || false;
        this.dialogTitle = "Change Password";
        this.form = this.createForm(this.requestCurrentPassword);
    }

    private matchingPasswords(
        passwordKey: string,
        confirmPasswordKey: string
    ): any {
        return (group: FormGroup): { [key: string]: any } => {
            const password = group.controls[passwordKey];
            const confirmPassword = group.controls[confirmPasswordKey];

            if (password.value !== confirmPassword.value) {
                return {
                    mismatchedPasswords: true,
                };
            }
        };
    }

    createForm(requireCurrentPassword: boolean): FormGroup {
        if (requireCurrentPassword) {
            return this._formBuilder.group(
                {
                    userId: [this._data.userId, Validators.required],
                    currentPassword: ["", Validators.required],
                    password: ["", Validators.required],
                    passwordConfirmation: ["", Validators.required],
                },
                {
                    validator: this.matchingPasswords(
                        "password",
                        "passwordConfirmation"
                    ),
                }
            );
        }
        return this._formBuilder.group(
            {
                userId: [this._data.userId, Validators.required],
                password: ["", Validators.required],
                passwordConfirmation: ["", Validators.required],
            },
            {
                validator: this.matchingPasswords(
                    "password",
                    "passwordConfirmation"
                ),
            }
        );
    }

    mismatchPassword() {
        return (
            this.form.errors?.["mismatchedPasswords"] &&
            (this.form.touched || this.form.dirty)
        );
    }

    savePassword() {
        const formData = this.form.getRawValue();
        this.wrongPassword = false;
        this._profileService
            .changePassword(
                {
                    password: formData.currentPassword,
                    newPassword: formData.password,
                },
                formData.userId
            )
            .then((response) => {
                console.log("sucess", response);
                this._matSnackBar.open("Đổi mật khẩu thành công!", "OK", {
                    verticalPosition: "top",
                    duration: 2000
                });
                this.matDialogRef.close(true);
            })
            .catch((err) => {
                this._matSnackBar.open("Mật khẩu hiện tại không chính xác!", "OK", {
                    verticalPosition: "top",
                    duration: 2000
                });
                this.wrongPassword = true;
                console.log("cannot change password", err);
            });
    }
}
