import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../core/services/base.service';

@Injectable()
export class LoginService extends BaseService {

  constructor(private _httpClient: HttpClient) {
    super();
  }

  login(identity, password): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(this.getFullUrl('r/login'), {
          identity,
          password
        })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
