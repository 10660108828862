import { FuseUtils } from '@fuse/utils';

export interface AccountUpdate {
    firstName: string;
    lastName: string;
    email: string;
    staffCode: string;
    birthday: Date;
    gender: string;
    phone: string;
    role: string;
}

export class Account {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
    phone: string;
    staffCode: string;
    title: string;
    email: string;
    birthday: Date;
    disable: boolean;
    gender: string;
    role: string;

    constructor(account) {
        {
            this.id = account.id || FuseUtils.generateGUID();
            this.firstName = account.firstName || '';
            this.lastName = account.lastName || '';
            this.avatar = account.avatar || 'assets/images/avatars/profile.jpg';
            this.staffCode = account.staffCode || '';
            this.title = account.title || '';
            this.email = account.email || '';
            this.phone = account.phone || '';
            this.disable = account.disable || false;
            this.birthday = account.birthday || null;
            this.gender = account.gender || '';
            this.role = account.role || '';
        }
    }
}

export enum ACCOUNT_ROLE {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    BCT_ADMIN = 'BCT_ADMIN',
    HR = 'HR',
    STAFF = 'STAFF',
}
