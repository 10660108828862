import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../company/company.service';

@Component({
    selector: 'app-poster-welfare',
    templateUrl: './poster-welfare.component.html',
    styleUrls: ['./poster-welfare.component.scss'],
})
export class PosterWelfareComponent implements OnInit {
    constructor(private companyService: CompanyService) {}

    ngOnInit(): void {
        this.companyService.getCompanyWelfare().then(() => {});
    }
}
