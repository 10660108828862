import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-report',
    templateUrl: './app-report.component.html',
    styleUrls: ['./app-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppReportComponent implements OnInit {
    statistics = {
        totalAccess: 0,
        totalSeller: 0,
        newSeller: 0,
        totalProduct: 7,
        newProduct: 7,
        totalPurchase: 0,
        successPurchase: 0,
        failPurchase: 0,
        revenue: 0,
    };
    startDate = new Date();

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _httpClient: HttpClient
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.startDate.setMonth(
            this.startDate.getFullYear() === 2024 ? 6 : 0,
            1
        );

        this._httpClient
            .get(environment.apiEndpoint + 'reports')
            .subscribe((data: any) => {
                this.statistics = data;
            });
    }
}
