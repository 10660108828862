import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { size } from 'lodash';

@Injectable({ providedIn: 'root' })
export class CandidateManageService {
    candidates: any[];
    onCandidatesChanged: BehaviorSubject<any>;
    constructor(private httpClient: HttpClient) {
        this.candidates = [];
        this.onCandidatesChanged = new BehaviorSubject<any>([]);
    }

    getCandidates({ page = 0, status = 'NOT_READY' }) {
        return new Promise((resolve) => {
            const params = new HttpParams()
                .set('page', page + '')
                .set('size', '20')
                .set('status', status);
            this.httpClient
                .get(environment.apiEndpoint + 'administrator/users/profiles', {
                    params,
                })
                .subscribe(
                    (res: any[] = []) => {
                        res.forEach((candidate: any, index) => {
                            candidate.staticIndex = page * 20 + index + 1
                            candidate.fullName = `${
                                candidate.lastName ? candidate.lastName : ''
                            } ${
                                candidate.firstName ? candidate.firstName : ''
                            }`;
                        });
                        this.candidates = res;
                        this.onCandidatesChanged.next(this.candidates);
                        resolve(res);
                    },
                    () => {
                        resolve([]);
                    }
                );
        });
    }

    approveProfile(id, isApprove) {
        if (isApprove) {
            return this.httpClient.post(
                `${environment.apiEndpoint}administrator/users/profiles/${id}/approve`,
                {}
            );
        }

        return this.httpClient.post(
            `${environment.apiEndpoint}administrator/users/profiles/${id}/reject`,
            {}
        );
    }
}
