<div mat-dialog-content fxLayout="column" class="candidate-details-dialog">
    <div class="top-btn-container">
        <span (click)="dialogRef.close(false)">
            <mat-icon class="btn-close">close</mat-icon>
        </span>
    </div>
    <div fxLayout="row" fxLayoutGap="1rem" class="mt-10">
        <div>
            <img
                class="avatar-img"
                src="{{ candidate.avatar }}"
                alt="{{ candidate.fullName }}"
            />
        </div>
        <table>
            <tr>
                <td class="cell-label">
                    {{"DATA_TABLE.fullName" | translate}}
                </td>
                <td class="cell-value">{{ candidate.fullName }}</td>
                <td class="cell-label">
                    {{"DATA_TABLE.address" | translate}}
                </td>
                <td class="cell-value">{{ candidate.currentAddress }}</td>
            </tr>
            <tr>
                <td class="cell-label">
                    {{"DATA_TABLE.gender" | translate}}
                </td>
                <td class="cell-value">{{ candidate.gender }}</td>
                <td class="cell-label">
                    {{"DATA_TABLE.permanentAddress" | translate}}
                </td>
                <td class="cell-value">{{ candidate.address }}</td>
            </tr>
            <tr>
                <td class="cell-label">{{"DATA_TABLE.age" | translate}}</td>
                <td class="cell-value">{{ candidate.age }}</td>
                <td class="cell-label">
                    {{"DATA_TABLE.health" | translate}}
                </td>
                <td class="cell-value">{{ candidate.health }}</td>
            </tr>
            <tr>
                <td class="cell-label">
                    {{"DATA_TABLE.certificates" | translate}}
                </td>
                <td class="cell-value">
                    <p *ngFor="let cer of candidate.certificateStrings">
                        {{ cer }}
                    </p>
                </td>
                <td class="cell-label">
                    {{"DATA_TABLE.experience" | translate}}
                </td>
                <td class="cell-value">
                    <p *ngFor="let exp of candidate.experienceStrings">
                        {{ exp }}
                    </p>
                </td>
            </tr>
        </table>
    </div>
    <app-candidate-details-images [profileImages]="profileImages" [isLoading]="isLoading"></app-candidate-details-images>
</div>
