import { NgModule } from "@angular/core";

import { EnumToArrayPipe } from "./enumToNumberArray.pipe";
import { CompanyAddressToStringPipe } from "./companyAddressToString.pipe";
import { AgePipe } from "./age.pipe";

@NgModule({
    declarations: [EnumToArrayPipe, CompanyAddressToStringPipe, AgePipe],
    imports: [],
    exports: [EnumToArrayPipe, CompanyAddressToStringPipe, AgePipe],
})
export class PipesModule {}
