import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
    selector: 'app-poster-text-view',
    templateUrl: './poster-text-view.component.html',
    styleUrls: ['./poster-text-view.component.scss'],
})
export class PosterTextViewComponent implements OnInit {
    @Input() textModel: any;
    nativeElement: any;

    constructor(myElement: ElementRef) {
        this.nativeElement = myElement.nativeElement;
        if (!this.textModel) {
            this.textModel = {};
        }
    }

    getPosition() {
        const height = this.nativeElement.querySelector('p').clientHeight + 2;
        // if (this.textModel.textPosition === 'bottom') {
        //     return {
        //         bottom: '0px',
        //         left: '0px',
        //     };
        // } else if (this.textModel.textPosition === 'middle') {
        //     return {
        //         top: 'calc(50% - ' + height / 2 + 'px)',
        //         left: '0px',
        //     };
        // } else {
        //     return {
        //         top: '0px',
        //         left: '0px',
        //     };
        // }
        // Change position to middle
        return {
            top: 'calc(50% - ' + height / 2 + 'px)',
            left: '0px',
        };
    }

    ngOnInit() {}
}
