<div class="poster-creator">
    <div fxLayout="row">
        <div
            fxFlex="0 0 370px"
            fxLayout="column"
            class="poster-creator-left-panel"
        >
            <div class="padding-top-10">
                <div class="padding-10">
                    <app-poster-text-editor
                        *ngIf="posterModel.posterTextModel"
                        [textModel]="posterModel.posterTextModel"
                    >
                    </app-poster-text-editor>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="color-picker-panel padding-10">
                <div class="mt-10">
                    <div class="template-container">
                        <div
                            class="color-grid-wrapper"
                            *ngFor="let src of backgroundTemplates"
                        >
                            <div
                                (click)="setBackgroundTemplate(src)"
                                class="color-picker-thumbnail pull-left"
                                [ngStyle]="getBackgroundThumbnailStyles(src)"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="mt-10">
                    <div class="template-container">
                        <div
                            class="color-grid-wrapper"
                            *ngFor="let src of backgroundTemplates2"
                        >
                            <div
                                (click)="setBackgroundTemplate(src)"
                                class="color-picker-thumbnail pull-left"
                                [ngStyle]="getBackgroundThumbnailStyles(src)"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-10" fxLayoutAlign="end start">
                <button
                    mat-raised-button
                    color="accent"
                    class="poster-creator-image-uploader-button"
                    (click)="triggerInputImage()"
                >
                    {{ 'POSTER_COMPONENT.UPLOAD_IMAGE' | translate }}
                </button>
            </div>

            <div class="poster-creator-image-uploader" fxLayoutAlign="end start">
                <div
                    class="panel panel-white"
                    [hidden]="posterModel.backgroundType != 'panel_1'"
                >
                    <div class="panel-body padding-top-10 padding-bottom-10">
                        <div class="file-upload">
                            <input
                                id="background-input"
                                #fileInput
                                type="file"
                                (change)="fileChangeListener($event)"
                            />
                        </div>
                        <img-cropper
                            #cropper
                            [image]="posterModel.imageData"
                            [settings]="posterModel.cropperSettings"
                        ></img-cropper>

                        <img
                            #loadedImg
                            style="display: none"
                            src="{{ posterModel.imageData.image }}"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="0 0 370px" fxLayout="column">
            <div class="user-view-container">
                <div class="user-view-mobile">
                    <img
                        class="user-view-frame"
                        src="./assets/svgs/iphone-frame.svg"
                        alt=""
                    />
                    <div class="user-view-poster">
                        <app-poster-view
                            *ngIf="posterModel"
                            [posterModel]="convertToSimplePoster(posterModel)"
                            [customTextSize]="9.4"
                        ></app-poster-view>
                    </div>
                </div>
            </div>

            <div fxLayout="row" class="mt-8" fxLayoutAlign="end start">
                <button
                    style="margin-right: 8px"
                    mat-raised-button
                    color="warn"
                    (click)="onClickButton('cancel')"
                >
                    {{ 'COMMON.CANCEL' | translate }}
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    (click)="onClickButton('save')"
                >
                    {{ 'COMMON.SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
