import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { CompanyService } from '../../../../../main/account/company/company.service';
import { isEmpty } from 'lodash';
import { ImageCropperDialog } from '../../../image-cropper/image-cropper.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../../main/authentication/auth.service';

@Component({
    selector: 'app-navbar-vertical-style-5worker',
    templateUrl: './style-5worker.component.html',
    styleUrls: ['./style-5worker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle5workerComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    loadedLogo = false;
    isLoadingLogo = false;
    isAdmin = false;
    logo = '';

    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _authService: AuthService,
        private _companyService: CompanyService,
        public _dialog: MatDialog,
        private _router: Router
    ) {
        this._unsubscribeAll = new Subject();
    }

    @ViewChild(FusePerfectScrollbarDirective)
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    const activeNavItem: any = document.querySelector(
                        'navbar .nav-link.active'
                    );

                    if (activeNavItem && activeNavItem.offsetParent) {
                        const activeItemOffsetTop = activeNavItem.offsetTop;
                        const activeItemOffsetParentTop =
                            activeNavItem.offsetParent.offsetTop;
                        const scrollDistance =
                            activeItemOffsetTop -
                            activeItemOffsetParentTop -
                            48 * 3;

                        this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                    }
                });
            });
    }

    ngOnInit(): void {
        const currentUser = this._authService.getCurrentUser();
        this.isAdmin = currentUser ? currentUser.isAdmin : false;

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar('navbar')) {
                    this._fuseSidebarService.getSidebar('navbar').close();
                }
            });

        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation =
                    this._fuseNavigationService.getCurrentNavigation();
            });

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        this.logo = this._companyService.companyLogo;
        this.loadedLogo = !isEmpty(this.logo);
        if (!this.loadedLogo) {
            this.isLoadingLogo = true;
            this._companyService
                .getCompanyLogo()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(
                    (response: any) => {
                        const logo = response.logo;
                        if (isEmpty(logo)) {
                            this.logo = '';
                        } else {
                            this.logo = logo;
                            this._companyService.companyLogo = logo;
                        }
                        this.loadedLogo = true;
                        this.isLoadingLogo = false;
                    },
                    (error) => {
                        this.isLoadingLogo = false;
                        this.logo = '';
                        console.log('Cannot get logo', error);
                    }
                );
        }
    }

    fileChangeEvent(): void {
        const dialogRef = this._dialog.open(ImageCropperDialog, {
            panelClass: 'logo-form-dialog',
            data: { aspectRatio: 1.81, resizeToWidth: '200px' },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.saveCompanyLogo(result);
        });
    }

    saveCompanyLogo(src: any): void {
        this.isLoadingLogo = true;
        this._companyService
            .saveCompanyLogo(src)
            .then((response) => {
                this.logo = response.url;
                this.isLoadingLogo = false;
            })
            .catch((err) => {
                this.isLoadingLogo = false;
                console.log('cannot upload logo', err);
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._companyService.companyLogo = null;
    }
}
