<div *ngIf="params.colDef.field == 'status'">
    <mat-checkbox
        *ngIf="!isPending(params.data.status)"
        [checked]="!isPending(params.data.status)"
        disabled="true"
    ></mat-checkbox>
    <mat-checkbox
        *ngIf="isPending(params.data.status)"
        [(ngModel)]="params.data.paid"
    ></mat-checkbox>
</div>
<div *ngIf="params.colDef.field == 'action'">
    <a
        *ngIf="
                            params.data.status !== 'ACTIVE' &&
                            params.data.paid
                        "
        mat-raised-button
        color="accent"
        (click)="activeOrder()"
    >
        Kích hoạt
    </a>

    <a
        *ngIf="params.data.status === 'ACTIVE'"
        disabled
        mat-raised-button
        color="accent"
    >
        Đã kích hoạt
    </a>
    <a
        *ngIf="params.data.status === 'EXPIRED'"
        disabled
        mat-raised-button
        color="accent"
    >
        Đã hết hạn
    </a>
</div>

<div *ngIf="params.colDef.field == 'price'">
    <input type="number" [(ngModel)]="params.data.price" class="price-input" />
    <mat-icon class="icon-save" color="accent" (click)="updatePrice()">
        save
    </mat-icon>
</div>
