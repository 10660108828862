import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Company } from './company.model';
import { CompanyService } from './company.service';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CompanyComponent implements OnInit, OnDestroy {
    company: Company;
    isProcessing: boolean;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _companyService: CompanyService,
        private _formBuilder: FormBuilder,
        private _location: Location,
        private _matSnackBar: MatSnackBar
    ) {
        this.company = new Company();
        this.isProcessing = false;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._companyService.onCompanyChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((company) => {
                this.company = new Company(company);
            });
        this._companyService.onProcessingChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isProcessing) => (this.isProcessing = isProcessing));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
