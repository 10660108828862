<h1 matDialogTitle>
    {{ (title ? title : 'DIALOG.CONFIRM_TITLE') | translate }}
</h1>
<div mat-dialog-content>
    <p class="text-center" [innerHTML]="confirmMessage"></p>
    <div fxLayout="row">
        <div fxFlex="1 1 auto"></div>
        <div fxFlex="1 1 auto">
            <p *ngFor="let p of paragraph" class="text-justify">
                {{ p | translate }}
            </p>
            <table>
                <tr *ngFor='let r of rows' [innerHTML]='r'></tr>
            </table>
        </div>
        <div fxFlex="1 1 auto"></div>
    </div>
    <mat-form-field
        style="width: 100%"
        appearance="outline"
        *ngIf="inputLabel.length"
    >
        <mat-label>{{ inputLabel | translate }}</mat-label>
        <input [(ngModel)]="input" matInput type="text" />
    </mat-form-field>
</div>
<div mat-dialog-actions class="pt-24 pb-24 center">
    <div class="button-wrapper">
        <button
            mat-raised-button
            color="accent"
            (click)="dialogRef.close(getReturnValue())"
        >
            {{ (yesLabel ? yesLabel : 'COMMON.AGREE') | translate }}
        </button>
        <button
            mat-raised-button
            color="primary"
            class="ml-16"
            (click)="dialogRef.close(false)"
            *ngIf="!noCancel"
        >
            {{ (noLabel ? noLabel : 'COMMON.QUIT') | translate }}
        </button>
    </div>
</div>
