import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';
import { Member } from '../../../../shared/models/company.model';
import { cloneDeep, isEmpty } from 'lodash';

@Component({
    selector: 'app-result-request-confirm-dialog',
    templateUrl: './result-request-confirm-dialog.component.html',
    styleUrls: ['./result-request-confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ResultRequestConfirmDialogComponent implements OnInit {
    members = [];
    reviewerList = [];
    reviewerId = '';
    approverId = '';
    acceptingDeadline = '';
    countOfficial = 0;
    countBackup = 0;

    constructor(
        public dialogRef: MatDialogRef<ResultRequestConfirmDialogComponent>
    ) {}

    ngOnInit() {
        this.reviewerList = cloneDeep(this.members);
        this.reviewerList.push(
            new Member({
                id: 0,
                firstName: 'Không áp dụng',
                lastName: '',
            })
        );
        this.reviewerList.sort((r1, r2) => r1.id - r2.id);
    }

    getFullNamAndEmail(member: Member) {
        const fullName = `${member.firstName} ${member.lastName}`;
        return isEmpty(member.email) ? fullName : `${fullName} (${member.email})`;
    }

    getReturnValue() {
        return {
            reviewerId: this.reviewerId,
            approverId: this.approverId,
        };
    }
}
