<div class="multi-select-actions" fxLayout="row" fxLayoutAlign="end start">
    <button
        class="mr-4"
        mat-raised-button
        color="accent"
        [disabled]="disabled || !hasSelectedContacts"
        (click)="
            evaluateSelectedCandidates(
                candidateEvaluationEnum.PASS,
                recruitmentTypeEnum.IMMEDIATE
            )
        "
    >
        <span class="text-uppercase">Tuyển chính thức</span>
    </button>
    <button
        class="ml-4 mr-4"
        mat-raised-button
        color="accent"
        [disabled]="disabled || !hasSelectedContacts"
        (click)="
            evaluateSelectedCandidates(
                candidateEvaluationEnum.PASS,
                recruitmentTypeEnum.ADDITIONAL
            )
        "
    >
        <span class="text-uppercase">Tuyển dự bị</span>
    </button>
    <button
        class="ml-4"
        mat-raised-button
        color="warn"
        [disabled]="disabled || !hasSelectedContacts"
        (click)="
            evaluateSelectedCandidates(
                candidateEvaluationEnum.FAIL,
                recruitmentTypeEnum.FAIL
            )
        "
    >
        <span class="text-uppercase">Không tuyển dụng</span>
    </button>
</div>
