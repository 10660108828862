export const CAMPAIGN_RESULT_SORT_TYPE = {
    AGE: 'AGE',
    AVERAGE: 'AVERAGE',
    EXPERIENCE: 'EXPERIENCE',
    HEALTH: 'HEALTH',
    ROUND_SCORE: 'ROUND_SCORE',
    ROUND_PREREQUISITE_SCORE: 'ROUND_PREREQUISITE_SCORE',
    SCORE: 'SCORE',
    PREREQUISITE_SCORE: 'PREREQUISITE_SCORE',
};
