import { FuseNavigation } from '@fuse/types';

export const hrNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'campaign',
                title: 'Campaign',
                translate: 'NAV.CAMPAIGN.TITLE',
                type: 'item',
                icon: 'work_outline',
                url: '/campaigns',
            },
            {
                id: 'database',
                title: 'Database',
                translate: 'NAV.DATABASE.TITLE',
                type: 'item',
                icon: 'list',
                url: '/database',
            },
            {
                id: 'welfare',
                title: 'Welfare',
                translate: 'NAV.WELFARE.TITLE',
                type: 'item',
                icon: 'group',
                url: '/account/welfare',
            },
        ],
    },
];
