import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
    ElementRef,
} from '@angular/core';

@Component({
    selector: 'app-poster-text-editor',
    templateUrl: './poster-text-editor.component.html',
    styleUrls: ['./poster-text-editor.component.scss'],
})
export class PosterTextEditorComponent implements OnInit, OnChanges {
    @Input() textModel: any;
    textModelStyle: any;
    fonts: any;
    sizes: Array<any>;
    isShowingAlignment: boolean;
    isShowingPosition: boolean;
    textColor: any;
    bgColor: any;
    presetColors: any;
    bgColorPickerToggle = false;
    textColorPickerToggle = false;
    colorPickerToggle = false;
    lastColor: any;

    constructor() {
        this.fonts = [
            { name: 'Arial', style: 'Arial,Helvetica,sans-serif' },
            { name: 'Georgia', style: 'Georgia,serif' },
            { name: 'Impact', style: 'Impact,Charcoal,sans-serif' },
            { name: 'Tahoma', style: 'Tahoma,Geneva,sans-serif' },
            {
                name: 'Times New Roman',
                style: 'Times New Roman,Times,serif,-webkit-standard',
            },
            { name: 'Verdana', style: 'Verdana,Geneva,sans-serif' },
        ];
        this.sizes = [8, 9, 10, 11, 12, 14, 18, 21, 24, 30, 36, 48];
        this.presetColors = [
            '#007bff',
            '#0062cb',
            '#130eca',
            '#6610f2',
            '#6f42c1',
            '#e83e8c',
            '#dc3545',
            '#fd7e14',
            '#ffc107',
            '#ffff0f',
            '#28a745',
            '#00af68',
            '#20c997',
            '#17a2b8',
            '#fff',
            '#868e96',
            '#343a40',
            '#000'
        ];
    }

    ngOnInit() {
        this._initEditorValues();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._initEditorValues();
    }

    _initEditorValues() {
        const textModel = this.textModel;
        if (!textModel.style) {
            textModel.style = {};
        }
        if (!textModel.style['text-align']) {
            textModel.style['text-align'] = 'center';
        }
        if (!textModel.textPosition) {
            textModel.textPosition = 'top';
        } else {
            textModel.textPosition = textModel.textPosition
                ? textModel.textPosition
                : 'top';
        }
        if (!textModel.style['font-family']) {
            textModel.style['font-family'] = 'Arial,Helvetica,sans-serif';
        }
        if (!textModel.size) {
            textModel.size = 21;
        }
        if (!textModel.textColor) {
            this.textColor = '#000';
        } else {
            this.textColor = textModel.textColor;
        }

        textModel.bgColor = '#ffffff';
        textModel.style['background-color'] = '#ffffff';
        textModel.style['opacity'] = '0.7';
    }

    toggleDropDown(dropdown) {
        const dropdownStatus = !this[dropdown];
        this.closeAllDropdown();
        this[dropdown] = dropdownStatus;
    }

    closeAllDropdown() {
        this.isShowingAlignment = false;
        this.isShowingPosition = false;
    }

    setBackgroundColor(color) {
        this.textModel.style['background-color'] = color;
    }

    increaseSize() {
        this.textModel.size += 2;
    }

    decreaseSize() {
        this.textModel.size -= 2;
    }

    setTextColor(color) {
        this.textModel.style['color'] = color;
        this.textModel.textColor = color;
    }

    getPositionFromIcon(icon) {
        if (icon === 'fa-arrow-up') {
            return 'top';
        } else if (icon === 'fa-arrow-down') {
            return 'bottom';
        } else {
            return 'middle';
        }
    }

    getColorPickerTextColor(color: any) {
        if (color === undefined) {
            return '#ffffff';
        }
        const _color = (color + '').toLowerCase();
        if (
            _color.charCodeAt(1) + _color.charCodeAt(3) + _color.charCodeAt(5) >
                250 ||
            _color === '#fff'
        ) {
            return '#000000';
        }
        return '#ffffff';
    }
}
