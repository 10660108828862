<div *ngIf="field === 'action'">
    <a *ngIf="approved" disabled mat-raised-button color="accent"> Đã duyệt </a>

    <a *ngIf="!approved" mat-raised-button color="accent" (click)="active()">
        Chờ duyệt
    </a>
</div>
<div *ngIf="field === 'companyRegistrationImages'">
    <span *ngFor="let doc of documents; let i = index">
        {{i == 0 ? '' : ' - '}}<a [href]="doc.url"
            >[{{doc.fileName}}]
        </a>
    </span>
</div>
