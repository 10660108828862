import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { FuseSharedModule } from "@fuse/shared.module";

import { LoginComponent } from "app/main/authentication/login/login.component";
import { LoginService } from "app/main/authentication/login/login.service";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

const routes = [
    {
        path: "auth/login",
        component: LoginComponent
    }
];

@NgModule({
    declarations: [LoginComponent],
  imports: [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatGridListModule,

    FuseSharedModule,
    MatProgressSpinnerModule
  ],
    providers: [LoginService]
})
export class LoginModule {}
