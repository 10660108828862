import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CampaignResultService } from '../campaign-result.service';
import {
    CandidateEvaluationEnum,
    RecruitmentTypeEnum,
} from 'app/shared/models/campaign.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay } from '@angular/cdk/overlay';

@Component({
    selector: 'fw-campaign-result-selected-bar',
    templateUrl: './campaign-result-selected-bar.component.html',
    styleUrls: ['./campaign-result-selected-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CampaignResultSelectedBarComponent implements OnInit, OnDestroy {
    @Input() disabled = false;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    hasSelectedContacts: boolean;
    selectedContacts: string[];
    candidateEvaluationEnum = CandidateEvaluationEnum;
    recruitmentTypeEnum = RecruitmentTypeEnum;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _campaignResultService: CampaignResultService,
        public _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private overlay: Overlay
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._campaignResultService.onSelectedCandidatesChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((selectedCandidates) => {
                this.selectedContacts = selectedCandidates;
                setTimeout(() => {
                    this.hasSelectedContacts = selectedCandidates.length > 0;
                }, 0);
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    evaluateSelectedCandidates(
        candidateEvaluation: CandidateEvaluationEnum,
        recruitmentType: RecruitmentTypeEnum
    ): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: false,
                scrollStrategy: this.overlay.scrollStrategies.noop(),
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage = `Bạn có muốn đánh giá các ứng viên đã chọn?`;

        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._campaignResultService.evaluateSelectedCandidates(
                    candidateEvaluation,
                    recruitmentType,
                    () => {
                        this._matSnackBar.open(
                            'Đánh giá các ứng viên thành công',
                            'OK',
                            {
                                verticalPosition: 'top',
                                duration: 2000,
                            }
                        );
                    }
                );

            }
            this.confirmDialogRef = null;
        });
    }
}
