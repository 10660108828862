import {
    Component,
    OnInit,
    Inject,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { get } from 'lodash';
import { GoogleMapGuideComponent } from '../../shared/google-map-guide/google-map-guide.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'fw-add-branch-form',
    templateUrl: './add-branch-form.component.html',
    styleUrls: ['./add-branch-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddBranchFormDialogComponent implements OnInit, AfterViewInit {
    responseData: any;
    hasGeography: boolean;
    branchForm: FormGroup;
    defaultData: any;

    constructor(
        public matDialogRef: MatDialogRef<AddBranchFormDialogComponent>,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.responseData = {
            address: {
                address: 'string',
                name: 'string',
                town: 0,
                industryPark: 0,
                mapLink: '',
            },
        };
        this.defaultData = {};
    }

    ngAfterViewInit(): void {
        if (this._data.branch) {
            this.responseData.address.town = this.defaultData.townId || null;
            this.responseData.address.industryPark =
                this.defaultData.industryParkId || null;
        }
    }

    ngOnInit(): void {
        this.branchForm = this.createBranchForm();
        this.defaultData = get(this._data,'branch.address') || {}
    }

    createBranchForm(): FormGroup {
        return this._formBuilder.group({
            name: [this._data.name || '', Validators.required],
            address: [this._data.address || '', Validators.required],
            mapLink: [get(this._data, 'branch.address.mapLink') || ''],
        });
    }

    getData(data) {
        this.hasGeography = true;
        this.responseData.address.town = (data.town && data.town.id) || null;
        this.responseData.address.industryPark =
            (data.industryPark && data.industryPark.id) || null;
    }

    onYesClick() {
        const textData = this.branchForm.getRawValue();
        this.responseData.address.name = textData.name;
        this.responseData.address.address = textData.address;
        this.responseData.address.mapLink = textData.mapLink;
        return this.responseData;
    }

    showMapGuide(){
        this._matDialog.open(GoogleMapGuideComponent);
    }
}
