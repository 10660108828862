<mat-toolbar>

    <div fxLayout="row-reverse" fxLayoutAlign="center center"  fxLayoutAlign.gt-xs="space-between center" fxFlex>
        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <img class="footer-logo-right" src="../assets/images/logos/logo5workerBlue.png">
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxHide fxShow.gt-xs>
            <span class="footer-address">
                Đường số 1, Khu A, Phường X, Quận Y, HCMC - 5worker@gmail.com
            </span>
            <span class="footer-hotline">
                Hotline: 0123587462
            </span>
        </div>
    </div>

</mat-toolbar>