import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CampaignTableData, CampaignCreatingModel } from './campaign.model';
import { BaseService } from 'app/core/services/base.service';
import { GeographyService } from '../geography/geography.service';
import { AuthService } from '../authentication/auth.service';
import {
    CandidateGuide,
    ResultEvaluationTypeEnum,
    Round,
    TestTypeEnum,
} from '../../shared/models/campaign.model';
import { cloneDeep, isEmpty } from 'lodash';
import { Address } from '../../shared/models/company.model';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CandidateDetailsDialogComponent } from './shared/candidate-details-dialog/candidate-details-dialog.component';

@Injectable()
export class CampaignService extends BaseService implements Resolve<any> {
    backupRound = new Round();
    campaign = new CampaignTableData();
    isLead = false;
    isApprover = false;
    isReviewer = false;
    isInAdminGroup = false;
    generalInfoFormGroup: FormGroup;
    currentRoundData: any;

    onCampaignChanged: BehaviorSubject<any>;
    onMembersChanged: BehaviorSubject<any[]>;
    onCompanyBranchesChanged: BehaviorSubject<any[]>;
    onProvincesChanged: BehaviorSubject<any[]>;
    onIndustrialParkChanged: BehaviorSubject<any[]>;
    onQuizzesChanged: BehaviorSubject<any[]>;
    onRoundChange: BehaviorSubject<any>;
    onCampaignSpinnerChange: BehaviorSubject<boolean>;
    onCurrentRoundDataChange: BehaviorSubject<any>;
    onRoundListChange: BehaviorSubject<any[]>;
    onEditingCampaignChange = new BehaviorSubject(false);
    onRoundEditingStatusChange = new BehaviorSubject(false);

    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private _geographyService: GeographyService,
        public matDialog: MatDialog,
        private _authService: AuthService
    ) {
        super();
        this.onCampaignChanged = new BehaviorSubject({});
        this.onMembersChanged = new BehaviorSubject([]);
        this.onCompanyBranchesChanged = new BehaviorSubject([]);
        this.onProvincesChanged = new BehaviorSubject([]);
        this.onIndustrialParkChanged = new BehaviorSubject([]);
        this.onQuizzesChanged = new BehaviorSubject([]);
        this.onRoundChange = new BehaviorSubject({
            index: -1,
            round: null,
        });
        this.onCampaignSpinnerChange = new BehaviorSubject(false);
        this.onCurrentRoundDataChange = new BehaviorSubject({});
        this.onRoundListChange = new BehaviorSubject([]);
        this.currentRoundData = {};
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCampaign(route.params.id),
                this.getMembers(),
                this.getCompanyBranches(),
                this.getProvinces(),
                this.getIndustrialParks(),
            ]).then(() => {
                resolve(true);
            }, reject);
        });
    }

    getCampaign(campaignId): Promise<any> {
        return new Promise((resolve, reject) => {
            if (campaignId === 'new') {
                this._httpClient
                    .post(
                        this.getFullUrl('companies/campaigns'),
                        new CampaignCreatingModel(
                            new CampaignTableData({
                                lead: {
                                    id: this._authService.getCurrentUser()
                                        .identity,
                                },
                            })
                        )
                    )
                    .subscribe((response: any) => {
                        this.router.navigate(['campaigns', response.id]);
                        this.campaign = response;
                        resolve(response);
                    }, reject);
            } else {
                this._httpClient
                    .get(this.getFullUrl('companies/campaigns/') + campaignId)
                    .subscribe((response: any) => {
                        const campaign = new CampaignTableData(response);
                        this.onCampaignChanged.next(campaign);
                        this.campaign = campaign;
                        resolve(campaign);
                    }, reject);
            }
        });
    }

    getMembers(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this.getFullUrl('companies/accounts'))
                .subscribe((response: any) => {
                    this.onMembersChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }

    getCompanyBranches(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this.getFullUrl(`companies/branches`))
                .subscribe((response: any) => {
                    this.onCompanyBranchesChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }

    getProvinces(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._geographyService.getProvinces().then((response) => {
                this.onProvincesChanged.next(response);
                resolve(response);
            }, reject);
        });
    }

    getIndustrialParks(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._geographyService.getIndustrialParks().then((response) => {
                this.onIndustrialParkChanged.next(response);
                resolve(response);
            }, reject);
        });
    }

    getCandidateOriginalProfile(email: string): Observable<any> {
        return this._httpClient.post(this.getFullUrl('users/profile'), {
            email,
        });
    }

    selectRound(index: number, round: Round, canEdit = false) {
        this.backupRound = cloneDeep(round);
        this.onRoundEditingStatusChange.next(canEdit);
        this.onRoundChange.next({
            index,
            round,
        });
    }

    deselectRound() {
        this.backupRound = null;
        this.onRoundChange.next({
            index: -1,
            round: null,
        });
        this.onRoundEditingStatusChange.next(false);
    }

    revertRound(index: number) {
        this.onRoundChange.next({
            index,
            round: cloneDeep(this.backupRound),
        });
        this.onRoundEditingStatusChange.next(false);
    }

    resetBackupRound(round = new Round()) {
        if (isEmpty(round)) {
            return;
        }
        if (round.id || round.id === 0) {
            this.backupRound = cloneDeep(round);
            return;
        }
        this.backupRound = cloneDeep(this.backupRound);
    }

    initializeNewRound(type, branch): Round {
        const defaultPlace = {
            id: branch.id,
            address: this.transformAddressToText(branch.address),
        };
        const organizers =
            type === TestTypeEnum.TEST
                ? []
                : [this._authService.getCurrentUser().identity];
        const note =
            type === TestTypeEnum.TEST
                ? 'Mang theo điện thoại đã cài app Việc Nhà Máy, có 4G và sạc đầy pin để làm bài kiểm tra'
                : '';
        return {
            id: 0,
            type,
            evaluationType: ResultEvaluationTypeEnum.MANUAL,
            activeFrom: null,
            activeTo: null,
            sieve: null,
            testId: 0,
            candidateGuide: {
                startTime: null,
                contactPhone: '',
                contactName: '',
                place: defaultPlace,
                note,
            } as CandidateGuide,
            organizers,
        } as Round;
    }

    transformAddressToText(address: Address): string {
        return `${this.toString(address.address)}${this.toString(
            address.industryPark
        )}${this.toString(address.town)}${this.toString(
            address.district
        )}${this.toString(address.province, true)}`;
    }

    showCandidateDetails(candidate: any) {
        const popup = this.matDialog.open(CandidateDetailsDialogComponent, {
            panelClass: 'candidate-detail-dialog-modal',
        });
        popup.componentInstance.candidate = candidate;
    }

    storeCampaign(campaign: CampaignTableData) {
        this.campaign = campaign;
        const currentUserId = this._authService.getCurrentUserId();
        this.isLead = campaign.ownerId === currentUserId;
        this.isApprover = campaign.approverId === currentUserId;
        this.isReviewer = campaign.reviewerId === currentUserId;
        this.isInAdminGroup = this.isLead || this.isReviewer || this.isApprover;
    }

    clearCampaign() {
        this.campaign = null;
        this.isLead = false;
        this.isApprover = false;
        this.isReviewer = false;
        this.isInAdminGroup = false;
    }

    private toString(str: string, isLast: boolean = false): string {
        return str ? `${str}${isLast ? '.' : ', '}` : '';
    }
}
