<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{
                'COMPANY.BRANCH' | translate
            }}</span>
            <button
                mat-icon-button
                (click)="matDialogRef.close()"
                aria-label="Close dialog"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0">
        <form name="branchForm" fxLayout="column" [formGroup]="branchForm">
            <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="w-100-p"
            >
                <mat-label>{{ 'COMMON.NAME' | translate }}*</mat-label>
                <input matInput name="name" formControlName="name" />
            </mat-form-field>

            <fw-geography
                [selectedData]="defaultData"
                (geographyEmitter)="getData($event)"
            ></fw-geography>

            <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="w-100-p"
            >
                <mat-label>{{ 'COMPANY.ADDRESS' | translate }}*</mat-label>
                <input matInput name="address" formControlName="address" />
            </mat-form-field>

            <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="no-bottom"
            >
                <mat-label>{{ 'GEOGRAPHY.MAP_LINK' | translate }}</mat-label>
                <input
                    matInput
                    placeholder="{{ 'COMPANY.G_MAP_PLACEHOLDER' | translate }}"
                    name="mapLink"
                    formControlName="mapLink"
                />
            </mat-form-field>
            <a style="cursor: pointer" (click)="showMapGuide()">{{
                'COMPANY.G_MAP_HOW_TO' | translate
            }}</a>
        </form>
    </div>

    <div
        mat-dialog-actions
        class="m-0 p-16"
        fxLayout="row"
        fxLayoutAlign="end center"
    >
        <button
            mat-button
            mat-raised-button
            color="accent"
            class="save-button"
            [mat-dialog-close]="onYesClick()"
            [disabled]="
                !hasGeography && (branchForm.invalid || branchForm.pristine)
            "
            aria-label="SAVE"
        >
            {{ 'COMMON.SAVE' | translate }}
        </button>
    </div>
</div>
