import { CompanyService } from './company.service';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { FuseWidgetModule } from '../../../../@fuse/components';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyComponent } from './company.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ImageCropperModule } from 'ngx-img-cropper';
import { ColorPickerModule } from 'ngx-color-picker';
import { BasicInformationComponent } from './basic-information/basic-information.component';
import { PosterService } from '../poster/poster.service';
import { GeographyModule } from 'app/main/geography/geography.module';
import { LogoImageCropperFormComponent } from './basic-information/logo-image-cropper-form/logo-image-cropper-form.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { ActivationKeyComponent } from '../activation-key/activation-key.component';
import { ActivationKeyService } from '../activation-key/activation-key.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GoogleMapGuideComponent } from './shared/google-map-guide/google-map-guide.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
    declarations: [
        CompanyComponent,
        BasicInformationComponent,
        LogoImageCropperFormComponent,
        ActivationKeyComponent,
        GoogleMapGuideComponent,
    ],
    imports: [
        CommonModule,
        CompanyRoutingModule,

        NgxCurrencyModule,

        MatToolbarModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatCardModule,
        MatRadioModule,
        MatGridListModule,
        MatDividerModule,
        MatDialogModule,
        MatProgressBarModule,

        ColorPickerModule,
        ImageCropperModule,

        FuseSharedModule,
        FuseWidgetModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        GeographyModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
    ],
    providers: [CompanyService, PosterService, ActivationKeyService],
    entryComponents: [LogoImageCropperFormComponent, ActivationKeyComponent],
    exports: [GoogleMapGuideComponent],
})
export class CompanyModule {}
