<div class="content-card" fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <div fxLayout="row" fxFlexFill fxLayoutAlign="start center">
            <button
                (click)="reloadResultTable()"
                class="mr-8"
                mat-mini-fab
                color="accent"
                [disabled]="loadingIndicator"
            >
                <mat-icon>refresh</mat-icon>
            </button>
            <mat-form-field>
                <input
                    #searchInput
                    matInput
                    type="text"
                    id="filter-campaign-result-text-box"
                    placeholder="Tìm kiếm ứng viên"
                    (input)="onFilterTextBoxChanged($event)"
                    [disabled]='loadingIndicator'
                />
            </mat-form-field>
        </div>
        <ag-grid-angular
            *ngIf="!loadingIndicator"
            [ngStyle]="tableStyle"
            class="ag-theme-alpine"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData$ | async"
            [rowSelection]="'multiple'"
            (bodyScrollEnd)="onScrollEnd($event)"
            (firstDataRendered)="onLoaded($event)"
            (gridReady)="onGridReady($event)"
            (cellClicked)="onCellClicked($event)"
            (rowSelected)="onRowSelected($event)"
        ></ag-grid-angular>

        <div
            *ngIf="loadingIndicator"
            [ngStyle]="tableStyle"
            class="campaign-result-spinner"
        >
            <mat-spinner color="accent"></mat-spinner>
        </div>
    </div>
</div>
