<div id="logo-image-cropper-form" class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
            <div class="account-name mt-8">Logo</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="center">
        <img-cropper class="pull-left col-6 padding-right-0 text-right" #cropper [image]="imageData"
          [settings]="cropperSettings"></img-cropper>
    </div>
  
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button class="save-button" (click)="onNoClick()">Cancel</button>
      <button mat-button class="save-button" [mat-dialog-close]="onYesClick()" cdkFocusInitial>Ok</button>
    </div>
  </div>