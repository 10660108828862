import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ImageCropperModule } from "ngx-image-cropper";

import { FuseSharedModule } from "@fuse/shared.module";

import { ImageCropperDialog } from "app/layout/components/image-cropper/image-cropper.component";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    declarations: [ImageCropperDialog],
    imports: [
        RouterModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatDialogModule,
        FuseSharedModule,
        ImageCropperModule,
        MatIconModule,
        MatToolbarModule,
    ],
    entryComponents: [ImageCropperDialog],
    exports: [ImageCropperDialog],
})
export class ImageCropperDialogModule {}
