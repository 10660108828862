<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="title">Khôi phục mật khẩu</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Vui lòng nhập email
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        Email không hợp lệ
                    </mat-error>

                    <mat-error *ngIf="sentFail">
                        Xảy ra lỗi trong quá trình gửi, vui lòng thử lại sau.
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent" (click)="sendForgotPasswordEmail()"
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid">
                    Gửi mã xác nhận
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Quay lại</a>
            </div>

        </div>

    </div>

</div>
