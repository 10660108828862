import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EffectsRootModule } from '@ngrx/effects/src/effects_root_module';

import { AuthService } from './auth.service';
import { AuthGuard } from './guards/auth.guard';


@NgModule({
  providers:    [ AuthGuard, AuthService ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RootAuthModule,
      providers: [AuthService, AuthGuard],
    };
  }
}

@NgModule({
  imports: [
    AuthModule
  ],
})
export class RootAuthModule { }
