import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-poster-list',
    templateUrl: './poster-list.component.html',
    styleUrls: ['./poster-list.component.scss'],
})
export class PosterListComponent implements OnInit {
    @Input() posterList: any;
    @Input() deletable?: boolean;
    @Input() editable?: boolean;
    @Input() isMulti? = false;
    @Input() selectable? = true;
    @Output() selectedPosterEmitter?: EventEmitter<any> = new EventEmitter();
    @Output() deletedPosterEmitter?: EventEmitter<any> = new EventEmitter();
    @Output() editingPosterEmitter?: EventEmitter<any> = new EventEmitter();

    isDeletable: boolean;
    isEditable: boolean;
    selectedItem: number;
    selectedItems: any[];
    selectedIndexes: number[];

    constructor() {
        this.isDeletable = false;
        this.isEditable = false;
        this.selectedItems = [];
        this.selectedIndexes = [];
    }

    ngOnInit() {
        this.isDeletable = this.deletable || false;
        this.isEditable = this.editable || false;
    }

    identify(index, item) {
        return index;
    }

    selectPoster(i) {
        const selectedPosters = [];
        if (this.isMulti) {
            if (this.selectedIndexes.includes(i)) {
                this.selectedIndexes = this.selectedIndexes.filter(
                    (index) => index !== i
                );
            } else {
                this.selectedIndexes.push(i);
            }

            if (!isEmpty(this.posterList)) {
                this.selectedIndexes.forEach((index) =>
                    selectedPosters.push(this.posterList[index])
                );
            }
        } else {
            if (this.selectedIndexes.includes(i)) {
                this.selectedIndexes = [];
            } else {
                this.selectedIndexes = [i];
            }
        }
        this.selectedPosterEmitter.emit({
            model: this.posterList[i],
            index: i,
            selectedPosters,
        });
    }

    deletePoster(i) {
        this.deletedPosterEmitter.emit({
            model: this.posterList[i],
            index: i,
        });
    }

    editPoster(i) {
        this.editingPosterEmitter.emit({
            model: this.posterList[i],
            index: i,
        });
    }
}
