import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { AuthService } from 'app/main/authentication/auth.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    sentFail = false;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _router: Router,
        private _authService: AuthService,
        private _formBuilder: FormBuilder
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    sendForgotPasswordEmail() {
        this.sentFail = false;
        const _email = this.forgotPasswordForm.get('email').value;
        this._authService.forgotPassword(_email).subscribe(
            (response) => {
                console.log('sent email to', _email);
                this._authService.forgotEmail =
                    this.forgotPasswordForm.get('email').value;
                this._router.navigate(['/auth/reset-password']);
            },
            (error) => {
                console.log('error while sending email', error);
                this.sentFail = true;
            }
        );
    }
}
