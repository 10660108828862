import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ACCOUNT_ROLE } from '../../account/account.model';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}
    canActivate() {
        if (this.authService && this.authService.isAuthenticated()) {
            const currentUser = this.authService.getCurrentUser();
            if (
                currentUser.role === ACCOUNT_ROLE.ADMIN ||
                currentUser.role === ACCOUNT_ROLE.HR ||
                currentUser.role === ACCOUNT_ROLE.STAFF
            ) {
                this.router.navigate(['/campaigns']).then(() => {});
                return false;
            }
        }

        this.authService.logout();
        return true;
    }
}
