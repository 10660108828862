<!-- <div
    class="navbar-top"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/fuse.svg" />
        <span class="logo-text secondary-text">5 Worker</span>
    </div>

    <div class="buttons">
        <button
            mat-icon-button
            class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()"
            fxHide.lt-lg
        >
            <mat-icon class="secondary-text">swap_horiz</mat-icon>
        </button>

        <button
            mat-icon-button
            class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()"
            fxHide.gt-md
        >
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>
    </div>
</div> -->

<div
    class="navbar-scroll-container"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
    <div
        class="company"
        fxLayout="column"
        [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
    >
        <div class="h3 company-name">{{ company.fullName }}</div>

        <div
            class="company-container"
            [ngClass]="fuseConfig.layout.navbar.logoBorder"
        >
            <mat-spinner
                color="accent"
                *ngIf="isLoadingLogo"
                style="margin: auto;"
            ></mat-spinner>
            <img
                *ngIf="!isLoadingLogo"
                class="company-logo"
                src="{{ company.logo }}"
            />
            <button class="update-logo-btn" (click)="fileChangeEvent()">
                <div class="icon-holder"><mat-icon>local_see</mat-icon></div>
                Cập nhật Logo
            </button>
        </div>
    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>
</div>
