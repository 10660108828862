import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PosterWelfareComponent } from './poster-welfare/poster-welfare.component';
import { CompanyService } from './company/company.service';

const routes: Routes = [
    {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
    },
    {
        path: 'account',
        loadChildren: './member/member.module#MemberModule',
    },
    {
        path: 'welfare',
        component: PosterWelfareComponent
    },
    {
        path: 'member',
        loadChildren: './member/member.module#MemberModule',
    },
    {
        path: 'company',
        loadChildren: './company/company.module#CompanyModule',
    },
    {
        path: 'activation',
        loadChildren:
            './activation-key/activation-key.module#ActivationKeyModule',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountRoutingModule {}
