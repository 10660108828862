import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
} from 'ag-grid-community';
import CampaignUtils from '../campaign/shared/campaign-utils';
import { DatePipe } from '@angular/common';
import { CompanyManageService } from './company-manage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get, isEmpty } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseConfirmDialogComponent } from '../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
const datePipe = new DatePipe('en-US');
@Component({
    selector: 'app-company-manage',
    templateUrl: './company-manage.component.html',
    styleUrls: ['./company-manage.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CompanyManageComponent implements OnInit, OnDestroy {
    columnDefs: ColDef[] = [
        {
            field: 'id',
            headerName: 'Mã Cty',
            width: 100,
            sort: 'desc',
        },
        {
            field: 'fullName',
            headerName: 'Tên Cty',
            width: 150,
        },
        {
            field: 'taxCode',
            headerName: 'MST',
            width: 150,
        },
        {
            field: 'taxCodeIssueDate',
            headerName: 'Ngày cấp MST',
            width: 150,
            valueGetter: (item) =>
                datePipe.transform(item.data.taxCodeIssueDate, 'dd.MM.yyyy'),
        },
        {
            field: 'taxCodeIssuer',
            headerName: 'Nơi cấp MST',
            width: 150,
        },
        {
            field: 'companyRegistrationImages',
            headerName: 'Giấy ĐKKD',
            width: 150,
            cellRenderer: CompanyManageCellRenderer,
        },
        {
            field: 'adminName',
            headerName: 'Tên Admin',
            width: 150,
            valueGetter: (item) => {
                const admin = get(item, 'data.admin[0]') || {};
                if (!isEmpty(admin)) {
                    return `${admin.firstName} ${admin.lastName}`;
                }
            },
        },
        {
            field: 'adminEmail',
            headerName: 'Email Admin',
            width: 150,
            valueGetter: (item) => {
                const admin = get(item, 'data.admin[0]') || {};
                if (!isEmpty(admin)) {
                    return admin.email;
                }
            },
        },
        {
            field: 'adminPhone',
            headerName: 'Số dt Admin',
            width: 150,
            valueGetter: (item) => {
                const admin = get(item, 'data.admin[0]') || {};
                if (!isEmpty(admin)) {
                    return admin.phone;
                }
            },
        },
        {
            field: 'action',
            headerName: '',
            width: 150,
            cellRenderer: CompanyManageCellRenderer,
            cellRendererParams: {
                activeCompany: (id) => {
                    const confirmDialog = this.matDialog.open(
                        FuseConfirmDialogComponent,
                        {
                            disableClose: false,
                        }
                    );

                    confirmDialog.componentInstance.confirmMessage = `Xác nhận duyệt?`;
                    confirmDialog.componentInstance.noLabel = 'Xem lại';

                    confirmDialog.afterClosed().subscribe((result) => {
                        if (!result) {
                            return;
                        }
                        this.handleApprove(id);
                    });
                },
            },
        },
    ];
    gridApi!: GridApi;
    defaultColDef = CampaignUtils.defaultColDef;
    rowData = [];
    private unsubscribeAll: Subject<any>;

    constructor(
        private companyManageService: CompanyManageService,
        private matDialog: MatDialog,
        private matSnackBar: MatSnackBar
    ) {
        this.unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.companyManageService.getCompanies().then(() => {});
        this.companyManageService.onCompaniesChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((companies) => {
                this.rowData = companies;
            });
    }

    ngOnDestroy() {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onGridReady(event: GridReadyEvent) {
        this.gridApi = event.api;
    }

    handleApprove(id) {
        this.companyManageService
            .approveCompany(id)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(
                () => {
                    this.companyManageService.getCompanies().then(() => {});
                    this.matSnackBar.open('Duyệt thành công!', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                },
                (error) => {
                    this.matSnackBar.open('Xảy ra lỗi!', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                    console.log('Error ', error);
                }
            );
    }
}

@Component({
    templateUrl: 'company-manage-cell-renderer.html',
    styleUrls: ['./company-manage.component.scss'],
})
export class CompanyManageCellRenderer implements ICellRendererAngularComp {
    field = '';
    itemId = 0;
    documents = [];
    approved = false;
    activeCompany!: () => void;

    constructor() {}

    agInit(params: any): void {
        this.itemId = params.data.id;
        this.approved = params.data.approved;
        this.field = params.colDef.field;
        this.documents = params.data.companyRegistrationImages;
        console.log(this.documents);
        this.activeCompany = () => params.activeCompany(this.itemId);
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    active() {
        if (this.approved) {
            return;
        }
        this.activeCompany();
    }
}
