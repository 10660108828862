import {
    JobCategory,
    Company,
    CompanyPoster,
    CompanyWelfare,
} from './company.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { isEmpty } from 'lodash';
import * as moment from 'moment';

@Injectable()
export class CompanyService implements Resolve<any> {
    routeParams: any;
    company: Company;
    companyLogo: any;
    jobCategories: JobCategory[];
    availableWelfares: any[];
    onCompanyChanged: BehaviorSubject<Company>;
    onJobCategoriesChanged: BehaviorSubject<JobCategory[]>;
    onCompanyPostersChanged: BehaviorSubject<CompanyPoster[]>;
    onCompanyWelfaresChanged: BehaviorSubject<CompanyWelfare[]>;
    onProcessingChanged: BehaviorSubject<boolean>;

    constructor(private _httpClient: HttpClient) {
        // Set the defaults
        this.company = new Company();
        this.onCompanyChanged = new BehaviorSubject(new Company());
        this.onJobCategoriesChanged = new BehaviorSubject([] as JobCategory[]);
        this.onCompanyPostersChanged = new BehaviorSubject(
            [] as CompanyPoster[]
        );
        this.onCompanyWelfaresChanged = new BehaviorSubject(
            [] as CompanyWelfare[]
        );
        this.availableWelfares = this._getAvailableWelfare();
        this.onProcessingChanged = new BehaviorSubject(false);
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getJobCategories(), this.getCompany()]).then(
                ([jobCategories, company]: [JobCategory[], Company]) => {
                    resolve(true);
                },
                reject
            );
        });
    }

    getCompany(): Promise<Company> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiEndpoint + 'v1/companies/details')
                .subscribe((response: Company) => {
                    this.company = response;
                    this.onCompanyChanged.next(this.company);
                    resolve(this.company);
                }, reject);
        });
    }

    getCompanyWelfare() {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(
                    environment.apiEndpoint + 'v1/companies/simple-welfare-list'
                )
                .subscribe((response: Company) => {
                    this.company = {
                        ...this.company,
                        ...response,
                    };
                    this.onCompanyChanged.next(this.company);
                    resolve(this.company);
                }, reject);
        });
    }

    getCompanyPosters() {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiEndpoint + 'v1/companies/poster-list')
                .subscribe((response: Company) => {
                    this.company = {
                        ...this.company,
                        ...response,
                    };
                    this.onCompanyChanged.next(this.company);
                    resolve(this.company);
                }, reject);
        });
    }

    getWelfareById(id: number) {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.apiEndpoint}companies/welfare/${id}`)
                .subscribe((response: CompanyWelfare) => {
                    this.company.welfare.forEach((w) => {
                        if (w.id === id) {
                            w.posters = response.posters;
                        }
                    });
                    this.onCompanyChanged.next(this.company);
                    resolve(this.company);
                }, reject);
        });
    }

    getCompanyLogo(): Observable<any> {
        return this._httpClient.get(environment.apiEndpoint + 'companies/logo');
    }

    saveCompany(company): Promise<any> {
        this.onProcessingChanged.next(true);
        const companyData = this._filterCompanyData(company);
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(environment.apiEndpoint + 'companies/', companyData)
                .subscribe(
                    () => {
                        this.getCompany().then(() => {
                            resolve(true);
                            this.onProcessingChanged.next(false);
                        });
                    },
                    (error) => {
                        this.onProcessingChanged.next(false);
                        reject(error);
                    }
                );
        });
    }

    editBranch(id, data): Promise<Company> {
        this.onProcessingChanged.next(true);
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(environment.apiEndpoint + 'companies/branches/' + id, data)
                .subscribe((response: Company) => {
                    this.onProcessingChanged.next(false);
                    resolve(response);
                }, reject);
        });
    }

    deleteBranch(id): Promise<Company> {
        this.onProcessingChanged.next(true);
        return new Promise((resolve, reject) => {
            this._httpClient
                .delete(environment.apiEndpoint + 'companies/branches/' + id)
                .subscribe((response: Company) => {
                    this.onProcessingChanged.next(false);
                    resolve(response);
                }, reject);
        });
    }

    addBranch(data): Promise<Company> {
        this.onProcessingChanged.next(true);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiEndpoint + 'companies/branches', data)
                .subscribe((response: Company) => {
                    this.onProcessingChanged.next(false);
                    resolve(response);
                }, reject);
        });
    }

    saveCompanyLogo(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiEndpoint + 'companies/logo', {
                    content: data,
                })
                .subscribe((response) => {
                    console.log('logo:', response);
                    resolve(response);
                }, reject);
        });
    }

    getJobCategories(): Promise<JobCategory[]> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get<JobCategory[]>(
                    environment.apiEndpoint + 'companies/jobcategory'
                )
                .subscribe((response: JobCategory[]) => {
                    this.jobCategories = response;
                    this.onJobCategoriesChanged.next(this.jobCategories);
                    resolve(this.jobCategories);
                }, reject);
        });
    }

    saveCompanyRelatedJob(id): Promise<any> {
        this.onProcessingChanged.next(true);
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiEndpoint + 'companies/relatedjob', id)
                .subscribe(
                    () => {
                        this.onProcessingChanged.next(false);
                        resolve(true);
                    },
                    () => {
                        this.onProcessingChanged.next(false);
                    }
                );
        });
    }

    deleteRelatedJob(id): Promise<any> {
        this.onProcessingChanged.next(true);
        return new Promise((resolve, reject) => {
            this._httpClient
                .delete(environment.apiEndpoint + 'companies/relatedjob/' + id)
                .subscribe(
                    () => {
                        this.onProcessingChanged.next(false);
                        resolve(true);
                    },
                    () => {
                        this.onProcessingChanged.next(false);
                    }
                );
        });
    }

    saveCompanyPoster(poster): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiEndpoint + 'v1/companies/posters/', {
                    ...poster,
                })
                .subscribe((response) => {
                    this.getCompanyPosters().then(() => {
                        resolve(response);
                    });
                });
        });
    }

    editCompanyPoster(poster): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(
                    environment.apiEndpoint +
                        'v1/companies/posters/' +
                        poster.id,
                    { ...poster }
                )
                .subscribe((response) => {
                    this.getCompanyPosters().then(() => {
                        resolve(response);
                    });
                });
        });
    }

    deleteCompanyPoster(poster, withRefresh = true): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .delete(
                    environment.apiEndpoint +
                        'v1/companies/posters/' +
                        poster.id
                )
                .subscribe((response) => {
                    if (withRefresh) {
                        this.getCompanyPosters().then(() => {
                            resolve(response);
                        });
                    }
                });
        });
    }

    saveCompanyWelfare(welfare): Promise<CompanyWelfare[]> {
        delete welfare.sortOrder;
        welfare.note = '';
        welfare.status = 'YES';
        return new Promise((resolve, reject) => {
            this._httpClient
                .put<CompanyWelfare[]>(
                    environment.apiEndpoint + 'companies/welfare/' + welfare.id,
                    welfare
                )
                .subscribe(
                    (response) => {
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    uploadRegistrationDocument(formData: any) {
        return this._httpClient.post(
            `${environment.apiEndpoint}/companies/tax/images`,
            formData
        );
    }

    removeRegistrationDocument(id: string) {
        return this._httpClient.delete(
            `${environment.apiEndpoint}/companies/tax/images/${id}`
        );
    }

    _filterCompanyData(company) {
        return {
            branch: company.branch || '',
            companyCode: company.companyCode || '',
            description: company.description || '',
            fullName: company.fullName || '',
            industry: company.industry || '',
            phone: company.phone || '',
            prefix: company.prefix || '',
            shortName: company.shortName || '',
            size: company.size || '',
            taxCode: company.taxCode || '',
            taxCodeIssueDate: moment(company.taxCodeIssueDate),
            taxCodeIssuer: company.taxCodeIssuer,
        };
    }

    _getAvailableWelfare() {
        // Note: this is new available welfares that Mr.Toan has changed
        const welfares = [
            {
                id: 0,
                category: 'WORKING_ENVIRONMENT',
                valueType: 'BOOL',
            },
            {
                id: 1,
                category: 'OVER_TIME',
                valueType: 'BOOL',
            },
            {
                id: 2,
                category: 'INSURANCE',
                valueType: 'BOOL',
            },
            {
                id: 3,
                category: 'THIRTEENTH_MONTH_BONUS',
                valueType: 'BOOL',
            },
            {
                id: 4,
                category: 'PRODUCTIVITY_BONUS',
                valueType: 'BOOL',
            },
            {
                id: 5,
                category: 'ALLOWANCE',
                valueType: 'BOOL',
            },
            {
                id: 6,
                category: 'FOOD',
                valueType: 'BOOL',
            },
            {
                id: 7,
                category: 'CHECKUP',
                valueType: 'BOOL',
            },
            {
                id: 8,
                category: 'ACTIVITIES',
                valueType: 'BOOL',
            },
            {
                id: 9,
                category: 'PAID_LEAVES',
                valueType: 'BOOL',
            },
            {
                id: 10,
                category: 'TRAINING',
                valueType: 'BOOL',
            },
            {
                id: 11,
                category: 'ANNUAL_LEAVE',
                valueType: 'BOOL',
            },
            {
                id: 12,
                category: 'OTHER_BENEFIT',
                valueType: 'BOOL',
            },
        ];

        return welfares;
    }
}
