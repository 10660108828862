<div id="reset-password" fxLayout="column">
    <div
        id="reset-password-form-wrapper"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div
            *ngIf="sentStatus != 'SUCCESS'"
            class="reset-password-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
        >
            <div class="title">Thiết lập mật khẩu</div>
            <h3>
                Vui lòng sử dụng mã đã được gửi trong email để cài đặt lại mật
                khẩu.
            </h3>

            <form
                name="resetPasswordForm"
                [formGroup]="resetPasswordForm"
                novalidate
            >
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input
                        matInput
                        formControlName="email"
                        autocomplete="new-phone"
                    />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error
                        *ngIf="
                            resetPasswordForm.get('email').hasError('required')
                        "
                    >
                        Vui lòng nhập email
                    </mat-error>
                    <mat-error
                        *ngIf="resetPasswordForm.get('email').hasError('email')"
                    >
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Mã cài đặt lại</mat-label>
                    <input matInput type="text" formControlName="token" />
                    <mat-error> Vui lòng nhập mã cài đặt lại </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Nhập mật khẩu mới</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="password"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error> Vui lòng nhập mật khẩu mới </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Xác nhận lại mật khẩu mới</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="passwordConfirm"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error
                        *ngIf="
                            resetPasswordForm
                                .get('passwordConfirm')
                                .hasError('required')
                        "
                    >
                        Vui lòng xác nhận lại mật khẩu mới
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !resetPasswordForm
                                .get('passwordConfirm')
                                .hasError('required') &&
                            resetPasswordForm
                                .get('passwordConfirm')
                                .hasError('passwordsNotMatching')
                        "
                    >
                        Mật khẩu không trùng khớp
                    </mat-error>
                </mat-form-field>

                <button
                    mat-raised-button
                    class="submit-button"
                    color="accent"
                    (click)="sendResetPassword()"
                    aria-label="Thiết lập"
                    [disabled]="resetPasswordForm.invalid"
                >
                    Thiết lập
                </button>
            </form>

            <h3 *ngIf="sentStatus == 'FAIL'">
                Gửi thiết lập thất bại, vui lòng thử lại sau.
            </h3>
        </div>

        <div class="reset-password-form" *ngIf="sentStatus == 'SUCCESS'">
            <h3>
                Gửi thiết lập thành công, bạn có thể đăng nhập bằng mật khẩu
                mới.
            </h3>

            <button
                mat-raised-button
                class="submit-button"
                color="accent"
                [routerLink]="'/auth/login'"
                aria-label="Đăng nhập"
            >
                Đăng nhập
            </button>
        </div>
    </div>
</div>
