import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
    aspectRatio: number;
    resizeToWidth: number;
    callback: any;
}

@Component({
    selector: "image-cropper-dialog",
    styleUrls: ["./image-cropper.component.scss"],
    templateUrl: "image-cropper-dialog.component.html"
})
export class ImageCropperDialog {
    imageChangedEvent: any = "";
    croppedImage: any = "";
    aspectRatio: any;
    resizeToWidth: any;
    callback: any;
    showCropper = false;
    showSpinner = false;
    showError = false;
    errorMessage = 'Dung lượng file quá lớn (>1MB)';

    constructor(
        public dialogRef: MatDialogRef<ImageCropperDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.aspectRatio = data.aspectRatio;
        this.resizeToWidth = data.resizeToWidth;
        this.callback = data.callback;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        if (event.target && this.checkOverFileSize(event.target.files)) {
            this.showError = true;
        } else {
            this.showError = false;
            this.showSpinner = true;
        }
    }
    imageCropped(event: any) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        // show cropper
        this.showCropper = true;
        this.showSpinner = false;
    }
    loadImageFailed() {
        // show message
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    private checkOverFileSize(files: File[]) {
        const maxSize = 1 * 1024 * 1024;
        if (files.length) {
            return files[0].size > maxSize;
        }
        return false;
    }

    save() {
        // this.callback();
    }
}
