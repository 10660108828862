import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'app/core/services/base.service';
import { retry, catchError } from 'rxjs/operators';
import { Campaign, Feedback } from 'app/shared/models/campaign.model';
import { Member } from 'app/shared/models/company.model';
import { Question, Quiz } from 'app/main/database/database.model';

@Injectable({
    providedIn: 'root',
})
export class DatabaseService extends BaseService {
    quiz: any;
    questions: Question[];
    tests: any[];
    onQuizChanged: BehaviorSubject<any>;
    onQuestionsChanged: BehaviorSubject<Question[]>;
    onTestsChange = new BehaviorSubject([]);

    constructor(private _httpClient: HttpClient) {
        super();
        this.onQuestionsChanged = new BehaviorSubject([]);
        this.onQuizChanged = new BehaviorSubject({});
        this.tests = [];
    }

    resolve(): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([this.getQuestions()]).then(() => {
                resolve(true);
            }, reject);
        });
    }

    getQuestions(): Promise<Question[]> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this.getFullUrl('companies/questions'))
                .subscribe((response: Question[]) => {
                    this.questions = response;
                    this.onQuestionsChanged.next(this.questions);
                    resolve(this.questions);
                }, reject);
        });
    }

    getTests(): Observable<any> {
        return new Observable((subscriber) => {
            this._httpClient
                .get(this.getFullUrl('companies/tests'))
                .subscribe((response: Quiz[]) => {
                    this.tests = response;
                    this.onTestsChange.next(response);
                    subscriber.next(response);
                }, error => subscriber.error(error));
        });
    }

    getQuizById(id: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (id === 'new-test' || id === 'new-interview') {
                this.onQuizChanged.next({});
                resolve({});
            } else {
                this._httpClient
                    .get(this.getFullUrl(`companies/tests/${id}`))
                    .subscribe((response: any) => {
                        this.quiz = response;
                        this.onQuizChanged.next(this.quiz);
                        resolve(this.quiz);
                    }, reject);
            }
        });
    }

    getFullQuestions(questions) {
        const fullQuestions = [];
        if (!this.questions || !this.questions.length) {
            return fullQuestions;
        }
        questions.forEach((id) => {
            fullQuestions.push(this._findFullQuestion(id));
        });
        return fullQuestions;
    }

    _findFullQuestion(id) {
        for (let i = 0; i <= this.questions.length; i++) {
            if (this.questions[i].id === id) {
                return this.questions[i];
            }
        }
    }
}
