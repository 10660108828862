<div id="register" fxLayout="column">
    <div
        id="register-form-wrapper"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div class="horizontal-stepper-wrapper">
            <mat-horizontal-stepper class="mat-elevation-z4" [linear]="true">
                <mat-step [stepControl]="horizontalStepperStep1">
                    <form
                        fxLayout="column"
                        [formGroup]="horizontalStepperStep1"
                        class="pt-8"
                    >
                        <ng-template matStepLabel
                            >Thông tin Nhà tuyển dụng</ng-template
                        >

                        <div fxFlex="1 0 auto" fxLayout="column">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Tên công ty</mat-label>
                                <input
                                    matInput
                                    formControlName="companyName"
                                    required
                                />
                                <mat-error>Vui lòng nhập tên công ty</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Địa chỉ</mat-label>
                                <input
                                    matInput
                                    formControlName="companyAddress"
                                    required
                                />
                                <mat-error
                                    >Vui lòng nhập địa chỉ công ty</mat-error
                                >
                            </mat-form-field>

                            <fw-geography
                                (geographyEmitter)="completeLocation($event)"
                            ></fw-geography>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Mã số thuế</mat-label>
                                <input
                                    matInput
                                    formControlName="taxCode"
                                    required
                                />
                                <mat-error>Vui lòng nhập Mã số thuế</mat-error>
                            </mat-form-field>

                            <mat-form-field (click)='picker.open()' appearance="outline" fxFlex="100">
                                <mat-label>Ngày cấp Mã số thuế</mat-label>
                                <input
                                    matInput
                                    (click)="picker.open()"
                                    [matDatepicker]="picker"
                                    formControlName="taxCodeIssueDate"
                                    placeholder="DD-MM-YYYY"
                                    required
                                    readonly
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error
                                    >Vui lòng nhập Ngày cấp Mã số
                                    thuế</mat-error
                                >
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Nơi cấp mã số thuế</mat-label>
                                <input
                                    matInput
                                    formControlName="taxCodeIssuer"
                                    required
                                />
                                <mat-error
                                    >Vui lòng nhập Nơi cấp mã số thuế</mat-error
                                >
                            </mat-form-field>

                            <div class="agree-terms-container">
                                <mat-checkbox
                                    [checked]="agreeTerms"
                                    (change)="agreeTerms = $event.checked"
                                >
                                    Đồng ý với
                                    <a
                                        target="_blank"
                                        href="https://home.viecnhamay.vn/quy-dinh-bao-mat/"
                                        >Quy định bảo mật</a
                                    >
                                    và
                                    <a
                                        target="_blank"
                                        href="https://home.viecnhamay.vn/thoa-thuan-su-dung/"
                                        >Thoả thuận sử dụng</a
                                    >
                                </mat-checkbox>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button
                                mat-raised-button
                                matStepperNext
                                type="button"
                                color="accent"
                                [disabled]="
                                    !agreeTerms ||
                                    horizontalStepperStep1.invalid
                                "
                            >
                                Tiếp tục
                            </button>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="horizontalStepperStep2">
                    <form
                        fxLayout="column"
                        [formGroup]="horizontalStepperStep2"
                        class="pt-8"
                    >
                        <ng-template matStepLabel>Tài khoản Admin</ng-template>

                        <div fxFlex="1 0 auto" fxLayout="column">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Email</mat-label>
                                <input
                                    matInput
                                    formControlName="email"
                                    [email]="true"
                                />
                                <mat-error
                                    *ngIf="
                                        horizontalStepperStep2
                                            .get('email')
                                            .hasError('required')
                                    "
                                >
                                    Vui lòng nhập email
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        horizontalStepperStep2
                                            .get('email')
                                            .hasError('email')
                                    "
                                >
                                    Địa chỉ email không hợp lệ
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Mật khẩu</mat-label>
                                <input
                                    matInput
                                    placeholder="Password"
                                    [type]="hide ? 'password' : 'text'"
                                    formControlName="password"
                                    minlength="6"
                                    required
                                />
                                <mat-icon matSuffix (click)="hide = !hide"
                                    >{{
                                        hide ? 'visibility_off' : 'visibility'
                                    }}
                                </mat-icon>
                                <mat-error>
                                    Vui lòng nhập mật khẩu có ít nhất 6 ký tự
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Xác nhận mật khẩu</mat-label>
                                <input
                                    matInput
                                    placeholder="Password"
                                    [type]="hideC ? 'password' : 'text'"
                                    formControlName="passwordConfirm"
                                    minlength="6"
                                    required
                                />
                                <mat-icon matSuffix (click)="hideC = !hideC"
                                    >{{
                                        hideC ? 'visibility_off' : 'visibility'
                                    }}
                                </mat-icon>

                                <mat-error
                                    *ngIf="
                                        horizontalStepperStep2
                                            .get('passwordConfirm')
                                            .hasError('required')
                                    "
                                >
                                    Vui lòng xác nhận mật khẩu
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        !horizontalStepperStep2
                                            .get('passwordConfirm')
                                            .hasError('required') &&
                                        horizontalStepperStep2
                                            .get('passwordConfirm')
                                            .hasError('passwordsNotMatching')
                                    "
                                >
                                    Mật khẩu không khớp
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Họ và tên Quản trị viên</mat-label>
                                <input matInput formControlName="name" />
                                <mat-error
                                    *ngIf="
                                        horizontalStepperStep2
                                            .get('name')
                                            .hasError('required')
                                    "
                                >
                                    Vui lòng nhập Họ và tên Quản trị viên
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Danh xưng</mat-label>
                                <mat-select formControlName="gender">
                                    <mat-option class="option" value="MALE"
                                    >Anh</mat-option
                                    >
                                    <mat-option class="option" value="FEMALE"
                                    >Chị</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Điện thoại liên lạc</mat-label>
                                <input matInput formControlName="phone" />
                                <mat-error
                                    *ngIf="
                                        horizontalStepperStep2
                                            .get('phone')
                                            .hasError('required')
                                    "
                                >
                                    Vui lòng nhập điện thoại liên lạc
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button
                                class="mr-8"
                                mat-raised-button
                                matStepperPrevious
                                type="button"
                                color="accent"
                            >
                                Quay lại
                            </button>
                            <button
                                mat-raised-button
                                matStepperNext
                                type="button"
                                color="accent"
                                [disabled]="horizontalStepperStep2.invalid"
                            >
                                Tiếp tục
                            </button>
                        </div>
                    </form>
                </mat-step>

                <mat-step>
                    <ng-template matStepLabel>Xác nhận đăng ký</ng-template>

                    <div
                        class="h2 m-16"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                    >
                        Email kích hoạt sẽ được gửi đến: {{ getEmail() }}
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button
                            mat-raised-button
                            type="button"
                            color="accent"
                            (click)="finishHorizontalStepper()"
                        >
                            Xác nhận đăng ký
                        </button>
                    </div>

                    <div
                        *ngIf="isError"
                        class="h2 m-16"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                    >
                        <p class="error">
                            Xảy ra lỗi trong quá trình tạo tài khoản.
                        </p>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>
