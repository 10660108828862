<div class="bell-text-editor">
    <div fxFlex="100%" fxLayout="row" fxLayoutGap="4px">
        <div fxFlex="90%">
            <mat-form-field class="m-0" style="width: 100%">
                <input
                    placeholder="{{
                        'POSTER_COMPONENT.POSTER_CONTENT' | translate
                    }}"
                    matInput
                    [(ngModel)]="textModel.value"
                />
            </mat-form-field>
        </div>

        <div fxFlex>
            <span
                #ignoredSpan
                (colorPickerChange)="
                    textColor = $event; setTextColor(textColor)
                "
                [(colorPicker)]="textColor"
                [(cpToggle)]="colorPickerToggle"
                [cpPosition]="'left'"
                [cpPositionRelativeToArrow]="true"
                [cpIgnoredElements]="[ignoredButton, ignoredSpan]"
                [cpPresetColors]="presetColors"
            ></span>

            <button
                class="color-picker-button"
                [style.color]="getColorPickerTextColor(textColor)"
                [style.background]="textColor"
                mat-mini-fab
                (click)="
                    colorPickerToggle = !colorPickerToggle;
                    lastColor = textColor
                "
                #ignoredButton
            >
                <mat-icon>color_lens</mat-icon>
            </button>

            <div class="clearfix"></div>
        </div>

        <!--    <div fxFlex>-->
        <!--      <button class="text-size-button" mat-mini-fab (click)="increaseSize()">-->
        <!--        <mat-icon>add_circle_outline</mat-icon>-->
        <!--      </button>-->
        <!--    </div>-->

        <!--    <div fxFlex>-->
        <!--      <button class="text-size-button" mat-mini-fab (click)="decreaseSize()">-->
        <!--        <mat-icon>remove_circle_outline</mat-icon>-->
        <!--      </button>-->
        <!--    </div>-->
    </div>
</div>
