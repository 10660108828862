import { AddBranchFormDialogComponent } from './company/basic-information/add-branch-form/add-branch-form.component';
import { SetPasswordFormDialogComponent } from './shared/set-password-form/set-password-form.component';
import { AccountFormDialogComponent } from 'app/main/account/shared/account-form/account-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';

import { FuseSharedModule } from '@fuse/shared.module';
import { GeographyModule } from '../geography/geography.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { PosterWelfareComponent } from './poster-welfare/poster-welfare.component';
import { WelfareComponent } from './welfare/welfare.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PosterComponent } from './poster/poster.component';
import { PosterCreatorComponent } from './poster/poster-creator/poster-creator.component';
import { PosterViewComponent } from './poster/poster-view/poster-view.component';
import { PosterListComponent } from './poster/poster-list/poster-list.component';
import { PosterTextViewComponent } from './poster/poster-text-view/poster-text-view.component';
import { PosterTextEditorComponent } from './poster/poster-text-editor/poster-text-editor.component';
import { PosterAddingFormComponent } from './shared/poster-adding-form/poster-adding-form.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-img-cropper';

@NgModule({
    declarations: [
        AccountFormDialogComponent,
        SetPasswordFormDialogComponent,
        AddBranchFormDialogComponent,
        PosterWelfareComponent,
        WelfareComponent,
        PosterComponent,
        PosterCreatorComponent,
        PosterViewComponent,
        PosterListComponent,
        PosterTextViewComponent,
        PosterTextEditorComponent,
        PosterAddingFormComponent,
    ],
    imports: [
        CommonModule,
        AccountRoutingModule,

        MatToolbarModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatDialogModule,
        MatSelectModule,
        MatChipsModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatSortModule,
        MatSnackBarModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatCardModule,
        MatRadioModule,
        MatGridListModule,
        MatDividerModule,
        MatProgressBarModule,

        ColorPickerModule,
        ImageCropperModule,

        FuseSharedModule,
        GeographyModule,
    ],
    exports: [
        PosterCreatorComponent,
        PosterViewComponent,
        PosterListComponent,
        PosterTextViewComponent,
        PosterTextEditorComponent,
    ],
    entryComponents: [
        PosterAddingFormComponent,
        AccountFormDialogComponent,
        SetPasswordFormDialogComponent,
        AddBranchFormDialogComponent,
    ],
})
export class AccountModule {}
