import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../account/profile/profile.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { ACCOUNT_ROLE } from '../account/account.model';

@Component({
    selector: 'app-dashboards',
    templateUrl: './dashboards.component.html',
    styleUrls: ['./dashboards.component.scss'],
    animations: fuseAnimations,
})
export class DashboardsComponent implements OnInit {
    currentUser = '';

    constructor(
        private router: Router,
        private _profileService: ProfileService
    ) {}

    ngOnInit() {
        this._profileService.getProfile().then((response) => {
            const { firstName = '', lastName = '', role = '' } = response;
            this.currentUser = `${lastName} ${firstName}`.trim();
            if (role !== ACCOUNT_ROLE.SUPER_ADMIN) {
                this.router.navigate(['/campaigns']).then(() => {});
            }
        });
    }
}
