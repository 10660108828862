import { Component, OnInit } from '@angular/core';
import { get } from 'lodash';
import { FuseConfigService } from '../../../@fuse/services/config.service';

@Component({
    selector: 'fw-candidate-download',
    templateUrl: './candidate-download.component.html',
    styleUrls: ['./candidate-download.component.scss'],
})
export class CandidateDownloadComponent implements OnInit {
    constructor(
        private _fuseConfigService: FuseConfigService,
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        const redirectToAppStore = () => {
            const userAgent =
                navigator.userAgent || navigator.vendor || get(window, 'opera');

            console.log({ userAgent });

            // iOS detection
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                window.location.href =
                    'https://apps.apple.com/app/id1559971409';
            }
            // Android detection
            else if (/android/i.test(userAgent)) {
                window.location.href =
                    'https://play.google.com/store/apps/details?id=com.viecnhamay.mobile.app';
            }
            // Fallback (e.g., desktop)
            else {
                window.location.href =
                    'https://apps.apple.com/app/id1559971409';
            }
        };
        redirectToAppStore();
    }

    ngOnInit(): void {}
}
