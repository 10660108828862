<div class="content p-16">
    <mat-tab-group>
        <mat-tab label="{{ 'COMPANY.BASIC_INFO' | translate }}">
            <div class="tab-content p-24" fusePerfectScrollbar>
                <form
                    name="companyForm"
                    [formGroup]="companyForm"
                    class="product w-100-p"
                    fxLayout="column"
                    fxFlex
                >
                    <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="w-100-p"
                    >
                        <mat-label>{{ 'COMMON.NAME' | translate }}</mat-label>
                        <input
                            matInput
                            placeholder="Name"
                            name="fullName"
                            formControlName="fullName"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="w-100-p"
                    >
                        <mat-label>{{
                            'COMPANY.TAX_CODE' | translate
                        }}</mat-label>
                        <input
                            matInput
                            placeholder="taxCode"
                            name="taxCode"
                            formControlName="taxCode"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        (click)="picker.open()"
                        appearance="outline"
                        fxFlex="100"
                    >
                        <mat-label>{{
                            'COMPANY.TAX_CODE_DATE' | translate
                        }}</mat-label>
                        <input
                            matInput
                            (click)="picker.open()"
                            [matDatepicker]="picker"
                            formControlName="taxCodeIssueDate"
                            placeholder="DD-MM-YYYY"
                            readonly
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error>{{
                            'COMPANY.TAX_CODE_DATE_ERROR' | translate
                        }}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{
                            'COMPANY.TAX_CODE_PLACE' | translate
                        }}</mat-label>
                        <input matInput formControlName="taxCodeIssuer" />
                    </mat-form-field>

                    <mat-form-field
                        class="w-100-p"
                        appearance="outline"
                        floatLabel="always"
                    >
                        <mat-label>{{
                            'COMPANY.PAPER_SCAN' | translate
                        }}</mat-label>
                        <mat-chip-list>
                            <mat-chip
                                style="cursor: pointer"
                                color="accent"
                                (click)="fileUpload.click()"
                                >+
                            </mat-chip>
                            <mat-chip
                                style="cursor: pointer"
                                (removed)="onRemoveFile(file.id)"
                                *ngFor="
                                    let file of company.companyRegistrationImages
                                "
                                (click)="window.open(file.url, '_blank')"
                            >
                                {{ file.fileName }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                        <input
                            style="display: none"
                            type="file"
                            (change)="onFileSelected($event)"
                            #fileUpload
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="w-100-p"
                    >
                        <mat-label>{{
                            'COMMON.DESCRIPTION' | translate
                        }}</mat-label>
                        <textarea
                            matInput
                            placeholder="{{ 'COMMON.DESCRIPTION' | translate }}"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="3"
                            cdkAutosizeMaxRows="10"
                            [maxLength]="300"
                            formControlName="description"
                        ></textarea>
                    </mat-form-field>

                    <mat-form-field
                        class="w-100-p"
                        appearance="outline"
                        floatLabel="always"
                    >
                        <mat-label>{{
                            'COMPANY.JOB_CATEGORY' | translate
                        }}</mat-label>
                        <mat-chip-list #chipList>
                            <mat-chip
                                *ngFor="
                                    let jobCategory of selectedJobCategories
                                "
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="remove(jobCategory)"
                            >
                                {{
                                    'JOB_NAME.' + jobCategory.jobKey | translate
                                }}
                                <mat-icon matChipRemove *ngIf="removable"
                                    >cancel</mat-icon
                                >
                            </mat-chip>
                            <input
                                placeholder="{{
                                    'COMPANY.JOB_CATEGORY' | translate
                                }}"
                                #jobCategoryInput
                                formControlName="jobCategories"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="
                                    separatorKeysCodes
                                "
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="add($event)"
                            />
                        </mat-chip-list>
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            (optionSelected)="selected($event)"
                        >
                            <mat-option
                                *ngFor="
                                    let jobCategory of filteredJobCategories
                                        | async
                                "
                                [value]="jobCategory.id"
                            >
                                {{
                                    'JOB_NAME.' + jobCategory.jobKey | translate
                                }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <fw-geography
                        [selectedData]="company.branches[0].address"
                        (geographyEmitter)="showData($event)"
                    ></fw-geography>

                    <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="w-100-p"
                    >
                        <mat-label>{{
                            'COMPANY.ADDRESS' | translate
                        }}</mat-label>
                        <input
                            matInput
                            placeholder="address"
                            name="address"
                            formControlName="address"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="w-100-p pb-0"
                    >
                        <mat-label>{{
                            'GEOGRAPHY.MAP_LINK' | translate
                        }}</mat-label>
                        <input
                            matInput
                            placeholder="{{
                                'COMPANY.G_MAP_PLACEHOLDER' | translate
                            }}"
                            name="mapLink"
                            formControlName="mapLink"
                        />
                    </mat-form-field>
                    <a class="map-guide-link" (click)="showMapGuide()">{{
                        'COMPANY.G_MAP_HOW_TO' | translate
                    }}</a>

                    <div class="save-basic-info-section pb48">
                        <button
                            mat-raised-button
                            color="accent"
                            class="save-basic-info-button"
                            (click)="saveCompany()"
                            [disabled]="
                                companyForm.invalid || companyForm.pristine
                            "
                        >
                            <span>{{ 'COMMON.SAVE' | translate }}</span>
                        </button>
                    </div>
                </form>
            </div>
        </mat-tab>

        <mat-tab label="{{ 'COMPANY.BRANCH' | translate }}">
            <div class="branch-section pb-48">
                <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
                    <div class="h1 secondary-text">
                        {{ 'COMMON.BRANCHES' | translate }}
                    </div>
                    <button
                        class="ml-8"
                        mat-icon-button
                        aria-label="add branches"
                        (click)="onAddBranchBtnClick()"
                    >
                        <mat-icon class="secondary-text add-icon"
                            >add_circle_outline</mat-icon
                        >
                    </button>
                </div>

                <mat-accordion class="addresses">
                    <mat-expansion-panel
                        *ngFor="let branch of company.branches"
                    >
                        <mat-expansion-panel-header *ngIf="!branch.headQuarter">
                            <mat-panel-title>
                                {{ branch.address.name + ' ' }}
                                <mat-icon
                                    class="edit-button"
                                    (click)="onEditBranchBtnClick(branch)"
                                    >edit</mat-icon
                                >
                                <mat-icon
                                    class="delete-button red-fg"
                                    (click)="onDeleteBranchBtnClick(branch)"
                                    >delete
                                </mat-icon>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div fxFlex fxLayout="column">
                            <div>{{ getAddressString(branch.address) }}</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="loading-container" *ngIf="isProcessing">
        <mat-spinner color="accent"></mat-spinner>
    </div>
</div>
