import { NgModule } from "@angular/core";

import { FuseNavigationModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import {NavbarVerticalStyle5workerComponent} from "./style-5worker.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
    declarations: [NavbarVerticalStyle5workerComponent],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,

        FuseSharedModule,
        FuseNavigationModule
    ],
    exports: [NavbarVerticalStyle5workerComponent]
})
export class NavbarVerticalStyle5workerModule {}
