import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class FuseConfirmDialogComponent {
    public confirmMessage: string;
    public paragraph = [];
    public rows = [];
    public title?: string;
    public yesLabel?: string;
    public noLabel?: string;
    public inputLabel = '';
    public noCancel = false;
    input = '';

    constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>) {}

    getReturnValue() {
        return this.inputLabel.length
            ? { result: true, input: this.input }
            : true;
    }
}
