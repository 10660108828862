import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
    CellClickedEvent,
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
} from 'ag-grid-community';
import CampaignUtils from '../campaign/shared/campaign-utils';
import { DatePipe } from '@angular/common';
import { CandidateManageService } from './candidate-manage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserDetailsDialogComponent } from '../../shared/modules/user-details-dialog/user-details-dialog.component';
import { isEmpty } from 'lodash';
import { async } from '@angular/core/testing';
const datePipe = new DatePipe('en-US');
const MAX_TABLE_HEIGHT = 520;
@Component({
    selector: 'app-candidate-manage',
    templateUrl: './candidate-manage.component.html',
    styleUrls: ['./candidate-manage.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CandidateManageComponent implements OnInit, OnDestroy {
    columnDefs: ColDef[] = [
        {
            field: 'staticIndex',
            headerName: 'STT',
            width: 50,
        },
        // {
        //     field: 'lastName',
        //     headerName: 'Họ',
        //     width: 150,
        // },
        // {
        //     field: 'firstName',
        //     headerName: 'Tên',
        //     width: 150,
        // },
        {
            field: 'fullName',
            headerName: 'Tên',
            cellRenderer: CandidateManageCellRenderer,
            width: 150,
        },
        {
            field: 'phone',
            headerName: 'Số ĐT',
            width: 150,
        },
        {
            field: 'email',
            headerName: 'email',
            width: 250,
        },
        {
            field: 'lastUpdated',
            headerName: 'Cập nhật gần nhất',
            width: 150,
            cellRenderer: CandidateManageCellRenderer,
        },
        {
            field: 'status',
            headerName: 'Trạng thái duyệt',
            width: 150,
            cellRenderer: CandidateManageCellRenderer,
            sortable: false,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Duyệt/Bỏ duyệt',
            width: 150,
            cellRenderer: CandidateManageCellRenderer,
            cellRendererParams: {
                approveProfile: (id, isApprove) => {
                    this.handleApprove(id, isApprove);
                },
            },
            sortable: false,
        },
    ];
    gridApi!: GridApi;
    defaultColDef = CampaignUtils.defaultColDef;
    rowData = [];
    selectedRow = null;
    currentFilter = 'NOT_READY';
    currentPage = 0;
    fetchingData = false;
    fetchedAll = false;
    maxScrollTop = -1;
    private unsubscribeAll: Subject<any>;

    constructor(
        private candidateManageService: CandidateManageService,
        private matDialog: MatDialog,
        private matSnackBar: MatSnackBar
    ) {
        this.unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.reloadData();
        this.candidateManageService.onCandidatesChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((candidates) => {
                if (candidates.length > 0) {
                    if (this.gridApi) {
                        this.gridApi.applyTransaction({ add: candidates });
                    }
                } else {
                    this.fetchedAll = this.currentPage > 0;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    handleApprove(id, isApprove) {
        this.candidateManageService
            .approveProfile(id, isApprove)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(
                () => {
                    this.matSnackBar.open('Thành công!', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                    this.gridApi.applyTransaction({
                        remove: [this.selectedRow.data],
                    });
                },
                (error) => {
                    this.matSnackBar.open('Xảy ra lỗi!', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                    console.log('Error ', error);
                }
            );
    }

    showCandidateDetails(event: CellClickedEvent) {
        const candidate = event.data;
        if (event.colDef.field.includes('Name')) {
            const popup = this.matDialog.open(UserDetailsDialogComponent, {
                panelClass: 'candidate-detail-dialog-modal',
            });
            popup.componentInstance.candidate = candidate;
            popup.componentInstance.approveProfile = (isApprove) =>
                this.handleApprove(candidate.id, isApprove);
        }
    }

    triggerFilter() {
        this.reloadData();
    }

    fetchMoreData() {
        if (this.fetchingData || this.fetchedAll) {
            return;
        }

        this.currentPage++;
        this.fetchingData = true;
        this.candidateManageService
            .getCandidates({
                page: this.currentPage,
                status: this.currentFilter,
            })
            .then(() => {
                this.fetchingData = false;
            })
            .catch(() => {
                this.fetchingData = false;
                this.fetchedAll = true;
            });
    }

    reloadData(): void {
        this.currentPage = 0;
        this.maxScrollTop = -1;
        this.fetchedAll = false;
        this.fetchingData = true;
        this.rowData = [];

        if (this.gridApi) {
            this.gridApi.setRowData([]);
            this.gridApi.redrawRows();
        }

        this.candidateManageService
            .getCandidates({
                page: 0,
                status: this.currentFilter,
            })
            .then(() => {
                this.fetchingData = false;
            });
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        if (this.gridApi) {
            const verticalPixelRange = this.gridApi.getVerticalPixelRange();
            const { bottom = 0 } = verticalPixelRange;
            if (bottom > this.maxScrollTop + MAX_TABLE_HEIGHT) {
                this.maxScrollTop = bottom;
                this.fetchMoreData();
            }
        }
    }

    onScrollEnd(event: any) {
        const { top = -1, direction = '' } = event;

        if (direction !== 'vertical') {
            return;
        }

        if (
            top <= MAX_TABLE_HEIGHT ||
            top > this.maxScrollTop + MAX_TABLE_HEIGHT
        ) {
            this.maxScrollTop = top;
            this.fetchMoreData();
        }
    }

    rowClicked(e) {
        console.log({ e });
        this.selectedRow = e.node;
    }

    onLoaded() {
        if (!isEmpty(this.gridApi)) {
            const verticalPixelRange = this.gridApi.getVerticalPixelRange();
            const { bottom = 0 } = verticalPixelRange;
            if (bottom > this.maxScrollTop + MAX_TABLE_HEIGHT) {
                this.maxScrollTop = bottom;
                setTimeout(() => {
                    this.fetchMoreData();
                }, 1000);
            }
        }
    }

    protected readonly async = async;
}

@Component({
    templateUrl: 'candidate-manage-cell-renderer.html',
    styleUrls: ['./candidate-manage.component.scss'],
})
export class CandidateManageCellRenderer implements ICellRendererAngularComp {
    field = '';
    itemId = 0;
    approved = false;
    status = '';
    fullName = '';
    lastUpdated = null;
    approveProfile!: (approved: boolean) => void;

    constructor() {}

    agInit(params: any): void {
        this.itemId = params.data.id;
        this.approved = params.data.status === 'APPROVED';
        this.status = params.data.status;
        this.field = params.colDef.field;
        this.fullName = params.data.fullName;
        this.lastUpdated = params.data.lastUpdated;
        this.approveProfile = () =>
            params.approveProfile(this.itemId, this.approved);
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    handleApprove(event: any) {
        this.approveProfile(this.approved);
    }
}
