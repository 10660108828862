<div class="app-report">
    <h1>Báo cáo số liệu</h1>
    <h2>Từ ngày {{ startDate | date: 'dd/MM/yyyy' }} đến thời điểm truy cập</h2>
    <table>
        <thead>
            <th colspan="2">Tiêu chí</th>
            <th>Số lượng</th>
        </thead>
        <tbody>
            <tr>
                <td colspan="2">Số lượng truy cập</td>
                <td>{{ statistics.totalAccess }}</td>
            </tr>

            <tr>
                <td rowspan="2">Số lượng người bán</td>
                <td>Tổng số người bán</td>
                <td>{{ statistics.totalSeller }}</td>
            </tr>
            <tr>
                <td>Số lượng người bán mới</td>
                <td>{{ statistics.newSeller }}</td>
            </tr>

            <tr>
                <td rowspan="2">Số lượng sản phẩm</td>
                <td>Tổng số sản phẩm (SKU)</td>
                <td>{{ statistics.totalProduct }}</td>
            </tr>
            <tr>
                <td>Số sản phẩm đăng bán mới</td>
                <td>{{ statistics.newProduct }}</td>
            </tr>

            <tr>
                <td rowspan="3">Số lượng giao dịch</td>
                <td>Tổng số đơn hàng</td>
                <td>{{ statistics.totalPurchase }}</td>
            </tr>
            <tr>
                <td>Tổng số đơn hàng thành công</td>
                <td>{{ statistics.successPurchase }}</td>
            </tr>
            <tr>
                <td>Tổng số đơn hàng không thành công</td>
                <td>{{ statistics.failPurchase }}</td>
            </tr>

            <tr>
                <td colspan="2">Tổng giá trị giao dịch</td>
                <td>{{ statistics.revenue }}</td>
            </tr>
        </tbody>
    </table>
</div>
