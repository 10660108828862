<div class="page-layout simple fullwidth">
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center">
        <div>
            <h2>{{ 'NAV.ACCOUNT.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="content p-24">
        <div class="order-information">
            <table class="key-status-table">
                <tr>
                    <td style="font-weight: bold; width: 290px">
                        {{ 'ACTIVATION.REMAINING' | translate }}
                    </td>
                    <td>
                        <button mat-raised-button color="accent">
                            {{
                                availableQuantity > 9999999
                                    ? 'UNLIMITED'
                                    : availableQuantity
                            }}
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="font-style: italic">
                        (*) {{ 'ACTIVATION.NOTE' | translate }}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold" colspan="2">
                        {{ 'ACTIVATION.HISTORY' | translate }}
                    </td>
                </tr>
            </table>
        </div>

        <div class="order-table-container">
            <mat-table
                class="order-table"
                #table
                [dataSource]="dataSource"
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>
                        <p>{{ 'ACTIVATION.ORDER_CODE' | translate }}</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let activationKey">
                        <p
                            class="font-weight-600"
                            title="{{ activationKey.orderCode }}"
                        >
                            {{ activationKey.orderCode }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="requester">
                    <mat-header-cell *matHeaderCellDef>
                        <p>{{ 'ACTIVATION.REQUESTER' | translate }}</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let activationKey">
                        <p
                            class="font-weight-600"
                            title="{{ activationKey.requester }}"
                        >
                            {{ activationKey.requester }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef>
                        <p>{{ 'ACTIVATION.PACKAGE' | translate }}</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let activationKey">
                        <p
                            class="font-weight-600"
                            title="{{ getPackageName(activationKey.quantity) }}"
                        >
                            {{ getPackageName(activationKey.quantity) }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        <p>{{ 'ACTIVATION.STATUS' | translate }}</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let activationKey">
                        <p
                            class="font-weight-600"
                            title="{{
                                'STATUS.' + activationKey.status | translate
                            }}"
                        >
                            {{ 'STATUS.' + activationKey.status | translate }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="activationDate">
                    <mat-header-cell *matHeaderCellDef>
                        <p>{{ 'ACTIVATION.ACTIVATION_DATE' | translate }}</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let activationKey">
                        <p
                            class="font-weight-600"
                            title="{{
                                activationKey.activationDate
                                    | date: 'dd/MM/yyyy'
                            }}"
                        >
                            {{
                                activationKey.activationDate
                                    | date: 'dd/MM/yyyy'
                            }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expirationDate">
                    <mat-header-cell *matHeaderCellDef>
                        <p>{{ 'ACTIVATION.REMAINING_DAYS' | translate }}</p>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let activationKey">
                        <p
                            class="font-weight-600"
                            title="{{ activationKey.remainingDay }}"
                        >
                            {{ activationKey.remainingDay }}
                        </p>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                    *matRowDef="let activationKey; columns: displayedColumns"
                    class="activationKey"
                    matRipple
                    [@animate]="{ value: '*', params: { y: '100%' } }"
                >
                </mat-row>
            </mat-table>
        </div>
        <div class="order-input-container" fxLayout="row">
            <div
                fxFlexFill
                fxLayout="row"
                fxLayoutAlign="end center"
                fxLayoutGap="10px"
            >
                <span style="font-weight: bold; margin-right: 24px"
                    >{{ 'ACTIVATION.EXTEND' | translate }}?</span
                >
                <div class="key-input">
                    <mat-form-field>
                        <mat-label>{{
                            'ACTIVATION.PACKAGE' | translate
                        }}</mat-label>
                        <mat-select
                            placeholder="Chọn gói"
                            name="category"
                            [(ngModel)]="quantity"
                        >
                            <mat-option
                                *ngFor="let pkg of this.packageList"
                                [value]="pkg.value"
                            >
                                {{ pkg.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <button
                        mat-raised-button
                        color="accent"
                        (click)="createOrder()"
                    >
                        {{ 'ACTIVATION.REQUEST' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
