<div class="page-layout simple fullwidth">
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center">
        Quản lý ứng viên
    </div>

    <div class="content p-16">
        <div>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Trạng thái duyệt</mat-label>

                <mat-select
                    [(ngModel)]="currentFilter"
                    (selectionChange)="triggerFilter()"
                >
                    <mat-option value="NOT_READY"> Chưa đủ hồ sơ </mat-option>
                    <mat-option value="APPROVED"> Đã duyệt </mat-option>
                    <mat-option value="PENDING_REVIEW"> Chờ duyệt </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <ag-grid-angular
            class="ag-theme-alpine"
            style="height: calc(100vh - 200px)"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [enableCellTextSelection]="true"
            [animateRows]="true"
            (gridReady)="onGridReady($event)"
            (bodyScrollEnd)="onScrollEnd($event)"
            (firstDataRendered)="onLoaded()"
            (cellClicked)="showCandidateDetails($event)"
            (rowClicked)='rowClicked($event)'
        ></ag-grid-angular>
    </div>
</div>
