import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'google-map-guide',
    templateUrl: './google-map-guide.component.html',
    styleUrls: ['./google-map-guide.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GoogleMapGuideComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<GoogleMapGuideComponent>) {}

    ngOnInit(): void {}
}
