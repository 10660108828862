import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompanyManageService {
    companies: any[];
    onCompaniesChanged: BehaviorSubject<any>;
    constructor(private httpClient: HttpClient) {
        this.companies = [];
        this.onCompaniesChanged = new BehaviorSubject<any>([]);
    }

    getCompanies() {
        return new Promise((resolve, reject) => {
            this.httpClient
                .get(environment.apiEndpoint + 'administrator/companies')
                .subscribe((res) => {
                    this.companies = res as any[];
                    this.onCompaniesChanged.next(this.companies);
                    resolve(res);
                }, reject);
        });
    }

    approveCompany(id) {
        return this.httpClient.post(
            `${environment.apiEndpoint}administrator/companies/${id}/approve`,
            {}
        );
    }
}
