import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthHeader implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var users = JSON.parse(localStorage.getItem('current_user'))
        var modified;
        if (users != null && this._isNotExpired(users)) {
            modified = req.clone({ setHeaders: { 'Authorization': 'Bearer ' + users.token } });
        } else {
            localStorage.removeItem("current_user");
            modified = req.clone();
        }

        return next.handle(modified);
    }

    private _isNotExpired(user) {
        return new Date() < new Date(user.expiration);
    }
}
