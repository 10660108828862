import { HttpClient } from "@angular/common/http";
import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseConfigService } from "@fuse/services/config.service";
import { ImageCropperDialog } from "app/layout/components/image-cropper/image-cropper.component";
import { CompanyService } from "app/main/account/company/company.service";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";

import { environment } from "../../../../../../environments/environment";
import { Company } from "../../../../../main/account/company/company.model";
import { AuthService } from "../../../../../main/authentication/auth.service";

@Component({
    selector: "navbar-vertical-style-1",
    templateUrl: "./style-1.component.html",
    styleUrls: ["./style-1.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    company: Company;

    isLoadingLogo = false;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        public _dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _companyService: CompanyService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private _httpClient: HttpClient,
        private _authService: AuthService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.company = <Company>{};
    }

    fileChangeEvent(): void {
        const dialogRef = this._dialog.open(ImageCropperDialog, {
            data: { aspectRatio: 1.81, resizeToWidth: "200px" }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.saveCompanyLogo(result);
        });
    }

    saveCompanyLogo(src: any): void {
        this.isLoadingLogo = true;
        this._companyService
            .saveCompanyLogo(src)
            .then(response => {
                this.company.logo = response.url;
                this.isLoadingLogo = false;
            })
            .catch(err => {
                console.log("cannot upload logo", err);
                this.isLoadingLogo = false;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement(
                        "navbar .nav-link.active",
                        -120
                    );
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar("navbar")) {
                    this._fuseSidebarService.getSidebar("navbar").close();
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });
        if (this._authService.isAuthenticated()) {
            this.isLoadingLogo = true;
            this._httpClient
                .get(environment.apiEndpoint + "companies/logo")
                .subscribe((response: any) => {
                    this.company = response;
                    if (this.company.logo == null) {
                        this.company.logo = "assets/images/logos/logo_blue.png";
                    }
                    this.isLoadingLogo = false;
                });
        }
        // this.toggleSidebarFolded();
        // this.toggleSidebarOpened();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleFold();
    }
}
