import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { User } from 'app/shared/models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
    forgotEmail = '';
    constructor(private _httpClient: HttpClient) {}
    public isAuthenticated(): boolean {
        const user = localStorage.getItem('current_user');
        return user != null;
    }

    public storeUser(user) {
        localStorage.setItem('current_user', JSON.stringify(user));
    }

    public getCurrentUser(): User {
        return this.isAuthenticated
            ? JSON.parse(localStorage.getItem('current_user'))
            : null;
    }

    getCurrentUserId() {
        return this.isAuthenticated() ? this.getCurrentUser().identity : -1;
    }

    public forgotPassword(email: any): Observable<any> {
        return this._httpClient.post<any>(
            this.getFullUrl(`accounts/forgotpassword`),
            email
        );
    }

    public resetPassword(body: any): Observable<any> {
        return this._httpClient.post<any>(
            this.getFullUrl(`accounts/resetpassword`),
            body
        );
    }

    getFullUrl(url): string {
        return environment.apiEndpoint + url;
    }

    public logout() {
        localStorage.removeItem('current_user');
    }
}
