import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-candidate-details-images',
    templateUrl: './candidate-details-images.component.html',
    styleUrls: ['./candidate-details-images.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CandidateDetailsImagesComponent implements OnInit {
    @Input() profileImages = [];
    @Input() isLoading = false;
    @Input() autoShow = false;
    viewingIndex = 0;
    showImage = false;
    rotationArray = [];
    imageViewerConfig = {
        btnClass: 'default image-viewer-btn', // The CSS class(es) that will apply to the buttons
        zoomFactor: 0.1, // The amount that the scale will be increased by
        containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
        wheelZoom: true, // If true, the mouse wheel can be used to zoom in
        allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to enter fullscreen mode
        allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
        btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
            zoomIn: 'material-icons icon-zoom_in',
            zoomOut: 'material-icons icon-zoom_out',
            rotateClockwise: 'material-icons icon-rotate_right',
            rotateCounterClockwise: 'material-icons icon-rotate_left',
            next: 'material-icons icon-keyboard_arrow_right',
            prev: 'material-icons icon-keyboard_arrow_left',
            fullscreen: 'material-icons icon-fullscreen',
        },
        btnShow: {
            zoomIn: true,
            zoomOut: true,
            rotateClockwise: true,
            rotateCounterClockwise: true,
            next: true,
            prev: true
        }
    };

    constructor() {}

    ngOnInit(): void {
        if (!isEmpty(this.profileImages)) {
            this.profileImages.forEach((i) => this.rotationArray.push(0));
        }
        this.showImage = this.autoShow;
    }

    handleImageEvent(e) {
        console.log(e, this.viewingIndex);
    }

    getImgList() {
        if (!isEmpty(this.profileImages)) {
            return this.profileImages.map((img) => img.url);
        }
        return [];
    }
}
