<div class="poster-list">
    <mat-grid-list cols="3" rowHeight="1.5:1" [gutterSize]="'14px'">
        <mat-grid-tile *ngFor="let posterModel of posterList; let i = index">
            <li
                [class]="selectable && selectedIndexes.includes(i) ? 'selected' : ''"
                (click)="selectedItem = i; selectPoster(i)"
            >
                <div class="poster-list-item">
                    <app-poster-view
                        *ngIf="posterList[i]"
                        [posterModel]="posterList[i]"
                    ></app-poster-view>
                    <mat-icon
                        *ngIf="isEditable"
                        class="active-icon edit-icon blue-500"
                        (click)="editPoster(i)"
                        >edit</mat-icon
                    >
                    <mat-icon
                        *ngIf="isDeletable"
                        class="active-icon delete-icon red-500"
                        (click)="deletePoster(i)"
                        >close</mat-icon
                    >
                </div>
            </li>
        </mat-grid-tile>
    </mat-grid-list>
    <div *ngIf="!posterList || !posterList.length" class="no-data-block">
        <span>{{ 'POSTER_COMPONENT.NO_DATA' | translate }}</span>
    </div>
</div>
