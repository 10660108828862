<div
    class="navbar-header"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
    <div class="logo">
        <img *ngIf="!isLoadingLogo && logo.length" class="logo-icon" [src]="logo" alt="logo" />
        <mat-spinner
            color="accent" *ngIf="isLoadingLogo" style="margin: auto"></mat-spinner>

        <button
            *ngIf="isAdmin"
            class="update-logo-btn"
            (click)="fileChangeEvent()"
        >
            <div class="icon-holder"><mat-icon>local_see</mat-icon></div>
            Cập nhật Logo
        </button>
    </div>
</div>

<div
    class="navbar-content"
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground"
>
    <fuse-navigation layout="vertical"></fuse-navigation>
</div>
