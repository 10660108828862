import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../auth.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    resetPasswordForm: FormGroup;
    sentStatus = 'NOT_YET';

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _authService: AuthService,
        private _formBuilder: FormBuilder
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            token: ['', Validators.required],
            email: [this._authService.forgotEmail, [Validators.required, Validators.email]],
            password: ['', Validators.required],
            passwordConfirm: [
                '',
                [Validators.required, confirmPasswordValidator],
            ],
        });

        this.resetPasswordForm.get('email').disable();

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm
            .get('password')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm
                    .get('passwordConfirm')
                    .updateValueAndValidity();
            });
    }

    sendResetPassword() {
        const _requestBody = {
            email: this.resetPasswordForm.get('email').value,
            password: this.resetPasswordForm.get('password').value,
            token: this.resetPasswordForm.get('token').value,
        };
        this.sentStatus = 'NOT_YET';
        this._authService.resetPassword(_requestBody).subscribe(
            (response) => {
                console.log('sent email to', _requestBody);
                this.sentStatus = 'SUCCESS';
            },
            (error) => {
                console.log('error while sending email', error);
                this.sentStatus = 'FAIL';
            }
        );
    }

    ngOnDestroy(): void {
        this._authService.forgotEmail = '';
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
