<h1 matDialogTitle>Kết quả xếp hạng</h1>
<div mat-dialog-content>
    <p>Tiêu chí xếp hạng</p>
    <table class="sort-condition-table">
        <tr>
            <td>Tiêu chí 1:</td>
            <td>
                <mat-form-field appearance="outline" fxFlex="200px">
                    <mat-select [(ngModel)]="condition1">
                        <mat-option
                            *ngFor="let condition of conditionList"
                            [value]="condition"
                        >
                            {{ condition.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-icon
                    class="icon-sort-by"
                    [ngStyle]='getColor(condition1.isAsc)'
                    (click)="condition1.isAsc = !condition1.isAsc"
                >
                    {{ condition1.isAsc ? 'arrow_upward' : 'arrow_downward' }}
                </mat-icon>
            </td>
        </tr>

        <tr>
            <td>Tiêu chí 2:</td>
            <td>
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-select [(ngModel)]="condition2">
                        <mat-option
                            *ngFor="let condition of conditionList"
                            [value]="condition"
                        >
                            {{ condition.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-icon
                    class="icon-sort-by"
                    [ngStyle]='getColor(condition2.isAsc)'
                    (click)="condition2.isAsc = !condition2.isAsc"
                >
                    {{ condition2.isAsc ? 'arrow_upward' : 'arrow_downward' }}
                </mat-icon>
            </td>
        </tr>

        <tr>
            <td>Tiêu chí 3:</td>
            <td>
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-select [(ngModel)]="condition3">
                        <mat-option
                            *ngFor="let condition of conditionList"
                            [value]="condition"
                        >
                            {{ condition.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-icon
                    class="icon-sort-by"
                    [ngStyle]='getColor(condition3.isAsc)'
                    (click)="condition3.isAsc = !condition3.isAsc"
                >
                    {{ condition3.isAsc ? 'arrow_upward' : 'arrow_downward' }}
                </mat-icon>
            </td>
        </tr>

        <tr>
            <td>Tiêu chí 4:</td>
            <td>
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-select [(ngModel)]="condition4">
                        <mat-option
                            *ngFor="let condition of conditionList"
                            [value]="condition"
                        >
                            {{ condition.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-icon
                    class="icon-sort-by"
                    [ngStyle]='getColor(condition4.isAsc)'
                    (click)="condition4.isAsc = !condition4.isAsc"
                >
                    {{ condition4.isAsc ? 'arrow_upward' : 'arrow_downward' }}
                </mat-icon>
            </td>
        </tr>

        <tr>
            <td>Tiêu chí 5:</td>
            <td>
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-select [(ngModel)]="condition5">
                        <mat-option
                            *ngFor="let condition of conditionList"
                            [value]="condition"
                        >
                            {{ condition.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-icon
                    class="icon-sort-by"
                    [ngStyle]='getColor(condition5.isAsc)'
                    (click)="condition5.isAsc = !condition5.isAsc"
                >
                    {{ condition5.isAsc ? 'arrow_upward' : 'arrow_downward' }}
                </mat-icon>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions class="pt-24 pb-24 center">
    <div class="button-wrapper">
        <button
            mat-raised-button
            color="accent"
            (click)="dialogRef.close(getReturnValue())"
        >
            Xếp hạng
        </button>
        <button
            mat-raised-button
            color="primary"
            class="ml-16"
            (click)="dialogRef.close(false)"
        >
            Quay lại
        </button>
    </div>
</div>
