<div class="page-layout simple fullwidth" id="campaigns">
    <!-- HEADER -->
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center">
        <h2 style="text-align: center">Xin chào {{ currentUser }}</h2>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">
        <div fxLayout="column" fxLayoutAlign="center"></div>
    </div>
</div>
