import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class RegisterService {
    constructor(private _httpClient: HttpClient) {}

    submit(model: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(environment.apiEndpoint + 'companies', {
                    ...model,
                    dev: !environment.production,
                })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
