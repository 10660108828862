import { Quiz } from '../database/database.model';
import * as moment from 'moment';
import { Moment } from 'moment';
import {
    Feedback,
    StatusEnum,
    UserAction,
} from '../../shared/models/campaign.model';

export class CampaignTableData {
    approver: CampaignMember;
    canAccept: boolean;
    currentRound: number;
    description: string;
    district: CampaignLocationSelection;
    from: Moment;
    to: Moment;
    id: number;
    jobInfo: CampaignJobInfo;
    lead: CampaignMember;
    name: string;
    notificationFilter: CampaignNotificationFilter;
    profileFilter: CampaignProfileFilter;
    profileRequirement: CampaignProfileRequirement;
    qrCode: string;
    resultStatus: CampaignResultStatus;
    reviewer: CampaignMember;
    rounds: CampaignRound[];
    status: string;
    approverId: number;
    reviewerId: number;
    ownerId: number;
    ownerName: string;
    feedback: Feedback;
    resultFeedback: string;
    qr: string;
    userAction?: UserAction;
    state: string;

    constructor(campaign?) {
        if (campaign) {
            this.id = campaign.id || 0;
            this.approver = campaign.approver;
            this.canAccept = campaign.canAccept;
            this.currentRound = campaign.currentRound;
            this.description = campaign.description;
            this.district = campaign.district;
            this.from = moment(campaign.from);
            this.to = moment(campaign.to);
            this.jobInfo = campaign.jobInfo || new CampaignJobInfo({});
            this.lead = campaign.lead;
            this.name = campaign.name;
            this.notificationFilter =
                campaign.notificationFilter ||
                new CampaignNotificationFilter({});
            this.profileFilter =
                campaign.profileFilter || new CampaignProfileFilter({});
            this.profileRequirement =
                campaign.profileRequirement ||
                new CampaignProfileRequirement({});
            this.qrCode = campaign.qrCode;
            this.resultStatus = campaign.resultStatus;
            this.reviewer = campaign.reviewer;
            this.rounds = campaign.rounds
                ? campaign.rounds.map((x) => new CampaignRound(x))
                : [];
            this.status = campaign.status;
            this.approverId = campaign.approverId;
            this.reviewerId = campaign.reviewerId;
            this.ownerId = campaign.ownerId;
            this.ownerName = campaign.ownerName;
            this.feedback = campaign.feedback;
            this.qr = campaign.qr;
            this.resultFeedback = campaign.resultFeedback;
            this.userAction = new UserAction(campaign.userAction);
        } else {
            this.id = 0;
            this.name = '';
            this.description = '';
            this.approverId = 0;
            this.reviewerId = 0;
            this.ownerId = 0;
            this.jobInfo = new CampaignJobInfo({});
            this.profileFilter = new CampaignProfileFilter({});
            this.rounds = [];
            this.status = StatusEnum.NOT_READY;
            this.from = moment();
            this.to = moment();
            this.userAction = new UserAction('');
        }
    }
}

export class CampaignMember {
    disable: boolean;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    phone: string;

    /**
     * Constructor
     *
     * @param campaignMember
     */
    constructor(campaignMember) {
        {
            this.id = campaignMember.id || 0;
            this.disable = campaignMember.disable;
            this.email = campaignMember.email;
            this.firstName = campaignMember.firstName;
            this.lastName = campaignMember.lastName;
            this.phone = campaignMember.phone;
        }
    }
}

export class CampaignJobInfo {
    company: CampaignCompanyAddress;
    customWorkingLocation: string;
    description: string;
    id: number;
    incomeFrom: number;
    incomeTo: number;
    jobType: CampaignJobType;
    number: number;
    requirement: CampaignProfileRequirement;
    requirementDescription: string;
    salaryFrom: number;
    salaryTo: number;
    title: string;
    workingLocation: CampaignAddress;

    /**
     * Constructor
     *
     * @param campaignJobInfo
     */
    constructor(campaignJobInfo) {
        {
            this.id = campaignJobInfo.id || 0;
            this.company =
                campaignJobInfo.company || new CampaignCompanyAddress({});
            this.customWorkingLocation = campaignJobInfo.customWorkingLocation;
            this.description = campaignJobInfo.description;
            this.incomeFrom = campaignJobInfo.incomeFrom;
            this.incomeTo = campaignJobInfo.incomeTo;
            this.jobType = campaignJobInfo.jobType;
            this.number = campaignJobInfo.number;
            this.requirement =
                campaignJobInfo.requirement ||
                new CampaignProfileRequirement({});
            this.requirementDescription =
                campaignJobInfo.requirementDescription;
            this.salaryFrom = campaignJobInfo.salaryFrom;
            this.salaryTo = campaignJobInfo.salaryTo;
            this.title = campaignJobInfo.title;
            this.workingLocation =
                campaignJobInfo.workingLocation || new CampaignAddress({});
        }
    }
}

export class CampaignAddress {
    address: string;
    district: string;
    districtId: number;
    industryPark: string;
    industryParkId: number;
    name: string;
    province: string;
    provinceId: number;
    town: string;
    townId: number;

    /**
     * Constructor
     *
     * @param campaignAddress
     */
    constructor(campaignAddress) {
        {
            this.address = campaignAddress.address;
            this.district = campaignAddress.district;
            this.districtId = campaignAddress.districtId;
            this.industryPark = campaignAddress.industryPark;
            this.industryParkId = campaignAddress.industryParkId;
            this.name = campaignAddress.name;
            this.province = campaignAddress.province;
            this.provinceId = campaignAddress.provinceId;
            this.town = campaignAddress.town;
            this.townId = campaignAddress.townId;
        }
    }
}

export class CampaignCompanyAddress {
    address: CampaignAddress;
    fullName: string;
    logo: string;
    path: string;
    prefix: string;
    shortName: string;

    /**
     * Constructor
     *
     * @param campaignCompanyAddress
     */
    constructor(campaignCompanyAddress) {
        {
            this.address = campaignCompanyAddress.address;
            this.fullName = campaignCompanyAddress.fullName;
            this.logo = campaignCompanyAddress.logo;
            this.path = campaignCompanyAddress.path;
            this.prefix = campaignCompanyAddress.prefix;
            this.shortName = campaignCompanyAddress.shortName;
        }
    }
}

export class CampaignNotificationFilter {
    followDistricts: CampaignLocationSelection[];
    followIndustrialPark: CampaignLocationSelection[];
    liveIns: CampaignLocationSelection[];

    /**
     * Constructor
     *
     * @param campaignNotificationFilter
     */
    constructor(campaignNotificationFilter) {
        {
            this.followDistricts =
                campaignNotificationFilter.followDistricts || [];
            this.followIndustrialPark =
                campaignNotificationFilter.followIndustrialPark || [];
            this.liveIns = campaignNotificationFilter.liveIns || [];
        }
    }
}

export class CampaignLocationSelection {
    id: number;
    name: string;

    /**
     * Constructor
     *
     * @param campaignLocationSelection
     */
    constructor(campaignLocationSelection) {
        {
            this.id = campaignLocationSelection.id || 0;
            this.name = campaignLocationSelection.name;
        }
    }
}

export class CampaignProfileFilter {
    date: Date;
    education: CampaignProfileFilterEducation;
    experience: number;
    fromAge: number;
    gender: CampaignGender;
    province: CampaignLocationSelection[];
    toAge: number;

    /**
     * Constructor
     *
     * @param campaignProfileFilter
     */
    constructor(campaignProfileFilter) {
        this.date = campaignProfileFilter.date;
        this.education = campaignProfileFilter.education;
        this.experience = campaignProfileFilter.experience;
        this.fromAge = campaignProfileFilter.fromAge;
        this.gender = campaignProfileFilter.gender;
        this.province = campaignProfileFilter.province || [];
        this.toAge = campaignProfileFilter.toAge;
    }
}

export class CampaignProfileRequirement {
    requireCV: boolean;
    requireCertificate: boolean;
    requireExperience: boolean;
    requireHealthCertificate: boolean;
    requireHousehold: boolean;
    requireIdentityCard: boolean;

    /**
     * Constructor
     *
     * @param campaignProfileRequirement
     */
    constructor(campaignProfileRequirement) {
        this.requireCV = campaignProfileRequirement.requireCV;
        this.requireCertificate = campaignProfileRequirement.requireCertificate;
        this.requireExperience = campaignProfileRequirement.requireExperience;
        this.requireHealthCertificate =
            campaignProfileRequirement.requireHealthCertificate;
        this.requireHousehold = campaignProfileRequirement.requireHousehold;
        this.requireIdentityCard =
            campaignProfileRequirement.requireIdentityCard;
    }
}

export class CampaignRound {
    activeFrom: Date;
    activeTo: Date;
    date: Date;
    evaluation: string;
    id: number;
    note: CampaignRoundNote;
    number: number;
    order: number;
    organizers: CampaignMember[];
    type: string;
    test: Quiz;
    active: boolean;

    /**
     * Constructor
     *
     * @param campaignRound
     */
    constructor(campaignRound) {
        this.active = campaignRound.active || false;
        this.activeFrom = new Date(campaignRound.activeFrom);
        this.activeTo = new Date(campaignRound.activeTo);
        this.date = new Date(campaignRound.date);
        this.evaluation = campaignRound.evaluation;
        this.id = campaignRound.id || 0;
        this.note = campaignRound.note
            ? new CampaignRoundNote(campaignRound.note)
            : new CampaignRoundNote({});
        this.organizers = campaignRound.organizers || [];
        this.type = campaignRound.type;
        this.test = campaignRound.test || new Quiz();
    }
}

export class CampaignRoundNote {
    contactName: string;
    contactPhone: string;
    from: Date;
    note: string;
    place: string;
    to: Date;

    /**
     * Constructor
     *
     * @param campaignRoundNote
     */
    constructor(campaignRoundNote) {
        campaignRoundNote = campaignRoundNote || {};
        this.contactName = campaignRoundNote.contactName;
        this.contactPhone = campaignRoundNote.contactPhone;
        this.from = campaignRoundNote.from
            ? new Date(campaignRoundNote.from)
            : new Date();
        this.note = campaignRoundNote.note;
        this.place = campaignRoundNote.place;
        this.to = campaignRoundNote.to
            ? new Date(campaignRoundNote.to)
            : new Date();
    }
}

export class CampaignCreatingModel {
    id: number;
    approver: number;
    description: string;
    from: Moment;
    reviewer: number;
    to: Moment;
    lead: number;
    name: string;

    jobInfo: {
        description: string;
        incomeFrom: number;
        incomeTo: number;
        jobType: string;
        number: number;
        requirementDescription: string;
        salaryFrom: number;
        salaryTo: number;
        title: string;
        customWorkingLocation: string;
        workingLocation: {
            address: string;
            industryPark: number;
            name: string;
            town: number;
        };
    };

    notificationFilter: {
        followDistricts: number[];
        followIndustrialPark: number[];
        liveIns: number[];
    };
    profileFilter: {
        date: Date;
        education: string;
        experience: number;
        fromAge: number;
        gender: string;
        province: number[];
        toAge: number;
    };
    profileRequirement: {
        requireCV: boolean;
        requireCertificate: boolean;
        requireExperience: boolean;
        requireHealthCertificate: boolean;
        requireHousehold: boolean;
        requireIdentityCard: boolean;
    };

    /**
     * Constructor
     *
     * @param campaign
     */
    constructor(campaign: CampaignTableData) {
        this.id = campaign.id;
        this.approver = campaign.approver ? campaign.approver.id : null;
        this.reviewer = campaign.reviewer ? campaign.reviewer.id : null;
        this.lead = campaign.lead ? campaign.lead.id : null;
        this.description = campaign.description || '';
        this.from = moment(campaign.from);
        this.to = moment(campaign.to);
        this.name = campaign.name || '';

        this.jobInfo = {
            description: campaign.jobInfo.description || '',
            incomeFrom: campaign.jobInfo.incomeFrom || 0,
            incomeTo: campaign.jobInfo.incomeTo || 0,
            jobType: CampaignJobType[campaign.jobInfo.jobType],
            number: campaign.jobInfo.number || 0,
            requirementDescription:
                campaign.jobInfo.requirementDescription || '',
            salaryFrom: campaign.jobInfo.salaryFrom || 0,
            salaryTo: campaign.jobInfo.salaryTo || 0,
            title: campaign.jobInfo.title || '',
            workingLocation: {
                address: '',
                industryPark: null,
                name: '',
                town: null,
            },
            customWorkingLocation: campaign.jobInfo.customWorkingLocation || '',
        };

        if (campaign.jobInfo.workingLocation) {
            this.jobInfo.workingLocation.address =
                campaign.jobInfo.workingLocation.address;
            this.jobInfo.workingLocation.industryPark =
                campaign.jobInfo.workingLocation.industryParkId;
            this.jobInfo.workingLocation.name =
                campaign.jobInfo.workingLocation.name;
            this.jobInfo.workingLocation.town =
                campaign.jobInfo.workingLocation.townId;
        }

        this.notificationFilter = {
            followDistricts: campaign.notificationFilter.followDistricts.map(
                (x) => x.id
            ),
            followIndustrialPark:
                campaign.notificationFilter.followIndustrialPark.map(
                    (x) => x.id
                ),
            liveIns: campaign.notificationFilter.liveIns.map((x) => x.id),
        };
        this.profileFilter = {
            date: campaign.profileFilter.date || new Date(),
            education:
                CampaignProfileFilterEducation[
                    campaign.profileFilter.education
                ],
            experience: campaign.profileFilter.experience || 0,
            fromAge: campaign.profileFilter.fromAge || 0,
            gender: CampaignGender[campaign.profileFilter.gender],
            province: campaign.profileFilter.province.map((x) => x.id),
            toAge: campaign.profileFilter.toAge || 0,
        };
        this.profileRequirement = {
            requireCV: campaign.profileRequirement.requireCV || false,
            requireCertificate:
                campaign.profileRequirement.requireCertificate || false,
            requireExperience:
                campaign.profileRequirement.requireExperience || false,
            requireHealthCertificate:
                campaign.profileRequirement.requireHealthCertificate || false,
            requireHousehold:
                campaign.profileRequirement.requireHousehold || false,
            requireIdentityCard:
                campaign.profileRequirement.requireIdentityCard || false,
        };
    }
}

export class CampaignRoundModel {
    activeFrom: Date;
    activeTo: Date;
    date: Date;
    evaluation: CampaignRoundEvaluation;
    note: CampaignRoundNote;
    number: number;
    order: number;
    organizers: number[];
    type: CampaignRoundType;
    test: number;

    /**
     * Constructor
     *
     * @param campaignRound
     */
    constructor(campaignRound) {
        this.activeFrom = campaignRound.activeFrom;
        this.activeTo = campaignRound.activeTo;
        this.date = campaignRound.date;
        this.evaluation = campaignRound.evaluation;
        this.note = campaignRound.note;
        this.number = campaignRound.number;
        this.order = campaignRound.order;
        this.organizers = campaignRound.organizers.map((x) => x.id);
        this.type = campaignRound.type;
        this.test = campaignRound.test ? campaignRound.test.id : 0;
    }
}

export enum CampaignResultStatus {
    NOT_READY,
    REVIEWED,
    APPROVED,
}

export enum CampaignJobType {
    FULL_TIME,
    SHIFT_TIME,
    PART_TIME,
}

export enum CampaignRoundEvaluation {
    AUTO,
    MANUAL,
}

export enum CampaignRoundType {
    NOTIFICATION,
    FILTER,
    HIRING_CONFIRMATION,
    TEST,
    SKILL,
    INTERVIEW,
}

export enum CampaignProfileFilterEducation {
    SECONDARY_SCHOOL,
    HIGH_SCHOOL,
    UNKNOWN,
}

export enum CampaignGender {
    MALE,
    FEMALE,
    OTHER,
    UNKNOWN,
}

export enum CampaignStatusFilter {
    PREVIEWING,
    RUNNING,
    COMPLETED,
}

export interface SaveEmitter {
    isSaving?: boolean;
    callback?: any;
    isSavingStep?: boolean;
}
