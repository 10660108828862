import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { FuseNavigationModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { NavbarVerticalStyle1Component } from "app/layout/components/navbar/vertical/style-1/style-1.component";

import { ImageCropperDialogModule } from "app/layout/components/image-cropper/image-cropper.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    declarations: [NavbarVerticalStyle1Component],
    imports: [
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatDialogModule,
        ImageCropperDialogModule,

        FuseSharedModule,
        FuseNavigationModule
    ],
    exports: [NavbarVerticalStyle1Component]
})
export class NavbarVerticalStyle1Module {}
