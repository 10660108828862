import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ACCOUNT_ROLE } from '../../account/account.model';
import { isEmpty } from 'lodash';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public authService: AuthService, public router: Router) {}
    canActivate(): boolean {
        if (!this._isAuthorized()) {
            this.router.navigate(['auth/login']);
            return false;
        }
        const currentUser = this.authService.getCurrentUser();
        const { role = '' } = currentUser;
        if (isEmpty(role) || role === ACCOUNT_ROLE.BCT_ADMIN) {
            this.authService.logout();
            this.router.navigate(['/auth/login']);
        }
        return true;
    }

    private _isAuthorized() {
        return this.authService && this.authService.isAuthenticated();
    }
}
