<div id="image-cropper-dialog">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Tải logo</span>
            <button
                mat-icon-button
                (click)="dialogRef.close()"
                aria-label="Close dialog"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

  <div mat-dialog-content>
    <div fxLayout="row">
      <input style="display: none;" id="input-file-id" (change)="fileChangeEvent($event)" type="file" />
      <label for="input-file-id" class="mat-raised-button mat-accent" fxLayout="row">Chọn ảnh</label>
    </div>
    <div fxLayoutAlign="center center">
      <mat-spinner
          color="accent" [style.display]="showSpinner ? null : 'none'"></mat-spinner>
    </div>
    <image-cropper *ngIf="!showError" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="aspectRatio"
      [resizeToWidth]="resizeToWidth" format="jpeg" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()" [style.display]="showCropper ? null : 'none'">
    </image-cropper>
    <div class="error-msg" *ngIf="showError">{{errorMessage}}</div>
    <div class="blank-logo" *ngIf="!showCropper"></div>
      <div fxLayout='row' fxLayoutAlign='end end'>
          <button *ngIf="showCropper && !showError" mat-raised-button color="accent" [mat-dialog-close]="croppedImage"
                  (click)="save()">Lưu</button>
      </div>
  </div>
</div>