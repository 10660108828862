<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <h3>{{'POSTER_COMPONENT.CREATE_POSTER' | translate}}</h3>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 m-0" style='max-height: 90vh'>
        <app-poster-creator
            [posterModelInput]="poster.model"
            [posterSize]="posterSizeModel"
            (emitter)="getPosterCreatorApi($event)"
            (buttonEmitter)="onButtonEmitter($event)"
        ></app-poster-creator>
    </div>
</div>
