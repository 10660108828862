import { ActivationKeyComponent } from './activation-key.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivationKeyService } from './activation-key.service';

const routes: Routes = [
  {
    path: '',
    component: ActivationKeyComponent,
    resolve: {
      activationKey: ActivationKeyService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivationKeyRoutingModule { }
