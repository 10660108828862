import { FuseUtils } from '@fuse/utils';

export class Question {
    id: string;
    category: string;
    choices: QuestionChoice[];
    content: string;
    createdBy: Date;
    creationDate: Date;
    images: QuestionImage[];
    lastModifiedBy: Date;
    lastUpdated: Date;
    relatedPosition: any;
    topic: string;
    type: string;

    /**
     * Constructor
     *
     * @param question
     */
    constructor(question?) {
        {
            question = question || {};
            this.id = question.id || null;
            this.category = question.category || '';
            this.choices = question.choices || [];
            this.content = question.content || '';
            this.createdBy = question.createdBy || null;
            this.creationDate = question.creationDate || null;
            this.images = question.images || [];
            this.lastModifiedBy = question.lastModifiedBy || null;
            this.lastUpdated = new Date(question.lastModifiedDate) || null;
            this.relatedPosition = question.relatedPosition || null;
            this.topic = question.topic || '';
            this.type = question.type || '';
        }
    }
}

export class QuestionChoice {
    id: number;
    answer: boolean;
    content: string;
    createdBy: Date;
    creationDate: Date;
    lastModifiedBy: Date;
    lastModifiedDate: Date;
    score: number;

    /**
     * @param questionChoice
     */
    constructor(questionChoice?) {
        this.id = questionChoice.id || null;
        this.answer = questionChoice.answer || false;
        this.content = questionChoice.content || '';
        this.createdBy = questionChoice.createdBy || null;
        this.creationDate = questionChoice.creationDate || null;
        this.lastModifiedBy = questionChoice.lastModifiedBy || null;
        this.lastModifiedDate = questionChoice.lastModifiedDate || null;
        this.score = questionChoice.score || 0;

    }
}

export class QuestionImage {
    index: number;
    src: string;
    caption: string;
    constructor(question?) {
        if (!question) {
            this.index = -1;
            this.src = "";
            this.caption = "";
        } else {
            this.index = question.index;
            this.src = question.src;
            this.caption = question.caption;
        }
    }
}

export class Quiz {
    id: number;
    name: string;
    questions: any[];
    pass: number;
    factor: number;
    time: number;
    evaluation: string;
    category: string;
    description: string;
    createdBy: Date;
    creationDate: Date;
    lastModifiedBy: Date;
    lastUpdated: Date;

    /**
     * Constructor
     *
     * @param quiz
     */
    constructor(quiz?) {
        {
            quiz = quiz || {};
            this.id = quiz.id || null;
            this.name = quiz.name || '';
            this.questions = quiz.questions || [];
            this.pass = quiz.pass || 0;
            this.factor = quiz.factor || 0;
            this.time = quiz.time || 0;
            this.evaluation = quiz.evaluation || '';
            this.category = quiz.category || 'test';
            this.description = quiz.description || '';
            this.createdBy = quiz.createdBy || '';
            this.creationDate = quiz.creationDate || '';
            this.lastModifiedBy = quiz.lastModifiedBy || '';
            this.lastUpdated = quiz.lastUpdated || '';
        }
    }
}
