import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class AppReportGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}
    canActivate() {
        if (!this.authService || !this.authService.isAuthenticated()) {
            this.router.navigate(['auth/login']).then(() => {});
            return false;
        } else {
            const currentUser = this.authService.getCurrentUser();
            return true;
        }
    }
}
