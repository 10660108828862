import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { FuseSharedModule } from '../../shared.module';

@NgModule({
    declarations: [FuseConfirmDialogComponent],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        CommonModule,
        FormsModule,
        FuseSharedModule,
    ],
    entryComponents: [FuseConfirmDialogComponent],
})
export class FuseConfirmDialogModule {}
