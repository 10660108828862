<div class="page-layout simple fullwidth">
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center">
        <div>
            <h2>{{ 'NAV.WELFARE.TITLE' | translate }}</h2>
        </div>
    </div>

    <div class="content p-16">
        <mat-tab-group selectedIndex="1">
            <mat-tab label="{{ 'POSTER_COMPONENT.POSTERS' | translate }}">
                <app-poster></app-poster>
            </mat-tab>

            <mat-tab label="{{ 'WELFARE.TITLE' | translate }}" #tab2>
                <app-welfare *ngIf='tab2.isActive'></app-welfare>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
