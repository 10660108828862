<div *ngIf="field === 'action'">
    <mat-slide-toggle
        [(ngModel)]="approved"
        (change)="handleApprove($event)"
    ></mat-slide-toggle>
</div>
<div *ngIf="field === 'status'">{{"STATUS."+status | translate}}</div>
<div *ngIf="field === 'fullName'">
    <a style="cursor: pointer">{{fullName}}</a>
</div>
<div *ngIf="field === 'lastUpdated'">{{lastUpdated | date:'hh:mm dd.MM.yyyy'}}</div>
