import { NgModule } from '@angular/core';
import { PipesModule } from './pipes/pipes.module';
import { CandidateDetailsImagesComponent } from '../main/campaign/shared/candidate-details-images/candidate-details-images.component';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { FuseSharedModule } from '../../@fuse/shared.module';

@NgModule({
    imports: [
        PipesModule,
        AngularImageViewerModule,
        FuseSharedModule,
    ],
    exports: [PipesModule, CandidateDetailsImagesComponent],
    declarations: [CandidateDetailsImagesComponent],
})
export class SharedModule {}
