import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import CampaignUtils from '../campaign-utils';
import { CampaignService } from '../../campaign.service';

@Component({
    selector: 'app-candidate-details-dialog',
    templateUrl: './candidate-details-dialog.component.html',
    styleUrls: ['./candidate-details-dialog.component.scss'],
})
export class CandidateDetailsDialogComponent implements OnInit {
    candidate = {
        avatar: '',
        age: '',
        address: '',
        email: '',
        currentAddress: '',
        fullName: '',
        certificateStrings: [],
        experienceStrings: [],
        prerequisite: '',
        score: '',
        result: '',
        gender: '',
        health: '',
        healthCertificate: {
            images: [],
        },
        certificate: [
            {
                images: [],
            },
        ],
        certificates: [
            {
                images: [],
            },
        ],
        identityCard: {
            images: [],
        },
        curriculumVitae: {
            applicationImages: [],
        },
        householdImages: [],
        originalProfile: {},
    };
    profileImages = [];
    isLoading = false;

    constructor(
        private campaignService: CampaignService,
        public dialogRef: MatDialogRef<CandidateDetailsDialogComponent>
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.campaignService
            .getCandidateOriginalProfile(this.candidate.email)
            .subscribe((response) => {
                this.isLoading = false;
                this.candidate.originalProfile = response;
                CampaignUtils.getImagesFromCandidate(
                    this.profileImages,
                    this.candidate
                );
            }, error => {
                this.isLoading = false;
                console.log('Error while loading profile: ', error);
            });
    }
}
