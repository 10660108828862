<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0">

        <form [formGroup]="form">

            <div *ngIf="requestCurrentPassword" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Mật khẩu hiện tại</mat-label>
                    <input matInput name="currentPassword" formControlName="currentPassword"
                        [type]="hide ? 'password' : 'text'" minlength="6" required>
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                    <mat-error>Mật khẩu phải có ít nhất 6 ký tự</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Mật khẩu mới</mat-label>
                    <input matInput name="password" formControlName="password" [type]="hideP ? 'password' : 'text'"
                        minlength="6" required>
                    <mat-icon matSuffix (click)="hideP = !hideP">{{hideP ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                    <mat-error>Mật khẩu phải có ít nhất 6 ký tự</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Nhập lại mật khẩu mới</mat-label>
                    <input matInput name="passwordConfirmation" formControlName="passwordConfirmation"
                        [type]="hidePC ? 'password' : 'text'" minlength="6" required>
                    <mat-icon matSuffix (click)="hidePC = !hidePC">{{hidePC ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                    <mat-error>Mật khẩu phải có ít nhất 6 ký tự</mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <div *ngIf="mismatchPassword()" class="error-text">
            Mật khẩu nhập lại không khớp
        </div>
        <div *ngIf="wrongPassword" class="error-text">
            Sai mật khẩu hiện tại
        </div>
        <button mat-button class="save-button" (click)="savePassword()" [disabled]="form.invalid"
            aria-label="SAVE">
            Lưu
        </button>
    </div>
</div>