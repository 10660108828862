<div class="tab-content p-24">
    <div class="welfare">
        <mat-accordion>
            <mat-expansion-panel
                *ngFor="let welfare of welfareList"
                (expandedChange)="loadWelfare(welfare, $event)"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span> ({{ welfare.size }})&nbsp; </span>
                        <span>
                            {{ 'WELFARE.' + welfare.category | translate }}
                        </span></mat-panel-title
                    >
                </mat-expansion-panel-header>

                <div
                    fxFlex
                    fxLayout="column"
                    *ngIf="loadingWelfareIds.includes(welfare.id)"
                    class="full-width"
                >
                    <div
                        class="pb-16"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                    >
                        <span>Đang tải...</span>
                    </div>
                </div>

                <div fxFlex fxLayout="column"
                     *ngIf="!loadingWelfareIds.includes(welfare.id)"
                     class="full-width">
                    <div
                        class="pb-16"
                        *ngIf="welfare.status == 'YES'"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                    >
                        <div class="h2 secondary-text">
                            {{ 'POSTER_COMPONENT.POSTERS' | translate }}
                        </div>
                        <button
                            class="ml-8"
                            mat-icon-button
                            aria-label="add branches"
                            (click)="onAddPosterBtnClick(welfare.id)"
                        >
                            <mat-icon class="secondary-text add-icon"
                                >add_circle_outline</mat-icon
                            >
                        </button>
                    </div>

                    <div class="content">
                        <div
                            class="center"
                            fusePerfectScrollbar
                            *ngIf="welfare.status == 'YES'"
                        >
                            <div
                                class="panel-body poster-list-panel"
                                *ngIf="posterList && posterList.length"
                            >
                                <app-poster-list
                                    [posterList]="
                                        generatePosterListById(welfare.posters)
                                    "
                                    [selectable]="false"
                                    [deletable]="true"
                                    (deletedPosterEmitter)="
                                        deletePoster(welfare.id, $event)
                                    "
                                ></app-poster-list>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
