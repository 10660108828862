import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { CompanyService } from '../../company/company.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-poster-adding-form',
    templateUrl: './poster-adding-form.component.html',
    styleUrls: ['./poster-adding-form.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})

export class PosterAddingFormComponent implements OnInit, OnDestroy {
    posterList: any;
    selectedPoster: any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        public matDialogRef: MatDialogRef<PosterAddingFormComponent>,
        private _companyService: CompanyService
    ) {
        this._unsubscribeAll = new Subject();
        this.selectedPoster = undefined;
    }

    ngOnInit() {
        this._companyService.onCompanyChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((c) => {
                this.posterList = cloneDeep(c.posters);
            });
    }

    ngOnDestroy() {
        this._unsubscribeAll.complete();
    }

    selectPoster(event: any) {
        this.selectedPoster = event.selectedPosters;
    }

    onNoClick() {
        this.matDialogRef.close();
    }
}
