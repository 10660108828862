import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivationKeyService } from '../account/activation-key/activation-key.service';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
} from 'ag-grid-community';
import CampaignUtils from '../campaign/shared/campaign-utils';
import { DatePipe } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';

const datePipe = new DatePipe('en-US');
const getPackageName = (value: number) =>
    value > 9999999 ? 'UNLIMITED' : 'MAX' + value;

@Component({
    selector: 'app-activation-manage',
    templateUrl: './activation-manage.component.html',
    styleUrls: ['./activation-manage.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ActivationManageComponent implements OnInit, OnDestroy {
    columnDefs: ColDef[] = [
        {
            field: 'companyId',
            headerName: 'Mã Cty',
            width: 100,
            sort: 'desc',
        },
        {
            field: 'companyTaxCode',
            headerName: 'MST',
            width: 150,
        },
        {
            field: 'companyName',
            headerName: 'Tên Cty',
            width: 150,
        },
        {
            field: 'requesterWithGender',
            headerName: 'Người gửi',
            width: 150,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 150,
        },
        {
            field: 'phone',
            headerName: 'Điện thoại',
            width: 150,
        },
        {
            field: 'orderCode',
            headerName: 'Mã đơn hàng',
            width: 150,
        },
        {
            field: 'purchaseDate',
            headerName: 'Thời gian đặt hàng',
            width: 150,
            valueGetter: (item) =>
                datePipe.transform(item.data.purchaseDate, 'dd.MM.yyyy'),
        },
        {
            field: 'quantity',
            headerName: 'Gói tuyển dụng',
            width: 150,
            valueGetter: (item) => getPackageName(item.data.quantity),
        },
        {
            field: 'price',
            headerName: 'Giá',
            width: 150,
            cellRenderer: ActivationManageCellRenderer,
            cellRendererParams: {
                updatePrice: (order) => this.updatePrice(order),
            },
        },
        {
            field: 'status',
            headerName: 'Đã thanh toán',
            width: 150,
            cellRenderer: ActivationManageCellRenderer,
        },
        {
            field: 'action',
            headerName: '',
            width: 150,
            cellRenderer: ActivationManageCellRenderer,
            cellRendererParams: {
                activeOrder: (order) => this.activeOrder(order),
            },
        },
    ];
    gridApi!: GridApi;
    defaultColDef = CampaignUtils.defaultColDef;
    rowData = [];
    private unsubscribeAll: Subject<any>;

    constructor(
        private matSnackBar: MatSnackBar,
        private matDialog: MatDialog,
        private activationKeyService: ActivationKeyService
    ) {
        this.unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.activationKeyService.getKeyHistory().then(() => {});

        this.activationKeyService.onActivationKeysChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((activationKeys) => {
                this.rowData = activationKeys;
            });
    }

    activeOrder(order) {
        const confirmDialog = this.matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });

        confirmDialog.componentInstance.confirmMessage = `Xác nhận gửi kích hoạt?`;
        confirmDialog.componentInstance.noLabel = 'Xem lại';

        confirmDialog.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.handleActiveOrderRequest(order);
        });
    }

    handleActiveOrderRequest(order) {
        const { id = 0, quantity = 0, companyId = 0, price = 0 } = order;
        if (id) {
            this.activationKeyService
                .updateOrder({
                    id,
                    quantity,
                    companyId,
                    status: 'ACTIVE',
                    price,
                })
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(
                    () => {
                        this.matSnackBar.open('Kích hoạt thành công!', 'Đóng', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                        this.activationKeyService
                            .getKeyHistory()
                            .then(() => {});
                    },
                    (error) => {
                        this.matSnackBar.open('Kích hoạt thất bại!', 'Đóng', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                        console.log('activeOrder', { error });
                    }
                );
        }
    }

    updatePrice(order) {
        const confirmDialog = this.matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });

        confirmDialog.componentInstance.confirmMessage = `Xác nhận cập nhật giá?`;
        confirmDialog.componentInstance.noLabel = 'Xem lại';

        confirmDialog.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.handleUpdatePrice(order);
        });
    }

    handleUpdatePrice(order) {
        const { id = 0, quantity = 0, companyId = 0, price, status } = order;
        if (id) {
            this.activationKeyService
                .updateOrder({ status, id, quantity, companyId, price })
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(
                    () => {
                        this.matSnackBar.open('Cập nhật thành công!', 'Đóng', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                        this.activationKeyService
                            .getKeyHistory()
                            .then(() => {});
                    },
                    (error) => {
                        this.matSnackBar.open('Cập nhật thất bại!', 'Đóng', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                        console.log('activeOrder', { error });
                    }
                );
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onGridReady(event: GridReadyEvent) {
        this.gridApi = event.api;
    }
}

@Component({
    templateUrl: 'activation-manage-cell-renderer.html',
    styleUrls: ['./activation-manage.component.scss'],
})
export class ActivationManageCellRenderer implements ICellRendererAngularComp {
    params = {
        colDef: {
            field: '',
        },
        data: {
            status: '',
            paid: false,
            price: 0,
        },
        activeOrder: (data) => {},
        updatePrice: (data) => {},
    };

    activeOrder!: () => void;
    updatePrice!: () => void;

    constructor() {}

    agInit(params: any): void {
        console.log(params);
        this.params = params;
        this.activeOrder = () => this.params.activeOrder(this.params.data);
        this.updatePrice = () => this.params.updatePrice(this.params.data);
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    isPending(status) {
        return status !== 'ACTIVE' && status !== 'EXPIRED';
    }
}
