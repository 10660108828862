import { Pipe, PipeTransform } from "@angular/core";
import { Address } from "../models/company.model";

@Pipe({ name: "companyAddressToString" })
export class CompanyAddressToStringPipe implements PipeTransform {
    transform(address: Address): string {
        return `${this.toString(address.address)}${this.toString(
            address.industryPark
        )}${this.toString(address.town)}${this.toString(
            address.district
        )}${this.toString(address.province, true)}`;
    }

    private toString(str: string, isLast: boolean = false): string {
        return str ? `${str}${isLast ? "." : ", "}` : "";
    }
}
