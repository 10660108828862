<div class="page-layout simple fullwidth">
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center">
        Tài khoản tuyển dụng
    </div>

    <div class="content p-16">
        <ag-grid-angular
            class="ag-theme-alpine"
            style="height: calc(100vh - 150px)"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [enableCellTextSelection]="true"
            [animateRows]="true"
            (gridReady)="onGridReady($event)"
        ></ag-grid-angular>
    </div>
</div>
