import { cloneDeep, isEmpty, get } from 'lodash';
import * as moment from 'moment';
import {
    CandidateEvaluationEnum,
    CandidateEvaluationEnum2LabelMapping,
    GenderEnum,
    GenderEnum2LabelMapping,
    ResultEvaluationTypeEnum,
} from '../../../shared/models/campaign.model';
import { TestResultCellRendererComponent } from '../campaign-running/campaign-running-steps/shared/test-result-cell-renderer.component';
import { ColDef } from 'ag-grid-community';

export default class CampaignUtils {
    static checkBoxCol = {
        field: 'selector',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 30,
        suppressNavigable: true,
        cellStyle: { 'margin-top': '7px' },
    };

    static defaultColDef: ColDef = {
        sortable: true,
        filter: false,
        resizable: true,
        sortingOrder: ['desc', 'asc', null],
    };

    static genericTestResultColDefs = [
        {
            // valueGetter: 'node.rowIndex + 1',
            field: 'order',
            headerName: '#',
            width: 50,
            sortable: false,
            cellStyle: { 'text-align': 'center' },
            cellClass: 'center-cell-class',
        },
        {
            field: 'avatar',
            cellRenderer: TestResultCellRendererComponent,
            headerName: 'Hình thẻ',
            width: 72,
            sortable: false,
            autoHeight: true,
            cellStyle: { 'text-align': 'center' },
        },
        {
            field: 'fullName',
            cellRenderer: TestResultCellRendererComponent,
            headerName: 'Họ và tên',
            minWidth: 130,
            cellClass: 'center-cell-class',
        },
        {
            field: 'confirm',
            headerName: 'UVXN',
            width: 70,
            cellRenderer: TestResultCellRendererComponent,
            cellStyle: { 'text-align': 'center' },
        },
        {
            field: 'resultTakenTime',
            headerName: 'Xác thực lúc',
            width: 120,
            cellRenderer: TestResultCellRendererComponent,
            cellClass: 'center-cell-class',
        },
        {
            field: 'examinerName',
            headerName: 'Người phỏng vấn',
            width: 150,
            cellClass: 'center-cell-class',
        },
        {
            field: 'prerequisiteTranslated',
            headerName: 'Điểm sàng lọc',
            width: 120,
            cellClass: 'center-cell-class',
        },
        {
            field: 'scoreTranslated',
            headerName: 'Điểm đánh giá (%)',
            width: 140,
            cellClass: 'right-cell-class',
        },
        {
            field: 'resultTranslated',
            headerName: 'KQ đánh giá',
            width: 100,
            cellClass: 'center-cell-class',
        },
        {
            field: 'resultCheckerName',
            headerName: 'Người đánh giá',
            width: 150,
            cellClass: 'center-cell-class',
        },
    ];

    static generateColumDefs = (round, isTest = false) => {
        if (isEmpty(round)) {
            return [];
        }

        const genericTable = isTest
            ? CampaignUtils.genericTestResultColDefs.filter(
                  (def) => def.field !== 'examinerName'
              )
            : CampaignUtils.genericTestResultColDefs;

        const newColumnDefs = round.active
            ? [CampaignUtils.checkBoxCol, ...genericTable]
            : [...genericTable];
        return cloneDeep(newColumnDefs);
    };

    static convertToTestResultTableRow = (rawItem, round = {}) => {
        const isAutoEvaluation =
            (get(round, 'evaluationType') || '') ===
            ResultEvaluationTypeEnum.AUTO;
        const item = cloneDeep(rawItem);
        const {
            birthday = 0,
            firstName = '',
            lastName = '',
            certificates = [],
            address,
            currentAddress,
            experiences = [],
            authorized = false,
            prerequisite = {},
            score = 0,
            result = CandidateEvaluationEnum.NOT_AVAILABLE,
            gender = GenderEnum.UNKNOWN,
            healthCertificate = {},
            examiner = {},
            resultChecker = {},
            profile = {},
        } = item;

        const getPrerequisiteString = ({ score: prerequisiteScore, total }) => {
            if (isEmpty(prerequisite)) {
                return '';
            }
            if (!total) {
                return 'Không đánh giá';
            }

            return prerequisiteScore === total ? 'Đạt' : 'Không đạt';
        };

        const prerequisiteString = authorized
            ? getPrerequisiteString(prerequisite)
            : '';

        const age =
            new Date().getFullYear() - new Date(birthday).getFullYear() + '';

        const examinerFirstName = get(examiner, 'firstName') || '';
        const examinerLastName = get(examiner, 'lastName') || '';

        const resultCheckerFirstName = get(resultChecker, 'firstName') || '';
        const resultCheckerLastName = get(resultChecker, 'lastName') || '';

        let certificateStrings = '';

        try {
            certificateStrings = certificates.map((certificate) =>
                CampaignUtils.readCertificateInfo(certificate)
            );
        } catch (e) {
            console.log({ e });
        }

        let experienceStrings = '';

        try {
            experienceStrings = experiences.map((experience) =>
                CampaignUtils.readExperience(experience)
            );
        } catch (e) {
            console.log({ e });
        }

        return {
            age,
            fullName: `${lastName} ${firstName}`,
            certificateStrings,
            experienceStrings,
            ...profile,
            ...item,
            address: CampaignUtils.readAddress(address),
            currentAddress: CampaignUtils.readAddress(currentAddress),
            prerequisiteTranslated: prerequisiteString,
            scoreTranslated: authorized ? CampaignUtils.formatScore(score) : '',
            result,
            resultTranslated: authorized
                ? CandidateEvaluationEnum2LabelMapping[result]
                : '',
            gender: GenderEnum2LabelMapping[gender],
            health:
                isEmpty(healthCertificate) || isEmpty(healthCertificate.type)
                    ? ''
                    : healthCertificate.type,
            examinerName: `${examinerLastName} ${examinerFirstName}`,
            resultCheckerName: authorized
                ? !isAutoEvaluation
                    ? `${resultCheckerLastName} ${resultCheckerFirstName}`
                    : 'Tự động'
                : '',
        };
    };

    static readCertificateInfo(certificate) {
        if (isEmpty(certificate)) {
            return '';
        }
        let certificateString = certificate.type;

        if (certificate.technique && certificate.technique.length) {
            certificateString += ' - ' + certificate.technique;
        }

        if (certificate.issuer && certificate.issuer.length) {
            certificateString += ' - ' + certificate.issuer;
        }

        if (certificate.date) {
            certificateString +=
                ' - ' + moment(new Date(certificate.date)).format('DD/MM/YYYY');
        }

        return certificateString;
    }

    static readExperience(exp) {
        if (isEmpty(exp)) {
            return '';
        }

        let expString = '';
        const nYear = exp.year ? `${exp.year} năm ` : '';
        const nMonth = exp.month ? `${exp.month} tháng ` : '';
        expString += nYear + nMonth;

        if (exp.industry && exp.industry.length) {
            expString += ' - ' + exp.industry;
        }

        if (exp.company && exp.company.length) {
            expString += ' - ' + exp.company;
        }

        return expString;
    }

    static readAddress(addressObj: any): string {
        if (isEmpty(addressObj)) {
            return '';
        }
        if (typeof addressObj === 'string') {
            return addressObj;
        }
        const {
            address = '',
            town = '',
            district = '',
            province = '',
        } = addressObj;
        return `${address}${
            isEmpty(town) || isEmpty(address) ? town : ', ' + town
        }${isEmpty(district) ? '' : ', ' + district}${
            isEmpty(province) ? '' : ', ' + province
        }`;
    }

    static sortByCustomCriteria = (list = [], criteria = []) =>
        list.sort((a, b) => {
            let scoreA = 0;
            let scoreB = 0;
            const n = criteria.length;
            criteria.forEach((c, i) => {
                const { path = '', isAsc = false } = c;
                if (isEmpty(path)) {
                    return;
                }
                const valA = get(a, path, 0) || 0;
                const valB = get(b, path, 0) || 0;
                if (valA === valB) {
                    return;
                }
                const score = 10 ** (n - i);
                if (!isAsc) {
                    if (valA > valB) {
                        scoreB += score;
                    } else {
                        scoreA += score;
                    }
                } else {
                    if (valA < valB) {
                        scoreB += score;
                    } else {
                        scoreA += score;
                    }
                }
            });

            return scoreA - scoreB;
        });

    static getFeedback(campaign) {
        const feedBackText =
            get(campaign, 'feedback.generalInformationFeedback') || '';
        return isEmpty(campaign.resultFeedback)
            ? feedBackText
            : campaign.resultFeedback;
    }

    static getImagesFromCandidate(profileImages, candidate) {
        try {
            const healthImages =
                get(candidate, 'originalProfile.healthCertificate.images') ||
                [];
            const personalInformationImages =
                get(
                    candidate,
                    'originalProfile.personalInformation.images'
                ) || [];
            const identityCardImages =
                get(candidate, 'originalProfile.identityCard.images') || [];
            const applicationImages =
                get(
                    candidate,
                    'originalProfile.curriculumVitae.applicationImages'
                ) || [];
            const householdImages =
                get(candidate, 'originalProfile.householdImages') || [];
            const certificate =
                get(candidate, 'originalProfile.certificate') || [];

            certificate.forEach(({ images = [] }, index) => {
                if (!isEmpty(images)) {
                    images.forEach(({ url }, i) => {
                        profileImages.push({
                            translation: "CERTIFICATE",
                            name:
                                ' (' +
                                (index + 1) +
                                '-' +
                                (i + 1) +
                                ')',
                            url,
                        });
                    });
                }
            });

            healthImages.forEach(({ url }, i) => {
                profileImages.push({
                    translation: "HEALTH_CERTIFICATE",
                    name: ` (${i + 1})`,
                    url,
                });
            });

            householdImages.forEach(({ url }, i) => {
                profileImages.push({
                    translation: "PROFILE",
                    name: ` (${i + 1})`,
                    url,
                });
            });

            if (isEmpty(householdImages)) {
                personalInformationImages.forEach(({ url }, i) => {
                    profileImages.push({
                        translation: "PROFILE",
                        name: ` (${i + 1})`,
                        url,
                    });
                });
            }

            applicationImages.forEach(({ url }, i) => {
                profileImages.push({
                    translation: "APPLICATION_FORM",
                    name: ` (${i + 1})`,
                    url,
                });
            });

            identityCardImages.forEach(({ url }, i) => {
                profileImages.push({
                    translation: i > 0 ? "ID_CARD_BACK" : "ID_CARD_FRONT",
                    name: '',
                    url,
                });
            });
        } catch (e) {
            console.log('Error while getting images from candidate', e);
        }
    }

    static updateHeaderTranslation(colDefs = [], translation = []) {
        this.genericTestResultColDefs.forEach((c) => {
            try {
                c.headerName = translation[c.field];
            } catch (e) {
                console.log('not found translation for ', { c, e });
            }
        });

        colDefs.forEach((c) => {
            try {
                c.headerName = translation[c.field];
            } catch (e) {
                console.log('not found translation for ', { c, e });
            }
        });
    }

    static formatScore(score: number) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        }).format(score);
    }
}
