import {
    AfterViewInit,
    Component,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { CropperSettings, ImageCropperComponent } from "ngx-img-cropper";

export interface QuestionImageCropperData {
    img: any;
    height: any;
    width: any;
}

@Component({
    selector: "fw-logo-image-cropper-form",
    templateUrl: "./logo-image-cropper-form.component.html",
    styleUrls: ["./logo-image-cropper-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LogoImageCropperFormComponent implements OnInit, AfterViewInit {
    IMAGE_HEIGHT: number;
    IMAGE_WIDTH: number;
    cropperSettings: any;
    noImageBlockStyle: any;
    imageData: any;

    @ViewChild("cropper", { static: true }) cropper: ImageCropperComponent;

    constructor(
        public dialogRef: MatDialogRef<LogoImageCropperFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: QuestionImageCropperData
    ) {
        this.IMAGE_WIDTH = 259;
        this.IMAGE_HEIGHT = 80;
    }

    ngOnInit() {
        var that = this;

        that.imageData = {};
        that.cropperSettings = new CropperSettings();
        that.cropperSettings.noFileInput = true;
        that.cropperSettings.width = this.IMAGE_WIDTH;
        that.cropperSettings.height = this.IMAGE_HEIGHT;
        that.cropperSettings.croppedWidth = this.IMAGE_WIDTH;
        that.cropperSettings.croppedHeight = this.IMAGE_HEIGHT;
        that.cropperSettings.canvasWidth = 400;
        that.cropperSettings.canvasHeight = 400;

        this.noImageBlockStyle = {
            height: that.cropperSettings.croppedHeight + "px",
            width: that.cropperSettings.croppedWidth + "px"
        };
    }

    ngAfterViewInit() {
        this.initializeCropperImage();
    }

    initializeCropperImage() {
        if (this.data.img) {
            this.cropper.setImage(this.data.img);
        }
    }

    onNoClick() {
        this.dialogRef.close();
    }

    onYesClick() {
        return {
            src: this.imageData.image,
            height: this.cropperSettings.croppedHeight,
            width: this.cropperSettings.croppedWidth
        };
    }
}
