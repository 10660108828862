<div id="login" fxLayout="column">
    <div fxLayout="row" class="white-bordered-block">
        <div fxFlex="auto" class="custom-header-content">
            <img
                class="logo5worker"
                src="../assets/images/logos/logo5workerBlue.png"
                alt="logo"
            />
            <span class="blue-text">
                Giải pháp công nghệ tuyển dụng công nhân hoàn toàn tự
                động</span
            >
        </div>
        <div fxFlex="200px" class="custom-header-content">
            <mat-icon style="margin-right: 10px; cursor: pointer" (click)="goToHomePage()"
                >home</mat-icon
            >
            <span style='cursor: pointer' (click)="goToHomePage()">Trang chủ</span>
        </div>
    </div>

    <div id="central-content-wrapper" fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="loadingIndicator" class="login-spinner">
            <mat-spinner color="accent"></mat-spinner>
        </div>
        <div id="login-form-wrapper" fxLayout="column">
            <div fxLayout="row">
                <button
                    mat-raised-button
                    class="register-button"
                    aria-label="Tạo tài khoản"
                    [routerLink]="'/auth/register'"
                >
                    Tạo tài khoản mới
                </button>
            </div>
            <div
                id="login-form"
                [@animate]="{
                    value: '*',
                    params: { duration: '300ms', y: '100px' }
                }"
            >
                <div class="title">Đăng nhập</div>
                <form name="loginForm" [formGroup]="loginForm" novalidate>
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" />
                        <mat-error
                            *ngIf="loginForm.get('email').hasError('required')"
                        >
                            Vui lòng nhập email
                        </mat-error>
                        <mat-error
                            *ngIf="
                                !loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')
                            "
                        >
                            Email không hợp lệ
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Mật khẩu</mat-label>
                        <input
                            matInput
                            type="password"
                            formControlName="password"
                        />
                        <mat-error> Vui lòng nhập mật khẩu </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="showError && !loadingIndicator">
                        Sai tài khoản hoặc mật khẩu
                    </mat-error>
                    <div
                        class="remember-forgot-password"
                        fxLayout="row"
                        fxLayout.xs="column"
                        fxLayoutAlign="space-between center"
                    >
                        <mat-checkbox
                            class="remember-me"
                            aria-label="Remember Me"
                            formControlName="remember"
                        >
                            Ghi nhớ tài khoản đăng nhập
                        </mat-checkbox>
                    </div>

                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button"
                        aria-label="Đăng nhập"
                        [disabled]="loginForm.invalid || loadingIndicator"
                        (click)="onSubmit(loginForm.value)"
                    >
                        Đăng nhập
                    </button>
                </form>

                <div
                    class="register"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                >
                    <a
                        class="forgot-password"
                        [routerLink]="'/auth/forgot-password'"
                    >
                        Quên mật khẩu?
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
