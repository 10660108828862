import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';
import { PosterService } from '../poster.service';
import { get } from 'lodash';

const CANVAS_SIZE = 239;

@Component({
    selector: 'app-poster-creator',
    templateUrl: './poster-creator.component.html',
    styleUrls: ['./poster-creator.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PosterCreatorComponent
    implements OnInit, OnChanges, AfterViewInit
{
    @Input() posterModelInput: any;
    @Input() posterSize?: any;
    @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() buttonEmitter: EventEmitter<any> = new EventEmitter();
    @ViewChild('cropper', { static: true }) cropper: ImageCropperComponent;
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('loadedImg') loadedImg: ElementRef;

    posterModel: any;
    color: any;
    colorPickerToggle: any;
    backgroundTemplates: any;
    backgroundTemplates2: any;
    presetColors: any;
    colorClass: any;
    posterHeight: number;
    posterWidth: number;

    constructor(private _posterService: PosterService) {
        this.color = '#ffffff';
        this.colorPickerToggle = false;
        this.backgroundTemplates = [
            './assets/poster-templates/001.png',
            './assets/poster-templates/002.jpg',
            './assets/poster-templates/003.jpg',
            './assets/poster-templates/004.jpg',
            './assets/poster-templates/005.png',
            './assets/poster-templates/006.jpg',
            './assets/poster-templates/007.jpg',
            './assets/poster-templates/008.jpg',
        ];

        this.backgroundTemplates2 = [
            './assets/poster-templates/009.jpg',
            './assets/poster-templates/010.jpg',
            './assets/poster-templates/012.jpg',
            './assets/poster-templates/013.jpg',
            './assets/poster-templates/015.jpg',
            './assets/poster-templates/016.jpg',
            './assets/poster-templates/017.jpg',
            './assets/poster-templates/018.jpg',
        ];

        this.presetColors = [
            '#007bff',
            '#6610f2',
            '#6f42c1',
            '#e83e8c',
            '#dc3545',
            '#fd7e14',
            '#ffc107',
            '#28a745',
            '#20c997',
            '#17a2b8',
            '#fff',
            '#868e96',
            '#343a40',
        ];
    }

    ngOnInit() {
        const that = this;

        if (!this.posterModelInput) {
            this._initialize(that);
        } else {
            this._reInitialize(that);
        }

        const getPosterModel = () =>
            that._posterService.convertToSimplePoster(
                that.posterModel,
                that.posterSize
            );

        this.emitter.emit({
            getPosterModel,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const that = this;

        if (!this.posterModelInput) {
            this._initialize(that);
        } else {
            this._reInitialize(that);
        }

        const getPosterModel = () =>
            that._posterService.convertToSimplePoster(
                that.posterModel,
                that.posterSize
            );

        this.emitter.emit({
            getPosterModel,
        });
    }

    ngAfterViewInit() {
        const modelBGType = get(this.posterModelInput, 'model.bgType') || '';
        const imageData = get(this.posterModel, 'imageData') || '';
        // if (!isEmpty(imageData) && modelBGType === 'image') {
        //     this.cropper.setImage(this.loadedImg.nativeElement);
        // }
    }

    onClickButton(name = '') {
        this.buttonEmitter.emit(name);
    }

    triggerInputImage() {
        this.posterModel.backgroundType = 'panel_1';
        this.clearColorInput();
        const inputElement: HTMLElement = this.fileInput
            .nativeElement as HTMLElement;
        inputElement.click();
    }

    _reInitialize(that?: any) {
        const model = this.posterModelInput.model;
        if (model.type && model.type === 'vertical') {
            that.posterSize = 'vertical';
            that.posterHeight = 300 * 1.5;
            that.posterWidth = 300;
        } else {
            that.posterSize = 'horizontal';
            that.posterHeight = 300;
            that.posterWidth = 300 * 1.5;
        }

        that.color = model.bgType !== 'color' ? '#ffffff' : model.bgValue;
        that.posterModel = {
            posterTextModel: {
                value: model.content,
                textPosition: model.contentPosition,
                textColor: model.textColor,
                size: model.size,
            },

            posterViewBackgroundStyle: {
                'background-color': that.color,
                width: that.posterWidth + 2 + 'px',
                height: that.posterHeight + 2 + 'px',
            },
            imageData: {},
            backgroundType: model.bgType === 'image' ? 'panel_1' : 'panel_0',
            size: that.posterSize,
        };

        if (model.bgType === 'pattern') {
            this.setBackgroundTemplate(model.bgValue);
        }

        if (model.bgType === 'image') {
            this.posterModel.imageData.image = model.bgValue;
        }

        that.posterModel.cropperSettings = new CropperSettings();
        that.posterModel.cropperSettings.noFileInput = true;
        that.posterModel.cropperSettings.width = that.posterWidth;
        that.posterModel.cropperSettings.height = that.posterHeight;
        that.posterModel.cropperSettings.croppedWidth = that.posterWidth;
        that.posterModel.cropperSettings.croppedHeight = that.posterHeight;
        that.posterModel.cropperSettings.canvasWidth = CANVAS_SIZE;
        that.posterModel.cropperSettings.canvasHeight = CANVAS_SIZE;
    }

    _initialize(that?: any) {
        if (that.posterSize && that.posterSize === 'vertical') {
            that.posterHeight = 300 * 1.5;
            that.posterWidth = 300;
        } else {
            that.posterSize = 'horizontal';
            that.posterHeight = 300;
            that.posterWidth = 300 * 1.5;
        }
        that.color = '#ffffff';
        that.posterModel = {
            posterTextModel: {
                value: '',
                textPosition: 'middle',
                textColor: '#000',
            },

            posterViewBackgroundStyle: {
                'background-color': that.color,
                width: that.posterWidth + 2 + 'px',
                height: that.posterHeight + 2 + 'px',
            },
            imageData: {},
            backgroundType: 'panel_0',
            size: that.posterSize,
        };

        that.posterModel.cropperSettings = new CropperSettings();
        that.posterModel.cropperSettings.noFileInput = true;
        that.posterModel.cropperSettings.width = that.posterWidth;
        that.posterModel.cropperSettings.height = that.posterHeight;
        that.posterModel.cropperSettings.croppedWidth = that.posterWidth;
        that.posterModel.cropperSettings.croppedHeight = that.posterHeight;
        that.posterModel.cropperSettings.canvasWidth = CANVAS_SIZE;
        that.posterModel.cropperSettings.canvasHeight = CANVAS_SIZE;
    }

    convertToSimplePoster(posterModel) {
        return this._posterService.convertToSimplePoster(
            posterModel,
            this.posterSize || 'horizontal'
        );
    }

    resetPosterSize() {
        const that = this;
        if (that.posterSize && that.posterSize === 'vertical') {
            that.posterHeight = 300 * 1.5;
            that.posterWidth = 300;
        } else {
            that.posterSize = 'horizontal';
            that.posterHeight = 300;
            that.posterWidth = 300 * 1.5;
        }

        that.posterModel.posterViewBackgroundStyle = {
            'background-color': that.color,
            width: that.posterWidth + 2 + 'px',
            height: that.posterHeight + 2 + 'px',
        };
        that.posterModel.imageData = {};

        that.posterModel.backgroundType = 'panel_0';
        that.posterModel.size = that.posterSize;

        that.posterModel.cropperSettings = new CropperSettings();
        that.posterModel.cropperSettings.noFileInput = true;
        that.posterModel.cropperSettings.width = that.posterWidth;
        that.posterModel.cropperSettings.height = that.posterHeight;
        that.posterModel.cropperSettings.croppedWidth = that.posterWidth;
        that.posterModel.cropperSettings.croppedHeight = that.posterHeight;
        that.posterModel.cropperSettings.canvasWidth = CANVAS_SIZE;
        that.posterModel.cropperSettings.canvasHeight = CANVAS_SIZE;
    }

    clearImageInput() {
        this.posterModel.imageData.image = null;

        const newTextModel = {
            value: this.posterModel.posterTextModel.value,
            textPosition: 'middle',
            textColor: this.posterModel.posterTextModel.textColor,
        };
        this.posterModel.posterTextModel = newTextModel;

        this.resetPosterSize();
    }

    clearColorInput() {
        this.color = '#fff';
        this.posterModel.posterViewBackgroundStyle = {
            'background-color': this.color,
            width: this.posterWidth + 2 + 'px',
            height: this.posterHeight + 2 + 'px',
        };

        const newTextModel = {
            value: this.posterModel.posterTextModel.value,
            textPosition: 'bottom',
            textColor: this.posterModel.posterTextModel.textColor,
        };
        this.posterModel.posterTextModel = newTextModel;
    }

    setBackgroundColor(color) {
        console.log('color', color);
        this.color = color;
        this.posterModel.posterViewBackgroundStyle = {
            'background-color': color,
            width: this.posterWidth + 2 + 'px',
            height: this.posterHeight + 2 + 'px',
        };
    }

    setBackgroundTemplate(src) {
        this.posterModel.backgroundType = 'panel_0';
        this.clearImageInput();
        this.posterModel.posterViewBackgroundStyle =
            this.getBackgroundTemplateStyles(src);
        this.color = '#ffffff';
    }

    getBackgroundTemplateStyles(src) {
        return {
            'background-image': src,
            'background-size': 'cover',
            width: this.posterWidth + 2 + 'px',
            height: this.posterHeight + 2 + 'px',
        };
    }

    getBackgroundThumbnailStyles(src) {
        const bgImageSrc = 'url(\'{src}\')';
        return {
            'background-image': bgImageSrc.replace('{src}', src),
            'background-size': 'cover',
        };
    }

    fileChangeListener($event) {
        const image: any = new Image();
        const file: File = $event.target.files[0];
        const myReader: FileReader = new FileReader();
        const that = this;
        myReader.onloadend = (loadEvent: any) => {
            image.src = loadEvent.target.result;
            that.cropper.setImage(image);
        };

        myReader.readAsDataURL(file);
    }

    getColorPickerTextColor(color: any) {
        if (color === undefined) {
            return '#ffffff';
        }
        const _color = (color + '').toLowerCase();
        if (
            _color.charCodeAt(1) + _color.charCodeAt(3) + _color.charCodeAt(5) >
                250 ||
            _color === '#fff'
        ) {
            return '#000000';
        }
        return '#ffffff';
    }
}
