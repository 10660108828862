import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CampaignService } from '../../../main/campaign/campaign.service';
import CampaignUtils from '../../../main/campaign/shared/campaign-utils';
import { get, isEmpty } from 'lodash';
import { GenderEnum2LabelMapping } from '../../models/campaign.model';

@Component({
    selector: 'app-user-details-dialog',
    templateUrl: './user-details-dialog.component.html',
    styleUrls: ['./user-details-dialog.component.scss'],
})
export class UserDetailsDialogComponent implements OnInit {
    approved = false;
    needRemove = false;
    candidate = {
        avatar: '',
        age: '',
        address: '',
        email: '',
        currentAddress: '',
        fullName: '',
        certificateStrings: [] as any[],
        experienceStrings: [] as any[],
        prerequisite: '',
        score: '',
        result: '',
        gender: '',
        health: '',
        healthCertificate: {
            images: [],
        },
        certificate: [
            {
                images: [],
            },
        ],
        certificates: [
            {
                images: [],
            },
        ],
        identityCard: {
            images: [],
        },
        curriculumVitae: {
            applicationImages: [],
        },
        householdImages: [],
        originalProfile: {},
        status: ''
    };
    profileImages = [];
    isLoading = false;
    approveProfile!: (approved: boolean) => void;

    constructor(
        private campaignService: CampaignService,
        public dialogRef: MatDialogRef<UserDetailsDialogComponent>
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.approved = this.candidate.status === 'APPROVED';
        this.campaignService
            .getCandidateOriginalProfile(this.candidate.email)
            .subscribe(
                (response) => {
                    this.isLoading = false;

                    const age =
                        new Date().getFullYear() -
                        new Date(get(response, 'birthday')).getFullYear() +
                        '';
                    this.candidate.age = age;
                    this.candidate.originalProfile = response;
                    this.candidate.avatar = get(response, 'avatar.url');
                    this.candidate.address = CampaignUtils.readAddress(
                        this.candidate.address
                    );
                    this.candidate.currentAddress = CampaignUtils.readAddress(
                        this.candidate.currentAddress
                    );
                    this.candidate.gender =
                        GenderEnum2LabelMapping[get(response, 'gender')];

                    const healthCertificate = get(
                        response,
                        'healthCertificate'
                    );
                    this.candidate.health =
                        isEmpty(healthCertificate) ||
                        isEmpty(healthCertificate.type)
                            ? ''
                            : healthCertificate.type;

                    const certificates = get(response, 'certificate');
                    let certificateStrings = [];

                    try {
                        certificateStrings = certificates.map((certificate) =>
                            CampaignUtils.readCertificateInfo(certificate)
                        );
                    } catch (e) {
                        console.log({ e });
                    }

                    const experiences = get(response, 'experiences');
                    let experienceStrings = [];

                    try {
                        experienceStrings = experiences.map((experience) =>
                            CampaignUtils.readExperience(experience)
                        );
                    } catch (e) {
                        console.log({ e });
                    }

                    this.candidate.experienceStrings = experienceStrings;
                    this.candidate.certificateStrings = certificateStrings;

                    CampaignUtils.getImagesFromCandidate(
                        this.profileImages,
                        this.candidate
                    );
                },
                (error) => {
                    this.isLoading = false;
                    console.log('Error while loading profile: ', error);
                }
            );
    }

    handleApprove() {
        this.approveProfile(this.approved);
    }
}
