import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';
import { get } from 'lodash';

@Component({
    selector: 'app-test-result-cell',
    template: `
        <span class="avatar-container" *ngIf="type === 'avatar'">
            <img
                class="avatar-img"
                src="{{ imageSrc }}"
                alt="{{ convertedValue }}}"
            />
        </span>
        
        <span *ngIf="isCheckBoxCell()">
            <mat-icon *ngIf="convertedValue" class="active-icon green-600 s-16"
                >check
            </mat-icon>
        </span>

        <span *ngIf="isTextCell()">{{ convertedValue }}</span>

        <span *ngIf="type === 'fullName'" class="name-cell">{{
            convertedValue
        }}</span>
    `,
    styleUrls: ['./test-result-cell-renderer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TestResultCellRendererComponent
    implements ICellRendererAngularComp
{
    params!: ICellRendererParams;
    type = '';
    convertedValue;
    imageSrc: '';

    agInit(params: ICellRendererParams) {
        this.params = params;
        this.type = params.colDef.field;

        switch (this.type) {
            case 'age':
                const birthday = get(params.data, 'birthday', 0);
                this.convertedValue =
                    new Date().getFullYear() -
                    new Date(birthday).getFullYear() +
                    '';
                break;
            case 'avatar':
            case 'fullName':
                this.imageSrc = get(params.data, 'avatar', '');
                const firstName = get(params.data, 'firstName', '');
                const lastName = get(params.data, 'lastName', '');
                this.convertedValue = `${lastName} ${firstName}`;
                break;
            case 'authorized':
                this.convertedValue = get(params.data, 'authorized', false);
                break;
            case 'confirm':
                this.convertedValue = get(params.data, 'confirm', false);
                break;
            case 'resultTakenTime':
                const time = get(params.data, 'resultTakenTime', 0) || 0;
                this.convertedValue = time
                    ? moment(new Date(time)).format('HH:mm DD.MM.YY')
                    : '';
                break;
            default:
                this.convertedValue = '';
                break;
        }
    }

    isTextCell() {
        return ['age', 'resultTakenTime'].includes(this.type);
    }

    isCheckBoxCell() {
        return ['confirm', 'authorized'].includes(this.type);
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
}
