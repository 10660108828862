import { fuseAnimations } from '@fuse/animations';
import { CompanyService } from '../company.service';
import { Company, JobCategory } from '../company.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AddBranchFormDialogComponent } from './add-branch-form/add-branch-form.component';
import { GeographyService } from 'app/main/geography/geography.service';
import {
    MatAutocomplete,
    MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { get, isEmpty } from 'lodash';
import { GoogleMapGuideComponent } from '../shared/google-map-guide/google-map-guide.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_FORMATS } from '../../../campaign/campaign-result/campaign-result.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

const FILE_LIMIT = 5 * 1024 * 1024;

@Component({
    selector: 'app-basic-information',
    templateUrl: './basic-information.component.html',
    styleUrls: ['./basic-information.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS,
        },
    ],
})
export class BasicInformationComponent implements OnInit {
    @ViewChild('myInput') fileInputVariable: ElementRef;
    @Input()
    company: Company;

    @ViewChild('jobCategoryInput')
    jobCategoryInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    logo = null;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];

    allJobCategories: JobCategory[];
    filteredJobCategories: Observable<JobCategory[]>;
    selectedJobCategories: any[];
    companyForm: FormGroup;
    isProcessing = false;
    dialogRef: any;

    newAddress = undefined;
    translate = [];
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private translateService: TranslateService,
        private _matSnackBar: MatSnackBar,
        private _companyService: CompanyService,
        private _formBuilder: FormBuilder,
        private _geographyService: GeographyService,
        private _matDialog: MatDialog
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.companyForm = this.createCompanyForm();
        this.filteredJobCategories = this.companyForm
            .get('jobCategories')
            .valueChanges.pipe(
                startWith(null),
                map((jobCategory: string | null) =>
                    jobCategory && typeof jobCategory === 'string'
                        ? this._filter(jobCategory)
                        : this.allJobCategories.slice()
                )
            );

        this._companyService.onJobCategoriesChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((jobCategories) => {
                this.allJobCategories = jobCategories;
            });

        this._companyService.onCompanyChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((company) => {
                this.company = company;
                this.companyForm = this.createCompanyForm();
                this.selectedJobCategories = company.relatedJobs;
            });

        this.translateService
            .get('COMPANY')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((t) => {
                this.translate = t;
            });

        this.translateService.onLangChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((t) => {
                const translation =
                    get(t, 'translations["COMPANY"]') || {};
                if (!isEmpty(translation)) {
                    this.translate = translation;
                }
            });
    }

    createCompanyForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.company.id],
            prefix: [this.company.prefix],
            fullName: [this.company.fullName],
            description: [this.company.description],
            logo: [this.company.logo],
            jobCategories: [],
            taxCode: this.company.taxCode,
            taxCodeIssueDate: moment(this.company.taxCodeIssueDate),
            taxCodeIssuer: this.company.taxCodeIssuer,
            address: [this.company.branches[0].address.address],
            mapLink: [this.company.branches[0].address.mapLink],
        });
    }

    add(event: MatChipInputEvent): void {
        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            if ((value || '').trim()) {
                const jobCategory = this.allJobCategories.find(
                    (x) => x.id.toString() === value.trim()
                );
                this.selectedJobCategories.push(jobCategory);
            }

            if (input) {
                input.value = '';
            }

            this.companyForm.get('jobCategories').setValue(null);
        }
    }

    remove(jobCategory: JobCategory): void {
        const index = this.selectedJobCategories.indexOf(jobCategory);

        if (index >= 0) {
            this._companyService
                .deleteRelatedJob(jobCategory.id)
                .then((res) => {
                    if (res) {
                        this.selectedJobCategories.splice(index, 1);
                    } else {
                        this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                });
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const jobCategory = this.allJobCategories.find(
            (x) => x.id === event.option.value
        );

        for (const item of this.selectedJobCategories) {
            if (item.id === jobCategory.id) {
                this._matSnackBar.open(this.translate['CHOSEN_JOB'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                this.jobCategoryInput.nativeElement.blur();

                return;
            }
        }

        this._companyService
            .saveCompanyRelatedJob(jobCategory.id)
            .then((res) => {
                if (res) {
                    this.selectedJobCategories.push(jobCategory);
                } else {
                    this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                }
            })
            .catch(() => {
                this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
            });

        this.jobCategoryInput.nativeElement.value = '';
        this.jobCategoryInput.nativeElement.blur();
    }

    onDeleteBranchBtnClick(branch) {
        this._companyService
            .deleteBranch(branch.id)
            .then(() => {
                this._companyService.getCompany().then(() => {});
                this._matSnackBar.open(this.translate['DELETE_SUCCESS'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
            })
            .catch((err) => {
                console.log('Error on saving', err);
                this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
            });
    }

    onEditBranchBtnClick(branch) {
        this.dialogRef = this._matDialog.open(AddBranchFormDialogComponent, {
            panelClass: 'add-branch-form-dialog',
            data: {
                name: branch.address.name,
                address: branch.address.address,
                branch,
            },
        });

        this.dialogRef.afterClosed().subscribe((response) => {
            if (!response) {
                return;
            }

            console.log('add-branch-form-dialog', response);
            this._companyService
                .editBranch(branch.id, {
                    address: response.address,
                })
                .then(() => {
                    this._companyService.getCompany().then(() => {
                        this._matSnackBar.open(this.translate['SAVE_SUCCESS'], 'OK', {
                            verticalPosition: 'top',
                            duration: 2000,
                        });
                    });
                })
                .catch((err) => {
                    console.log('Error on saving', err);
                    this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                });
        });
    }

    onAddBranchBtnClick(): void {
        this.dialogRef = this._matDialog.open(AddBranchFormDialogComponent, {
            panelClass: 'add-branch-form-dialog',
            data: {
                name: '',
                address: '',
            },
        });

        this.dialogRef.afterClosed().subscribe((response) => {
            if (!response) {
                return;
            }

            console.log('add-branch-form-dialog', response);
            this._companyService
                .addBranch({
                    address: response.address,
                })
                .then(() => {
                    this._companyService.getCompany().then((r) => {});
                    this._matSnackBar.open(this.translate['SAVE_SUCCESS'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                })
                .catch((err) => {
                    console.log('Error on saving', err);
                    this._matSnackBar.open(this.translate['ERROR'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                });
        });
    }

    saveCompany(callback = null) {
        const companyData = this.companyForm.getRawValue();
        const mainBranch = get(this.company, 'branches[0]') || {};
        if (
            !isEmpty(mainBranch) &&
            (this.newAddress ||
                this.companyForm.get('address').dirty ||
                this.companyForm.get('mapLink').dirty)
        ) {
            if (!this.newAddress) {
                this.newAddress = {
                    address: {
                        address: '',
                        name: '',
                        town: mainBranch.address.townId,
                        industryPark: mainBranch.address?.industryParkId,
                    },
                };
            }
            this.newAddress.address.address = companyData.address;
            this.newAddress.address.mapLink = companyData.mapLink;
            this._companyService
                .editBranch(mainBranch.id, this.newAddress)
                .then(() => {
                    this.newAddress = undefined;
                });
        }

        delete companyData.address;

        this._companyService
            .saveCompany(companyData)
            .then(() => {
                if (callback) {
                    callback();
                } else {
                    this._matSnackBar.open(this.translate['SAVE_SUCCESS'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                }
            })
            .catch((err) => {
                console.log('Saving error: ', err);
            });
    }

    showData(e) {
        this.newAddress = {
            address: {
                address: '',
                name: '',
                town: e.town?.id,
                industryPark: e.industryPark?.id,
            },
        };
        this.companyForm.markAsDirty();
    }

    getAddressString(address) {
        let addressString = '';
        if (!isEmpty(address.address)) {
            addressString += address.address + ', ';
        }
        return (
            addressString +
            (address.town ? address.town + ', ' : '') +
            (address.industryPark ? address.industryPark + ', ' : '') +
            address.district +
            ', ' +
            address.province
        );
    }

    showMapGuide() {
        this._matDialog.open(GoogleMapGuideComponent);
    }

    onFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            const { size, name: fileName } = file;
            if (size > FILE_LIMIT) {
                this._matSnackBar.open(this.translate['OVER_SIZE'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            this.isProcessing = true;
            this._companyService
                .uploadRegistrationDocument(formData)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res) => {
                    this.company.companyRegistrationImages.push({ ...res, fileName });
                    this._matSnackBar.open(this.translate['UPLOAD_SUCCESS'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                    this.companyForm.markAsDirty();
                    this.isProcessing = false;
                }, () => {
                    this.isProcessing = false;
                });
        }
    }

    onRemoveFile(id: string) {
        this.isProcessing = true;
        this._companyService
            .removeRegistrationDocument(id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                this.company.companyRegistrationImages =
                    this.company.companyRegistrationImages.filter(
                        (file) => file.id !== id
                    );
                this._matSnackBar.open(this.translate['DELETE_SUCCESS'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                this.companyForm.markAsDirty();
                this.isProcessing = false;
            }, () => {
                this.isProcessing = false;
            });
    }

    private _filter(jobCategory: string): JobCategory[] {
        const filterValue = jobCategory.toLowerCase();

        return this.allJobCategories.filter(
            (x) => x.content.toLowerCase().indexOf(filterValue) === 0
        );
    }

    protected readonly window = window;
}
