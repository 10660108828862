<h1 matDialogTitle>Phê duyệt tuyển dụng</h1>
<div mat-dialog-content>
    <p class="text-center">Danh sách tuyển dụng sẽ được gửi đi phê duyệt</p>
    <table id="result-confirm-dialog">
        <tr>
            <td style="width: 130px">Tuyển chính thức</td>
            <td style="width: 200px">{{countOfficial}}</td>
        </tr>
        <tr>
            <td>Tuyển dự bị</td>
            <td>{{countBackup}}</td>
        </tr>
<!--        <tr>-->
<!--            <td>Thời hạn xác nhận đi làm</td>-->
<!--            <td>{{acceptingDeadline}}</td>-->
<!--        </tr>-->
        <tr>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>Người kiểm tra</td>
            <td>
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-select [(ngModel)]="reviewerId">
                        <mat-option
                            *ngFor="let member of reviewerList"
                            [value]="member.id + ''"
                        >
                            {{ getFullNamAndEmail(member) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>Người phê duyệt</td>
            <td>
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-select [(ngModel)]="approverId">
                        <mat-option
                            *ngFor="let member of members"
                            [value]="member.id + ''"
                        >
                            {{ getFullNamAndEmail(member) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions class="pt-24 pb-24 center">
    <div class="button-wrapper">
        <button
            mat-raised-button
            color="accent"
            (click)="dialogRef.close(getReturnValue())"
        >
            Gửi
        </button>
        <button
            mat-raised-button
            color="primary"
            class="ml-16"
            (click)="dialogRef.close(false)"
        >
            Huỷ
        </button>
    </div>
</div>
