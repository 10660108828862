import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
    CellClickedEvent,
    ColDef,
    GridApi,
    GridReadyEvent,
} from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CampaignResultService } from '../campaign-result.service';
import {
    CampaignState,
    CandidateFinalResult,
    RoundResult,
    TestTypeEnum,
} from '../../../../shared/models/campaign.model';
import { CampaignResultCellRendererComponent } from './campaign-result-cell-renderer.component';
import { get, isEmpty } from 'lodash';
import CampaignUtils from '../../shared/campaign-utils';
import { CampaignService } from '../../campaign.service';
import { TranslateService } from '@ngx-translate/core';

const checkBoxCol = CampaignUtils.checkBoxCol;

@Component({
    selector: 'app-campaign-result-table',
    templateUrl: './campaign-result-table.component.html',
    styleUrls: ['./campaign-result-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CampaignResultTableComponent
    implements OnInit, OnDestroy, AfterViewChecked
{
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
    @Input() loadingIndicator: boolean;
    @Input() tableStyle = {
        height: 'calc(100vh - 30rem)',
        width: '100%',
    };
    @Output() onRefresh: EventEmitter<any> = new EventEmitter();
    columnDefs: ColDef[] = [];
    defaultColDef = CampaignUtils.defaultColDef;
    rowData$!: Observable<any[]>;
    maxScrollTop = 0;
    timeoutId = null;
    candidates: CandidateFinalResult[];
    roundResults: RoundResult[];
    canSelectCandidate = false;
    shouldReFocusSearchInput = false;
    translate = [];

    averageCol = {
        field: 'average',
        cellRenderer: CampaignResultCellRendererComponent,
        headerValueGetter: () => this.translate['average'],
        width: 140,
        cellClass: 'right-cell-class',
    };

    fullNameCol = {
        field: 'fullName',
        cellRenderer: CampaignResultCellRendererComponent,
        getQuickFilterText: ({ data }) => get(data, 'candidate.fullName') || '',
        headerValueGetter: () => this.translate['fullNameShort'],
        width: 160,
        cellClass: 'center-cell-class',
        comparator: (valueA, valueB, nodeA, nodeB) => {
            const text1 = get(nodeA, 'data.candidate.fullName', '');
            const text2 = get(nodeB, 'data.candidate.fullName', '');
            return text1.localeCompare(text2);
        },
    };

    phoneCol = {
        field: 'phone',
        cellRenderer: CampaignResultCellRendererComponent,
        headerValueGetter: () => this.translate['phone'],
        width: 100,
        cellClass: 'center-cell-class',
        comparator: (valueA, valueB, nodeA, nodeB) => {
            const text1 = get(nodeA, 'data.candidate.phone', '');
            const text2 = get(nodeB, 'data.candidate.phone', '');
            return text1.localeCompare(text2);
        },
    };

    initialColDef: ColDef[] = [
        {
            valueGetter: 'node.rowIndex + 1',
            headerName: '#',
            width: 40,
            cellStyle: { 'text-align': 'center' },
            cellClass: 'center-cell-class',
            sortable: false,
        },
        {
            field: 'avatar',
            cellRenderer: CampaignResultCellRendererComponent,
            headerValueGetter: () => this.translate['avatar'],
            width: 72,
            autoHeight: true,
            cellStyle: { 'text-align': 'center' },
            sortable: false,
        },
        this.fullNameCol,
        {
            field: 'recruitmentType',
            cellRenderer: CampaignResultCellRendererComponent,
            headerValueGetter: () => this.translate['recruitmentType'],
            minWidth: 150,
            flex: 1,
            cellClass: 'center-cell-class',
        },
        {
            field: 'confirm',
            cellRenderer: CampaignResultCellRendererComponent,
            headerValueGetter: () => this.translate['confirmGoToWork'],
            width: 114,
            cellStyle: { 'text-align': 'center' },
        },
        {
            field: 'age',
            cellRenderer: CampaignResultCellRendererComponent,
            headerValueGetter: () => this.translate['age'],
            width: 42,
            cellClass: 'right-cell-class',
            comparator: (valueA, valueB, nodeA, nodeB) => {
                const val1 = get(nodeA, 'data.candidate.birthday', 0);
                const val2 = get(nodeB, 'data.candidate.birthday', 0);
                return val1 - val2;
            },
        },
        {
            field: 'health',
            cellRenderer: CampaignResultCellRendererComponent,
            headerValueGetter: () => this.translate['health'],
            width: 120,
            cellClass: 'center-cell-class',
            comparator: (valueA, valueB, nodeA, nodeB) => {
                const text1 = get(
                    nodeA,
                    'data.candidate.healthCertificate.type',
                    ''
                );
                const text2 = get(
                    nodeB,
                    'data.candidate.healthCertificate.type',
                    ''
                );
                return text1.localeCompare(text2);
            },
        },
        {
            field: 'exp',
            cellRenderer: CampaignResultCellRendererComponent,
            headerValueGetter: () => this.translate['exp'],
            width: 120,
            cellClass: 'right-cell-class',
            comparator: (valueA, valueB, nodeA, nodeB) => {
                const exp1 = get(
                    nodeA,
                    'data.candidate.totalExperienceMonth',
                    0
                );
                const exp2 = get(
                    nodeB,
                    'data.candidate.totalExperienceMonth',
                    0
                );
                return exp1 - exp2;
            },
        },
        this.averageCol,
    ];

    private unsubscribeAll: Subject<any>;
    private gridApi!: GridApi;

    constructor(
        private translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private campaignResultService: CampaignResultService,
        private campaignService: CampaignService
    ) {
        this.unsubscribeAll = new Subject();
        this.rowData$ = new Observable();
    }

    ngOnInit(): void {
        this.candidates = this.campaignResultService.candidates;
        if (!isEmpty(this.candidates)) {
            this.setUpTable(this.candidates);
            this.reOrderTable();
        }

        this.campaignResultService.onPaginatedDataChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((candidates) => {
                this.candidates = [...this.candidates, ...candidates];
                if (this.campaignResultService.nextPage > 1) {
                    this.addRoundResultIntoColumnTemplates(candidates);
                    this.gridApi.applyTransaction({ add: candidates });
                } else {
                    this.setUpTable(candidates);
                }
                this.reOrderTable();
            });

        this.campaignResultService.onFilterChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.campaignResultService.deselectCandidates();
                this.refreshTable();
            });

        this.translateService
            .get('DATA_TABLE')
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((t) => {
                this.translate = t;
            });

        this.translateService.onLangChange
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((t) => {
                const translation = get(t, 'translations["DATA_TABLE"]') || {};
                if (!isEmpty(translation)) {
                    this.translate = translation;
                }
                if (this.gridApi) {
                    this.gridApi.refreshHeader();
                }
            });
    }

    ngAfterViewChecked() {
        if (
            this.shouldReFocusSearchInput &&
            !this.loadingIndicator &&
            this.searchInput.nativeElement.disabled === false &&
            this.searchInput.nativeElement !== document.activeElement
        ) {
            this.searchInput.nativeElement.focus();
            this.cdr.detectChanges();
            this.shouldReFocusSearchInput = false;
        }
    }

    onScrollEnd(event) {
        const { top = -1 } = event;

        if (
            (top <= 520 && this.maxScrollTop < 520) ||
            top > this.maxScrollTop
        ) {
            this.maxScrollTop = top <= 520 ? 520 : top;

            this.fetchMoreData();
        }
    }

    fetchMoreData() {
        this.campaignResultService.getMoreResult()?.then(() => {});
    }

    ngOnDestroy(): void {
        this.campaignResultService.nextPage = 0;
        this.campaignResultService.fetchedAll = false;
        this.campaignResultService.currentTableApi = null;
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onFilterTextBoxChanged(event) {
        const value = get(event, 'target.value') || '';

        const callSearch = () => {
            this.shouldReFocusSearchInput = true;
            this.campaignResultService.onSearchTextChanged.next(value);
        };
        const triggerCall = () => {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            this.timeoutId = setTimeout(callSearch, 2000);
        };

        triggerCall();
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.campaignResultService.currentTableApi = params.api;
    }

    onCellClicked(e: CellClickedEvent): void {}

    onRowSelected(e): void {
        const selectedRows = this.gridApi.getSelectedRows();
        this.campaignResultService.selectCandidates(selectedRows);
    }

    onLoaded(e) {
        if (!isEmpty(this.gridApi)) {
            const verticalPixelRange = this.gridApi.getVerticalPixelRange();
            const { bottom = 0 } = verticalPixelRange;
            if (bottom > this.maxScrollTop + 520) {
                this.maxScrollTop = bottom;
                setTimeout(() => {
                    this.fetchMoreData();
                }, 1000);
            }
        }
    }

    reloadResultTable() {
        this.maxScrollTop = 0;
        this.onRefresh.emit();
    }

    private setUpTable(candidates) {
        this.columnDefs = [...this.initialColDef];
        this.columnDefs.find((c) => c.field === 'fullName').onCellClicked = (
            event
        ) =>
            this.campaignService.showCandidateDetails(
                CampaignUtils.convertToTestResultTableRow({
                    originalProfile: event.data.originalProfile,
                    ...event.data.candidate,
                })
            );
        this.setUpCheckBox();
        this.addPhoneNumberIntoColumnTemplates();
        this.addRoundResultIntoColumnTemplates(candidates);
        if (!isEmpty(this.gridApi)) {
            this.gridApi.setColumnDefs(this.columnDefs);
        }
        this.rowData$ = new Observable((observer) => {
            observer.next(candidates);
        });
        this.refreshTable();
    }

    private reOrderTable() {
        setTimeout(() => {
            const sortFields =
                get(
                    this.campaignService.campaign,
                    'userAction.resultSortFields'
                ) || [];
            if (!isEmpty(sortFields) && this.gridApi) {
                const colDefs = this.gridApi.getColumnDefs();
                const confirmFieldIndex = colDefs.findIndex(
                    (col) => get(col, 'field') === 'confirm'
                );

                sortFields.forEach((field, index) => {
                    const fieldIndex = colDefs.findIndex(
                        (col) => get(col, 'field') === field
                    );
                    const [movingCol] = colDefs.splice(fieldIndex, 1);
                    colDefs.splice(index + confirmFieldIndex + 1, 0, movingCol);
                });

                this.gridApi.setColumnDefs(colDefs);
            }
        }, 100);
    }

    private refreshTable() {
        if (!isEmpty(this.gridApi)) {
            this.gridApi.refreshCells();
            this.gridApi.deselectAll();
        }
    }

    private setUpCheckBox() {
        this.canSelectCandidate = this.isAbleToSelectCandidate();
        if (this.canSelectCandidate) {
            this.columnDefs.unshift(checkBoxCol);
        }
    }

    private addRoundResultIntoColumnTemplates(
        candidates: CandidateFinalResult[]
    ): void {
        if (candidates.length) {
            this.roundResults = candidates[0].roundResults;
            const averageIndex = this.columnDefs.indexOf(this.averageCol);
            this.columnDefs.splice(
                averageIndex,
                0,
                ...candidates[0].roundResults.map((round, index) => ({
                    field: `round_${index}`,
                    headerValueGetter: () => `${index + 1} ${
                        round.type === TestTypeEnum.INTERVIEW
                            ? this.translate['roundInterview']
                            : this.translate['roundTest']
                    } (%)`,
                    cellRenderer: CampaignResultCellRendererComponent,
                    cellRendererParams: {
                        roundIndex: index,
                    },
                    width: 130,
                    cellClass: 'right-cell-class',
                    comparator: (valueA, valueB, nodeA, nodeB) => {
                        const val1 = get(
                            nodeA,
                            `data.roundResults[${index}].score`,
                            0
                        );
                        const val2 = get(
                            nodeB,
                            `data.roundResults[${index}].score`,
                            0
                        );
                        return val1 - val2;
                    },
                }))
            );
        }
    }

    private addPhoneNumberIntoColumnTemplates() {
        const resultStatus = this.campaignResultService.currentResultStatus;
        if (
            [CampaignState.NOTIFIED, CampaignState.FINISHED].includes(
                resultStatus as CampaignState
            )
        ) {
            const nameIndex = this.columnDefs.indexOf(this.fullNameCol);
            this.columnDefs.splice(nameIndex + 2, 0, this.phoneCol);
        }
    }

    private isAbleToSelectCandidate() {
        return this.campaignResultService.isManagingResult();
    }
}
