<mat-form-field class="w-100-p" appearance="outline">
  <mat-label>{{'GEOGRAPHY.PROVINCE' | translate}}</mat-label>
  <mat-select [value]='selectedProvince' (selectionChange)="getProvince($event)" formControlName="province" required>
    <mat-option *ngFor="let p of provinces" [value]="p">
      {{p.name}}
    </mat-option>
  </mat-select>
  <mat-error>{{'GEOGRAPHY.INPUT_PROVINCE' | translate}}</mat-error>
</mat-form-field>

<mat-form-field class="w-100-p" appearance="outline">
  <mat-label>{{'GEOGRAPHY.DISTRICT' | translate}}</mat-label>
  <mat-select [value]='selectedDistrict' (selectionChange)="getDistrict($event)" formControlName="district" required>
    <mat-option *ngFor="let d of districts" [value]="d">
      {{d.name}}
    </mat-option>
  </mat-select>
  <mat-error>{{'GEOGRAPHY.INPUT_DISTRICT' | translate}}</mat-error>
</mat-form-field>

<mat-form-field class="w-100-p" appearance="outline">
  <mat-label>{{'GEOGRAPHY.VILLAGE' | translate}}</mat-label>
  <mat-select [value]='selectedTown' (selectionChange)="getTown($event)" formControlName="town" required>
    <mat-option *ngFor="let t of towns" [value]="t">
      {{t.name}}
    </mat-option>
  </mat-select>
  <mat-error>{{'GEOGRAPHY.INPUT_VILLAGE' | translate}}</mat-error>
</mat-form-field>

<mat-form-field *ngIf="industrialParks.length" class="w-100-p" appearance="outline">
  <mat-label>{{'GEOGRAPHY.INDUSTRIAL_PARK' | translate}}</mat-label>
  <mat-select [value]='selectedIndustryPark' (selectionChange)="getIndustrialPark($event)" formControlName="industrialPark">
    <mat-option *ngFor="let t of industrialParks" [value]="t">
      {{t.name}}
    </mat-option>
  </mat-select>
  <mat-error>{{'GEOGRAPHY.INPUT_INDUSTRIAL_PARK' | translate}}</mat-error>
</mat-form-field>