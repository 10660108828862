import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-result-sort-dialog',
    templateUrl: './result-sort-dialog.component.html',
    styleUrls: ['./result-sort-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ResultSortDialogComponent implements OnInit {
    conditionList = [];
    condition1 = {
        name: 'Không chọn',
        path: '',
        isAsc: false,
    };
    condition2 = { name: 'Không chọn', path: '', isAsc: false };
    condition3 = { name: 'Không chọn', path: '', isAsc: false };
    condition4 = { name: 'Không chọn', path: '', isAsc: false };
    condition5 = { name: 'Không chọn', path: '', isAsc: false };

    constructor(public dialogRef: MatDialogRef<ResultSortDialogComponent>) {}

    ngOnInit() {}

    getReturnValue() {
        return [
            this.condition1,
            this.condition2,
            this.condition3,
            this.condition4,
            this.condition5,
        ];
    }

    getColor(isRed = false) {
        return {
            color: isRed ? '#ec407a' : '#42a5f5',
        };
    }
}
