import 'hammerjs';

import {
    HTTP_INTERCEPTORS,
    HttpClientModule,
    HttpClient,
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyModule } from 'ngx-currency';
import { AppRoutingModule } from './app-routing.module';
import { CompanyModule } from './main/account/company/company.module';
import { PosterCreatorDialogComponent } from './main/account/poster/poster.component';
import { AuthModule } from './main/authentication/auth.module';
import { ForgotPasswordModule } from './main/authentication/forgot-password/forgot-password.module';
import { AuthHeader } from './main/authentication/interceptor';
import { LoginModule } from './main/authentication/login/login.module';
import { RegisterModule } from './main/authentication/register/register.module';
import { ResetPasswordModule } from './main/authentication/reset-password/reset-password.module';
import { DashboardsModule } from './main/dashboards/dashboards.module';
import { GeographyModule } from './main/geography/geography.module';
import { ErrorIntercept } from './core/interceptors/error.interceptor';
import { SharedModule } from './shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';
import { ActivationManageModule } from './main/activation-manage/activation-manage.module';
import { GlobalErrorHandler } from './core/global-error-handler';
import { AgGridModule } from 'ag-grid-angular';
import { AccountModule } from './main/account/account.module';
import { RedirectGuard } from './core/guards/redirect.guard';
import { LoginGuard } from './main/authentication/guards/login.guard';
import { AppReportComponent } from './main/app-report/app-report.component';
import { AppReportGuard } from './main/authentication/guards/app-report.guard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
    CompanyManageComponent,
    CompanyManageCellRenderer,
} from './main/company-manage/company-manage.component';
import {
    CandidateManageCellRenderer,
    CandidateManageComponent,
} from './main/candidate-manage/candidate-manage.component';
import { CampaignService } from './main/campaign/campaign.service';
import { UserDetailsDialogComponent } from './shared/modules/user-details-dialog/user-details-dialog.component';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export const customCurrencyMaskConfig = {
    align: 'right',
    allowNegative: true,
    allowZero: true,
    decimal: '.',
    precision: 0,
    prefix: '',
    suffix: '',
    thousands: ',',
    nullable: true,
};

export const httpLoaderFactory = (http: HttpClient) =>
    new TranslateHttpLoader(http);

@NgModule({
    declarations: [
        AppComponent,
        PosterCreatorDialogComponent,
        AppReportComponent,
        CompanyManageComponent,
        CandidateManageComponent,
        CompanyManageCellRenderer,
        CandidateManageCellRenderer,
        UserDetailsDialogComponent,
    ],
    entryComponents: [PosterCreatorDialogComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AgGridModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),

        MatMomentDateModule,
        MatToolbarModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatCardModule,
        MatRadioModule,
        MatGridListModule,
        MatDividerModule,
        MatDialogModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        AppRoutingModule,
        LayoutModule,
        DashboardsModule,
        LoginModule,
        RegisterModule,
        ForgotPasswordModule,
        ResetPasswordModule,

        CompanyModule,
        ColorPickerModule,
        AuthModule,
        GeographyModule,
        SharedModule,
        ActivationManageModule,

        QRCodeModule,
        AccountModule,
        MatCheckboxModule,
        AngularImageViewerModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHeader,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorIntercept,
            multi: true,
        },
        RedirectGuard,
        AppReportGuard,
        CampaignService,
        LoginGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
