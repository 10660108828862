import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ActivationManageCellRenderer,
    ActivationManageComponent,
} from './activation-manage.component';
import { ActivationKeyRoutingModule } from '../account/activation-key/activation-key-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ActivationKeyService } from '../account/activation-key/activation-key.service';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
    declarations: [ActivationManageComponent, ActivationManageCellRenderer],
    imports: [
        CommonModule,

        MatToolbarModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatOptionModule,
        MatSelectModule,
        MatSnackBarModule,
        MatGridListModule,
        MatExpansionModule,
        MatDialogModule,
        MatCardModule,

        ActivationKeyRoutingModule,

        FuseSharedModule,
        AgGridModule,
    ],
    providers: [ActivationKeyService],
})
export class ActivationManageModule {}
