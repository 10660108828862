import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { RegisterService } from './register.service';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_FORMATS } from '../../campaign/campaign-result/campaign-result.component';
import * as moment from 'moment';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS,
        },
    ],
})
export class RegisterComponent implements OnInit, OnDestroy {
    horizontalStepperStep1: FormGroup;
    horizontalStepperStep2: FormGroup;

    agreeTerms = false;
    isError = false;
    hide = true;
    hideC = true;

    private _unsubscribeAll: Subject<any>;
    private _location: any;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private matDialog: MatDialog,
        private _registerService: RegisterService,
        public router: Router
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        // Horizontal Stepper form steps
        this.horizontalStepperStep1 = this._formBuilder.group({
            companyName: ['', Validators.required],
            companyAddress: ['', Validators.required],
            taxCode: ['', Validators.required],
            taxCodeIssueDate: [null, Validators.required],
            taxCodeIssuer: ['', Validators.required],
        });

        this.horizontalStepperStep2 = this._formBuilder.group({
            email: ['', Validators.required],
            gender: [''],
            name: ['', Validators.required],
            phone: ['', Validators.required],
            password: ['', Validators.required],
            passwordConfirm: [
                '',
                [Validators.required, confirmPasswordValidator],
            ],
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.horizontalStepperStep2
            .get('password')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.horizontalStepperStep2
                    .get('passwordConfirm')
                    .updateValueAndValidity();
            });
    }

    finishHorizontalStepper(): void {
        const model = {
            address: {
                address:
                    this.horizontalStepperStep1.get('companyAddress').value,
                industryPark: this._location.industryPark
                    ? this._location.industryPark.id
                    : null,
                // 'name': 'string',
                town: this._location.town ? this._location.town.id : null,
            },
            admin: {
                email: this.horizontalStepperStep2.get('email').value,
                firstName: this.horizontalStepperStep2.get('name').value,
                lastName: '(A)',
                password: this.horizontalStepperStep2.get('password').value,
                phone: this.horizontalStepperStep2.get('phone').value,
                gender: this.horizontalStepperStep2.get('gender').value,
            },
            fullName: this.horizontalStepperStep1.get('companyName').value,
            taxCode: this.horizontalStepperStep1.get('taxCode').value,
            taxCodeIssueDate: moment(
                this.horizontalStepperStep1.get('taxCodeIssueDate').value
            ),
            taxCodeIssuer:
                this.horizontalStepperStep1.get('taxCodeIssuer').value,
        };
        this._registerService.submit(model).then(
            () => {
                const confirmDialog = this.matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                    }
                );

                confirmDialog.componentInstance.title = 'Đăng ký thành công!';
                confirmDialog.componentInstance.yesLabel = 'OK';
                confirmDialog.componentInstance.paragraph = [
                    `Email kích hoạt đã được gửi đến ${
                        this.horizontalStepperStep2.get('email').value
                    }`,
                    `Vui lòng đăng nhập email để kích hoạt tài khoản`,
                ];
                confirmDialog.componentInstance.noCancel = true;

                confirmDialog.afterClosed().subscribe((result) => {
                    if (result) {
                        this.router.navigate(['auth/login']).then((r) => {});
                    }
                });
            },
            (err) => {
                console.log('error while creating account', err);
                this.isError = true;
            }
        );
    }

    getEmail(): string {
        return this.horizontalStepperStep2.get('email').value;
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    completeLocation(location: any): void {
        this._location = location;
    }
}

export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
