import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardsComponent } from './dashboards.component';
import { RouterModule, Routes } from '@angular/router';
import { FlexModule } from '@angular/flex-layout';

// const routes: Routes = [
//   {
//       path     : '**',
//       component: DashboardsComponent,
//   }
// ];


@NgModule({
    declarations: [DashboardsComponent],
    imports: [CommonModule, FlexModule],
})
export class DashboardsModule {}
