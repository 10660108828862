import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../authentication/auth.service';
import { isEmpty } from 'lodash';
import {
    GenderEnum,
    GenderEnum2LabelMapping,
    GenderEnum2LabelMapping2,
} from '../../../shared/models/campaign.model';

const convertIdToOrderCode = (id) => {
    const idText = 10000 + id + '';
    return 'DH' + idText.substr(1, 4);
};

const convertData = (key) => {
    const { requestedBy = {}, company = {}, id = 0, expirationDate } = key;
    const {
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        gender = GenderEnum.MALE,
    } = requestedBy;
    const {
        fullName = '',
        id: companyId,
        taxCode: companyTaxCode = '',
    } = company;
    const requester = `${firstName} ${lastName}`.trim();
    let remainingDay = 0;
    const today = new Date().getTime();
    if (expirationDate && expirationDate > today) {
        remainingDay = Math.floor(
            (expirationDate - today) / 1000 / 24 / 60 / 60
        );
    }
    return {
        ...key,
        orderCode: convertIdToOrderCode(id),
        requesterWithGender: `${GenderEnum2LabelMapping2[gender]} ${requester}`,
        requester,
        email,
        phone,
        companyName: fullName,
        companyId,
        companyTaxCode,
        remainingDay,
    };
};

const convertOrderList = (orders = []) => {
    if (isEmpty(orders)) {
        return [];
    }
    const convertedOrders = orders.map((key) => convertData(key));
    return convertedOrders.sort((a, b) => b.id - a.id);
};

@Injectable()
export class ActivationKeyService implements Resolve<any> {
    routeParams: any;
    activationKeys: any[];
    onActivationKeysChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    constructor(
        private _httpClient: HttpClient,
        private authService: AuthService
    ) {
        this.onActivationKeysChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.activationKeys = [];
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getKeyHistory()]).then(([files]) => {
                this.activationKeys = convertOrderList(files as any[]);
                this.onActivationKeysChanged.next(this.activationKeys);
                resolve();
            }, reject);
        });
    }

    getKeyHistory() {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiEndpoint + 'orders/')
                .subscribe((res) => {
                    const data = convertOrderList(res as any[]);
                    this.activationKeys = data;
                    this.onActivationKeysChanged.next(data);
                    resolve(data);
                }, reject);
        });
    }

    createOrder(quantity) {
        const requester = this.authService.getCurrentUserId();
        return this._httpClient.post(environment.apiEndpoint + 'orders/', {
            requester,
            quantity,
        });
    }

    updateOrder({ status, id, quantity, companyId, price }) {
        return this._httpClient.put(`${environment.apiEndpoint}orders/${id}`, {
            status,
            quantity,
            companyId,
            price
        });
    }
}
