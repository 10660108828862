import { isEmpty } from 'lodash';

export class Member {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    constructor(member?) {
        if (member) {
            this.id = member.id;
            this.firstName = member.firstName;
            this.lastName = member.lastName;
            this.email = member.email || '';
        }
    }
    fullName = () => `${this.firstName} ${this.lastName}`;
    fullNameAndEmail = () => {
        const fullName = `${this.firstName} ${this.lastName}`;
        return isEmpty(this.email) ? fullName : `${fullName} (${this.email})`;
    };
}

export class Address {
    address: string;
    townId: number;
    town: string;
    industryParkId: number;
    industryPark: string;
    districtId: number;
    district: string;
    provinceId: number;
    province: string;
}

export class Branch {
    id: number;
    address: Address;
    headQuarter: boolean;
}
