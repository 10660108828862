import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation,
    Input,
    OnDestroy,
    AfterViewInit,
} from '@angular/core';
import { GeographyService } from './geography.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { isEmpty } from 'lodash';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'fw-geography',
    templateUrl: './geography.component.html',
    styleUrls: ['./geography.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class GeographyComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() geographyEmitter = new EventEmitter<any>();
    @Input() selectedData: any;

    provinces: any[];
    selectedProvince: any;

    districts: any[];
    selectedDistrict: any;

    towns: any[];
    selectedTown: any;

    industrialParks: any[];
    selectedIndustryPark: any;

    preventDefaultData: boolean;

    private _unsubscribeAll: Subject<any>;

    constructor(private _geographyService: GeographyService) {
        this._unsubscribeAll = new Subject();

        this.preventDefaultData = false;

        this.provinces = [];
        this.districts = [];
        this.towns = [];
        this.industrialParks = [];

        this.selectedProvince = {};
        this.selectedDistrict = {};
        this.selectedTown = {};
        this.selectedIndustryPark = {};
    }

    ngOnInit() {
        const setProvince = (provinces) => {
            this.provinces = provinces;
            this.districts = [];
            this.towns = [];
            this.industrialParks = [];
            this.selectedTown = {};
            if (this.selectedData && !this.preventDefaultData) {
                this.provinces.forEach((p) => {
                    if (p.id === this.selectedData.provinceId) {
                        this.selectedProvince = p;
                        this.districts = p.districts;
                        this.reloadDistrict();
                    }
                });
            }
        };
        if (!isEmpty(this._geographyService.provinces)) {
            setProvince(this._geographyService.provinces);
        } else {
            this._geographyService.getProvinces().then(() => {});
        }

        this._geographyService.onProvincesChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((provinces) => {
                setProvince(provinces);
            });

        this._geographyService.onDistrictsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((districts) => {
                this.districts = districts.length ? districts : this.districts;
                this.towns = [];
                this.industrialParks = [];
                this.reloadDistrict();
            });

        this._geographyService.onTownsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((towns) => {
                this.towns = towns;
                if (this.selectedData) {
                    this.selectedTown = this.towns.filter(
                        (town) => town.id === this.selectedData.townId
                    )[0];
                }
            });

        this._geographyService.onIndustrialParkChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((industrialParks) => {
                this.industrialParks = industrialParks;
                if (this.selectedData) {
                    this.selectedIndustryPark = this.industrialParks.filter(
                        (iDP) => iDP.id === this.selectedData.industryParkId
                    )[0];
                }
            });
    }

    reloadDistrict() {
        if (this.selectedData) {
            this.selectedDistrict = this.districts.filter(
                (distr) => distr.id === this.selectedData.districtId
            )[0];
            if (this.selectedDistrict) {
                this._geographyService
                    .getDistrict(this.selectedDistrict.id)
                    .then(() => {});
            }
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {}

    getProvince(e) {
        this.preventDefaultData = true;
        this.selectedProvince = e.value;
        this.districts = this.selectedProvince.districts;
        this._geographyService.districts = this.districts;
    }

    getDistrict(e) {
        this.selectedDistrict = e.value;
        try {
            this._geographyService
                .getDistrict(this.selectedDistrict.id)
                .then(() => {});
        } catch (error) {
            console.log('Cannot get district!!!', error);
        }
    }

    getTown(e) {
        this.selectedTown = e.value;
        this._emitData();
    }

    getIndustrialPark(e) {
        this.selectedIndustryPark = e.value;
        this._emitData();
    }

    _emitData() {
        this.geographyEmitter.emit({
            province: this.selectedProvince,
            district: this.selectedDistrict,
            town: this.selectedTown,
            industryPark: this.selectedIndustryPark,
        });
    }
}
