import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { FuseSharedModule } from "@fuse/shared.module";

import { RegisterComponent } from "app/main/authentication/register/register.component";
import { GeographyModule } from "app/main/geography/geography.module";
import { RegisterService } from "./register.service";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from '@angular/material/datepicker';

const routes = [
    {
        path: "auth/register",
        component: RegisterComponent
    }
];

@NgModule({
    declarations: [RegisterComponent],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
        MatSelectModule,
        GeographyModule,

        FuseSharedModule,
        MatDatepickerModule,
    ],
    providers: [RegisterService],
})
export class RegisterModule {}
