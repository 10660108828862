import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { get, isEmpty } from 'lodash';

@Injectable()
export class GeographyService implements Resolve<any> {
    routeParams: any;
    provinces: any[];
    districts: any[];
    towns: any[];
    industrialParks: any[];

    onProvincesChanged: BehaviorSubject<any[]>;
    onDistrictsChanged: BehaviorSubject<any[]>;
    onTownsChanged: BehaviorSubject<any[]>;
    onIndustrialParkChanged: BehaviorSubject<any[]>;

    constructor(private _httpClient: HttpClient) {
        this.onProvincesChanged = new BehaviorSubject([]);
        this.onDistrictsChanged = new BehaviorSubject([]);
        this.onTownsChanged = new BehaviorSubject([]);
        this.onIndustrialParkChanged = new BehaviorSubject([]);
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([this.getProvinces()]).then(() => {
                resolve(true);
            }, reject);
        });
    }

    getProvinces(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiEndpoint + 'geography/provinces')
                .subscribe((response: any) => {
                    this.provinces = isEmpty(response)
                        ? []
                        : response.sort((a: any, b: any) =>
                              a.name.localeCompare(b.name)
                          );
                    this.provinces.forEach((p) => {
                        p.districts = isEmpty(p.districts)
                            ? []
                            : p.districts.sort((a: any, b: any) =>
                                  a.name.localeCompare(b.name)
                              );
                    });
                    this.onProvincesChanged.next(this.provinces);
                    this._resetDistricts();
                    resolve(this.provinces);
                }, reject);
        });
    }

    getProvince(id: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiEndpoint + 'geography/provinces/' + id)
                .subscribe((response: any) => {
                    this.districts = response.districts;
                    this.onDistrictsChanged.next(this.districts);
                    this._resetTowns();
                    resolve(response);
                }, reject);
        });
    }

    getDistrict(id: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiEndpoint + 'geography/district/' + id)
                .subscribe((response: any) => {
                    const towns = get(response, 'towns') || [];
                    this.towns = towns.sort((a, b) =>
                        a.name.localeCompare(b.name)
                    );
                    this.onTownsChanged.next(this.towns);
                    this.industrialParks = response.industrialParks;
                    this.onIndustrialParkChanged.next(this.industrialParks);
                    resolve(response);
                }, reject);
        });
    }

    getIndustrialParks(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(environment.apiEndpoint + 'geography/industrialparks')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getTown(id: any): void {
        console.log(id);
    }

    _resetDistricts() {
        this.districts = [];
        this.onDistrictsChanged.next(this.districts);
        this._resetTowns();
    }

    _resetTowns() {
        this.towns = [];
        this.onTownsChanged.next(this.towns);
    }

    _resetIndustrialParks() {
        this.industrialParks = [];
        this.onIndustrialParkChanged.next(this.industrialParks);
    }
}
