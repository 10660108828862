import { isEmpty } from 'lodash';

export class PosterService {
    generatePosterTextModel(posterModel: any, fontSize = 21) {
        const lineHeight = fontSize * 1.5;
        return {
            value: posterModel.content,
            style: {
                'text-align': 'center',
                'font-family': 'Arial,Helvetica,sans-serif',
                'font-weight': 'bold',
                'font-size': fontSize + 'px',
                'line-height': lineHeight + 'px',
                // 'background-color': '#ffffff',
                opacity: '0.7',
                'white-space': 'initial',
                color: posterModel.textColor,
            },
            textPosition: posterModel.contentPosition,
            bgColor: '#ffffff',
        };
    }

    convertToSimplePoster(posterModel, posterSize) {
        const bgSetting = this._getBgSetting(posterModel);
        return {
            type: posterSize,
            content: posterModel.posterTextModel.value,
            textColor: posterModel.posterTextModel.textColor,
            contentPosition: posterModel.posterTextModel.textPosition,
            bgType: bgSetting.bgType,
            bgValue: bgSetting.bgValue,
            size: posterModel.posterTextModel.size,
        };
    }

    _getBgSetting(posterModel) {
        if (posterModel.backgroundType === 'panel_1') {
            return {
                bgType: 'image',
                bgValue: posterModel.imageData.image,
            };
        } else if (posterModel.posterViewBackgroundStyle['background-color']) {
            return {
                bgType: 'color',
                bgValue:
                    posterModel.posterViewBackgroundStyle['background-color'],
            };
        } else {
            return {
                bgType: 'pattern',
                bgValue:
                    posterModel.posterViewBackgroundStyle['background-image'],
            };
        }
    }
}
