import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

    showSuccess(message: string): void {
        this.zone.run(() => {
            this.snackBar.open(message, null, {
                verticalPosition: "top",
                duration: 2000,
            });
        });
    }

    showError(message: string): void {
        this.zone.run(() => {
            this.snackBar.open(message, "OK", {
                verticalPosition: "top",
                horizontalPosition: "center",
                duration: 2000,
            });
        });
    }
}
